import React, { useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { toastr } from 'utils';
import history from 'utils/history';
import { FormWrapper } from 'components/Modal/styles';
import { Wrapper, Modal, Form, Button, PopupContext } from 'components';
import {
  inviteRetailerRambla,
  updateRetailerRambla,
  deleteRetailerRambla,
} from 'redux/retailers/actions';
import { ramblaRegionsSelector } from 'redux/regions/selectors';
import { mapInitialValues } from '../../utils/retailerFormUtils';

const RetailerForm = ({ type = 'create', initialValues, FormGroup, wrapperType }) => {
  const { closePopup } = useContext(PopupContext);
  const { t } = useTranslation(['pages', 'app', 'toastr']);
  const regions = useSelector(ramblaRegionsSelector);
  const dispatch = useDispatch();

  const mappedInitialValues = useMemo(() => mapInitialValues(initialValues), [initialValues]);

  const deleteAction = () => {
    toastr.confirm(t('toastr:retailerDelete'), {
      onOk: async () => {
        await dispatch(deleteRetailerRambla(initialValues?.uid));
        history.goBack();
      },
      dispatch,
    });
  };

  return (
    <Form
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
      }}
      enableReinitialize
      initialValues={
        pick(mappedInitialValues, ['email', 'region_uid']) || {
          email: '',
          region_uid: '',
        }
      }
      onSubmit={({ region_uid, email }) =>
        type === 'update'
          ? dispatch(updateRetailerRambla({ uid: initialValues?.uid, email, region_uid }))
          : dispatch(inviteRetailerRambla({ email, region_uid }))
      }
    >
      <FormWrapper
        style={{
          minWidth: type === 'create' ? 800 : 400,
          maxWidth: type === 'create' ? 1000 : 600,
        }}
        type={wrapperType === 'modal' ? 'create' : type}
      >
        <Row className="d-flex justify-content-center">
          <Col md={12}>
            <FormGroup required label={t('pages:retailers.email')}>
              <Form.Input type="text" name="email" disabled={type === 'update'} />
            </FormGroup>
            <FormGroup label={t('pages:retailers.region')}>
              <Form.MultiSelect
                options={regions.data.map(region => ({
                  value: region.uid,
                  label: region.name,
                }))}
                name="region_uid"
                isMulti={false}
              />
            </FormGroup>
          </Col>
        </Row>
      </FormWrapper>
      <Modal.Footer>
        {() => (
          <Wrapper d-flex justify-content-end>
            {type !== 'create' && (
              <Button onClick={deleteAction} variant="danger" className="mr-3">
                {t('app:delete')}
              </Button>
            )}
            <div>
              <Button onClick={closePopup} variant="white" className="mr-3">
                {t('app:cancel')}
              </Button>
              <Form.Submit>
                {type === 'create' ? t('components:inviteRetailer.inviteNow') : t('app:save')}
              </Form.Submit>
            </div>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

RetailerForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  wrapperType: PropTypes.string,
};

export default RetailerForm;
