import React, { useEffect, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';

import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import EntityService from 'api/services/EntityService';
import { useSelector } from 'react-redux';
import { locationSelector } from 'redux/locations/selectors';

import * as Ui from './LooksCollection.styles';
import { LookSlide } from './components/LookSlide';

const LooksCollection = ({ component }) => {
  const { uid } = useSelector(locationSelector);

  const [selectedBundles, setSelectedBundles] = useState([]);
  const [primaryBundle, setPrimaryBundle] = useState();

  useEffect(() => {
    async function fetch() {
      if (uid) {
        const fetchedBundles = await EntityService.fetch('available_stores', uid);
        setPrimaryBundle(
          fetchedBundles.find(({ short_id }) => short_id === component.properties?.primaryBundle),
        );
        setSelectedBundles(
          fetchedBundles.filter(
            ({ short_id }) =>
              component.properties?.shortIds?.includes(short_id) &&
              short_id !== component.properties?.primaryBundle,
          ),
        );
      }
    }
    fetch();
  }, [component.properties.primaryBundle, JSON.stringify(component.properties.shortIds)]);

  if (!primaryBundle) {
    return (
      <Row>
        <i className="fe fe-edit d-flex justify-content-center align-items-center" />
      </Row>
    );
  }

  return (
    <Ui.Wrapper>
      <Ui.Container>
        <Ui.CarouselWrapper>
          <ScrollContainer className="d-flex w-100 position-relative" vertical>
            <LooksCollectionPureComponent looks={[primaryBundle, ...selectedBundles]} />
          </ScrollContainer>
        </Ui.CarouselWrapper>
      </Ui.Container>
    </Ui.Wrapper>
  );

  // return (
  //   <Row>
  //     {primaryBundle ? (
  //       <>
  //         <Col className="d-flex justify-content-center align-items-center flex-column">
  //           <div className="d-flex flex-column justify-content-center align-items-center">
  //             <div
  //               style={{ opacity: primaryBundle.is_deleted ? 0.4 : 1 }}
  //               className="d-flex flex-column align-items-center"
  //             >
  //               <h3>{t('app:primary')}</h3>
  //               <Image
  //                 src={primaryBundle.pictures[0] || placeHolder}
  //                 style={{ width: '80px', height: '60px', objectFit: 'cover' }}
  //               />
  //               <h4 className="mt-2 text-center">
  //                 {primaryBundle.name} {t('app:by')} {primaryBundle.owner.name}
  //               </h4>
  //             </div>
  //             {primaryBundle.is_deleted && (
  //               <h2 className="position-absolute text-center text-danger">
  //                 {t('app:expired').toUpperCase()}
  //               </h2>
  //             )}
  //           </div>
  //         </Col>
  //         {selectedBundles &&
  //           selectedBundles.map(bundle => (
  //             <Col className="d-flex justify-content-center position-relative align-items-center flex-column">
  //               <div
  //                 style={{ opacity: bundle.is_deleted ? 0.4 : 1 }}
  //                 className="d-flex flex-column justify-content-center align-items-center"
  //               >
  //                 <Image
  //                   src={bundle.pictures[0] || placeHolder}
  //                   style={{ width: '80px', height: '60px', objectFit: 'cover' }}
  //                 />
  //                 <h4 className="mt-2 text-center">
  //                   {bundle.name} {t('app:by')} {bundle.owner.name}
  //                 </h4>
  //               </div>
  //               {primaryBundle.is_deleted && (
  //                 <h2 className="position-absolute text-center text-danger">
  //                   {t('app:expired').toUpperCase()}
  //                 </h2>
  //               )}
  //             </Col>
  //           ))}
  //       </>
  //     ) : (
  //       <i className="fe fe-edit d-flex justify-content-center align-items-center" />
  //     )}
  //   </Row>
  // );
};

const LooksCollectionPureComponent = ({ looks, onClick, widget = false }) => (
  <Ui.Wrapper widget={widget}>
    <Ui.Container>
      <Ui.SectionTitle widget={widget}>Shoppable Looks</Ui.SectionTitle>
      <Ui.CarouselWrapper>
        <ScrollContainer className="d-flex w-100 position-relative" vertical>
          {looks.map(look => (
            <LookSlide key={look.short_id} look={look} onClick={onClick} widget={widget} />
          ))}
        </ScrollContainer>
      </Ui.CarouselWrapper>
    </Ui.Container>
  </Ui.Wrapper>
);

LooksCollection.propTypes = {
  component: PropTypes.object.isRequired,
};
LooksCollectionPureComponent.propTypes = {
  looks: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  widget: PropTypes.bool,
};

export default LooksCollection;
export { LooksCollectionPureComponent };
