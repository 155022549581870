import { brands } from 'constants/brands';

export const userEmailSelector = state => state.auth.user.email;
export const userLoggingSelector = state => state.auth.autoLoginProcessing;
export const adminSelector = state => state.auth.user.is_mac_admin;
export const superAdminSelector = state => state.auth.user.is_mac_super_admin;
export const brandAdminSelector = state => state.auth.user.is_brand_admin;
export const userSelector = state => state.auth.user;
export const tokenSelector = state => state.auth.token;
export const ownerSelector = state => state.auth.user.is_brand_owner;
export const managerSelector = state => state.auth.user.is_brand_manager;
export const isOwnerPortalUserSelector = state =>
  state.auth.user.is_brand_manager || state.auth.user.is_brand_owner;
export const brandSelector = state => state.auth.user.brand;
export const regionsSelector = state => state.auth.user.regions;
export const userPrimaryLocationIdSelector = state => {
  const { user } = state.auth;
  if (user.connected_location) {
    return user.locations.find(location => location.id === user.connected_location)?.id || null;
  }

  return user.locations[0]?.id;
};
export const roleSelector = state => state.auth.role;
export const isAvedaSelector = state => state.auth.user.brand?.preview === brands[0];

export const ramblaUserSelector = state => state.auth.rambla_user;
export const isRamblaUserApprovedSelector = state =>
  state.auth.rambla_user.admin_details.is_approved;
