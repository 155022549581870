import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { CardContainer, ImageWrapper } from 'components';
import { BrandInformationForm } from 'containers/forms';
import { ReactComponent as Icon } from '../../assets/img/illustrations/brand-information.svg';

const OnboardingBrandInformationPage = () => {
  const { t } = useTranslation(['pages']);

  return (
    <CardContainer lg="8">
      <Helmet>
        <title>{t('onboarding.brandInfoTitle')}</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.brandInfoTitle')}</h1>
      <h2 className="display-5 text-center">{t('onboarding.brandInfoSubtitle')}</h2>
      <ImageWrapper>
        <Icon />
      </ImageWrapper>
      <BrandInformationForm buttonText={t('brandRegistration.saveAndProceedToTheme')} />
    </CardContainer>
  );
};

export default OnboardingBrandInformationPage;
