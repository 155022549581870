import React from 'react';
import PropTypes from 'prop-types';
import avatarProfile from '../../../../../../assets/img/rambla-logo.png';
import * as Ui from './Stylist.styles';

export const Stylist = ({ stylist, noSlide = false, onClick, widget = false }) => {
  const getOnClick = onClick ? { onClick: () => onClick(stylist) } : {};
  return (
    <Ui.Slide widget={widget} noSlide={noSlide} role="button" {...getOnClick}>
      <Ui.CoverPhotoWrapper>
        <Ui.Image src={stylist.connected_user?.picture || stylist?.picture || avatarProfile} />
      </Ui.CoverPhotoWrapper>
      <Ui.Name>{stylist.connected_user?.name || stylist.name}</Ui.Name>
    </Ui.Slide>
  );
};

Stylist.propTypes = {
  stylist: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string,
    connected_user: PropTypes.arrayOf(
      PropTypes.shape({
        connected_user: PropTypes.shape({
          picture: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    ),
  }),
  noSlide: PropTypes.bool,
  onClick: PropTypes.func,
  widget: PropTypes.bool,
};
