import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import { Caption as StyledCaption } from './styles';

const FormHelpBox = props => {
  const { helpContentKey, content, valueName } = props;
  const { values } = useFormikContext();

  const generateHelpContent = key => {
    const { helpText } = key
      ? content.find(item => item.key === key)
      : content.find(item => item.key === 'default');

    return (
      <div className="mt-3">
        <p>{helpText}</p>
      </div>
    );
  };

  return (
    <StyledCaption className="container-fluid mt-4 px-3 border-teal-500 rounded">
      <div className="row">
        <Form.Label>
          {helpContentKey
            ? generateHelpContent(helpContentKey, values[valueName])
            : generateHelpContent(values[valueName])}
        </Form.Label>
      </div>
    </StyledCaption>
  );
};

FormHelpBox.propTypes = {
  helpContentKey: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object),
  valueName: PropTypes.string,
};

export default FormHelpBox;
