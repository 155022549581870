import { all } from 'redux-saga/effects';
import { authSagas } from './auth/sagas';
import { brandSagas } from './brand/sagas';
import { regionSagas } from './regions/sagas';
import { retailerSagas } from './retailers/sagas';
import { storeSagas } from './stores/sagas';
import { productSagas } from './products/sagas';
import { entitySagas } from './entities/sagas';
import { locationSagas } from './locations/sagas';
import { looksSagas } from './looks/sagas';
import { featuredArtistsSagas } from './featuredArtists/sagas';

export default function* root() {
  yield all([
    ...authSagas,
    ...brandSagas,
    ...regionSagas,
    ...retailerSagas,
    ...storeSagas,
    ...productSagas,
    ...entitySagas,
    ...locationSagas,
    ...looksSagas,
    ...featuredArtistsSagas,
  ]);
}
