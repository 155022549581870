import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { pick } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { Form, ThemeVisualizer } from 'components';
import { updateTheme } from 'redux/brand/actions';
import { ImageUpload } from 'components/Form/inputs/ImageUpload/ImageUpload';
import { uploadMedia } from 'utils/mediaResourceUtils';
import { mapInitialValues } from '../../utils/themeCustomizationFormUtils';
import { ONBOARDING } from '../../constants/routes';

import { ReactComponent as Icon } from '../../assets/img/illustrations/theme-customization.svg';

const ThemeCustomizationForm = ({ buttonText, handleClick, initialValues }) => {
  const { t } = useTranslation(['pages']);
  const dispatch = useDispatch();
  const location = useLocation();

  const mappedInitialValues = useMemo(() => mapInitialValues(initialValues), [initialValues]);
  const [themeColor, setThemeColor] = useState(initialValues.color);
  const [file, setFile] = useState(null);

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      setThemeColor(values.color);
    }, [values.color]);

    return null;
  };

  return (
    <Form
      initialValues={
        pick(mappedInitialValues, ['uid', 'thumbnail', 'images', 'color']) || {
          thumbnail: [],
          images: [],
          color: undefined,
        }
      }
      onSubmit={async ({ uid, thumbnail, color }) => {
        const { public_id } = await uploadMedia(thumbnail);
        dispatch(
          updateTheme({
            uid,
            color,
            picture_id: public_id,
            is_onboarding: location.pathname.includes(ONBOARDING),
          }),
        );
      }}
      validationSchema={Yup.object({
        color: Yup.string().required(t('validation:required')),
        thumbnail: Yup.array().required(t('validation:required')),
      })}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '3rem',
          marginBottom: '2rem',
        }}
      >
        {themeColor || file ? (
          <div
            style={{
              height: 'auto',
              width: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                // position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              }}
            >
              <ThemeVisualizer mainColor={themeColor} />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '24px',
                left: '122px',
                maxWidth: '30px',
                maxHeight: '20px',
              }}
            >
              {file && <img width="100%" alt={file?.name || 'preview'} src={file?.url || file} />}
            </div>
          </div>
        ) : (
          <Icon />
        )}
      </div>
      <Form.Group>
        <Form.Label>{t('brandRegistration.color')}</Form.Label>
        <Form.Input className="p-3" name="color" type="color" />
      </Form.Group>

      <Form.Group>
        <Form.Label>{t(`brandRegistration.logo`)}</Form.Label>
        <ImageUpload accept="image/*" name="thumbnail" maxFiles={1} setFile={setFile} />
      </Form.Group>

      <Form.Submit
        className="mt-4"
        size="lg"
        variant="primary"
        withArrows
        block
        onClick={handleClick}
      >
        {buttonText || `${t('brandRegistration.save')}`}
      </Form.Submit>
      <FormObserver />
    </Form>
  );
};

ThemeCustomizationForm.propTypes = {
  handleClick: PropTypes.func,
  buttonText: PropTypes.string,
  initialValues: PropTypes.object,
};

export default ThemeCustomizationForm;
