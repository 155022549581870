import React from 'react';
import PropTypes from 'prop-types';

import * as forms from 'containers/forms';
import { upperFirst } from 'lodash';
import entities from 'constants/entities';
import { Form } from 'components';

const EntityForm = ({ entity, initialValues, type, ...rest }) => {
  let formName;

  switch (entity) {
    case 'parent_product':
      formName = 'ParentProductForm';
      break;
    case 'child_product':
      formName = 'ChildProductForm';
      break;
    default:
      formName = `${upperFirst(entity)}Form`;
      break;
  }

  const Component = forms[formName];

  const FormGroup = type === 'create' ? Form.GroupVertical : Form.GroupHorizontal;

  return <Component initialValues={initialValues} type={type} FormGroup={FormGroup} {...rest} />;
};

EntityForm.propTypes = {
  entity: PropTypes.oneOf(Object.keys(entities)).isRequired,
  initialValues: PropTypes.object,
  type: PropTypes.oneOf(['create', 'update']),
};

export default EntityForm;
