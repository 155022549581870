import styled, { keyframes } from 'styled-components';
import { media } from 'utils/rwd';
import { MODAL_SIZE } from './constants';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const iconStyle = {
  height: '14px',
  verticalAlign: 'middle',
  width: '14px',
};

export const StyledCloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: end;
  padding: 8px 0;
`;

export const StyledDialog = styled.div`
  align-items: center;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-name: ${({ $isFadingOut }) => ($isFadingOut ? fadeOutAnimation : fadeInAnimation)};
  animation-timing-function: ease-out;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;
`;

export const StyledDialogButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: #120e02;
  color: #fff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  order: 0;
  align-self: stretch;
  text-transform: uppercase;
  border-radius: 8px;
  border: none;
`;

export const StyledDialogButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 32px;
  gap: 12px;
`;

export const StyledDialogButtonWrapper = styled.div`
  flex-basis: 100%;
  margin-top: 16px;
  width: 100%;

  ${media.md`
    display: block;
    flex-basis: auto;
    margin-top: auto;
    width: auto;
    flex: 1;
`}
`;

export const StyledDialogContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  display: block;
  min-width: ${({ $size }) => ($size === MODAL_SIZE.LARGE ? '80vw' : '300px')};

  max-width: ${({ $size }) => ($size === MODAL_SIZE.LARGE ? '100vw' : '335px')};

  padding: 24px;
  position: relative;
  max-height: 85vh;
  overflow-y: auto;

  ${media.md`
    max-width: ${({ $size }) => ($size === MODAL_SIZE.LARGE ? '80vw' : '628px')};
	`}
`;

export const StyledDialogContent = styled.div.attrs({ className: 'mt-4' })``;

export const StyledDialogDescription = styled.h4`
  font-family: 'MetaOT' !important;
  color: #525252;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
`;

export const StyledDialogHeading = styled.h2`
  font-family: 'MetaOT' !important;
  color: #120e02;
  margin-bottom: 8px;
  margin-top: 13px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
`;

export const StyledDialogCloseButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  height: 48px;
  text-transform: uppercase;
  background: #ffffff;
  border: 1px solid #737373;
  border-radius: 8px;
  order: 0;
  flex-grow: 1;
`;
