import produce from 'immer';
import { cloneDeep } from 'lodash-es';
import {
  LOCATION_SELECT,
  UPDATE_SELECTED_LOCATION,
  CHANGE_PAGE_COMPONENT,
  INSERT_PAGE_COMPONENT,
  DELETE_PAGE_COMPONENT,
  MOVE_PAGE_COMPONENT,
  UPDATE_HEADER,
  SET_COMPONENT_EDITIONG,
  SET_SAVING,
  GET_LOCATION_GROUP_SUCCESS,
  UPDATE_LOCATION_GROUP_SUCCESS,
} from './constants';

export const initialState = {
  selectedLocation: null,
  body: null,
  isSaving: false,
  isEdited: false,
  isComponentEditing: false,
  locationsGroup: null,
  classification: [],
};

const locationReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case LOCATION_SELECT:
        draft.isEdited = false;
        draft.isSaving = false;
        draft.selectedLocation = payload.location;
        draft.body = cloneDeep(payload.location.page || []).sort((a, b) => a.position - b.position);
        break;
      case 'UPDATE_LOCATION_SUCCESS':
        draft.isEdited = false;
        draft.isSaving = false;
        draft.selectedLocation = payload;
        draft.body = cloneDeep(payload.page || []).sort((a, b) => a.position - b.position);
        break;
      case UPDATE_HEADER:
        draft.selectedLocation = { ...draft.selectedLocation, ...payload.location };
        draft.isEdited = true;
        break;
      case UPDATE_SELECTED_LOCATION:
        draft.isSaving = true;
        break;
      // eslint-disable-next-line no-fallthrough
      case 'UPDATE_LOCATION_FAILURE':
        draft.isSaving = false;
        break;
      case SET_COMPONENT_EDITIONG:
        draft.isComponentEditing = payload.value;
        break;
      case SET_SAVING:
        draft.isSaving = payload.value;
        break;
      case CHANGE_PAGE_COMPONENT: {
        draft.isComponentEditing = false;
        draft.isEdited = true;
        const array = [...draft.body];
        const idx = array.findIndex(component => component.position === payload.component.position);
        array[idx] = payload.component;
        draft.body = array;
        break;
      }
      case INSERT_PAGE_COMPONENT:
        draft.isEdited = true;

        draft.body = [
          ...draft.body,
          {
            name: payload.name,
            nameTranslationPath: payload.nameTranslationPath,
            position: draft.body.length,
            properties: {},
          },
        ];
        break;
      case DELETE_PAGE_COMPONENT: {
        draft.isEdited = true;

        const array = [...draft.body];
        const idx = array.findIndex(component => component.position === payload.position);
        for (let i = idx; i < array.length; i++) {
          array[i].position--;
        }
        array.splice(idx, 1);
        draft.body = array;
        break;
      }
      case MOVE_PAGE_COMPONENT: {
        const { cuurentPosition, previousPosition } = payload;
        draft.isEdited = true;

        const array = [...draft.body];
        const movedComponentIdx = array.findIndex(
          component => component.position === previousPosition,
        );

        [array[movedComponentIdx], array[cuurentPosition]] = [
          array[cuurentPosition],
          array[movedComponentIdx],
        ]; // swaps elements of array
        // change positions of elements
        const tmp = array[movedComponentIdx].position;
        array[movedComponentIdx].position = array[cuurentPosition].position;
        array[cuurentPosition].position = tmp;
        draft.body = array;
        break;
      }
      case GET_LOCATION_GROUP_SUCCESS: {
        draft.locationsGroup = payload;
        draft.locationsGroup.fetched = true;

        break;
      }
      case UPDATE_LOCATION_GROUP_SUCCESS: {
        draft.locationsGroup = payload;
        draft.locationsGroup.fetched = true;

        break;
      }
      default:
        return state;
    }
  });

export default locationReducer;
