import ImageService from 'api/services/ImageService';
import { toastr } from 'utils';
import i18n from 'i18n';

export const uploadMedia = async ([image], forceUpload = false) => {
  try {
    const isAlreadyuploaded = !image.path && !image.size;
    if (isAlreadyuploaded && !forceUpload) {
      return { public_id: image };
    }
    const signature = await ImageService.uploadSignature();
    return await ImageService.uploadCloudinary(image, signature);
  } catch (error) {
    toastr.error(i18n.t('toastr:failure'), i18n.t('toastr:imageError'));
  }
};

export const uploadMediaWithInitialState = async ([image], initial) => {
  try {
    const isAlreadyuploaded = !image.path && !image.size;
    if (isAlreadyuploaded && initial) {
      const finded = initial.find(el => el.url === image);
      return {
        resource_type: finded?.type,
        url: finded?.url,
      };
    }
    const scope = image?.type?.startsWith('video') ? 'product_video' : 'product_image';
    const signature = await ImageService.uploadSignature({ scope });
    return await ImageService.uploadCloudinary(image, signature);
  } catch (error) {
    toastr.error('Failed', "Coudn't upload the image, please try upload image once again");
  }
};

export const uploadImages = async images => {
  try {
    const notUploadedAlready = images.filter(image => !!image.path);
    const uploadedAlready = images.filter(image => !image.path);

    const signatures = await Promise.all(
      notUploadedAlready.map(() => ImageService.uploadSignature({ scope: 'product_image' })),
    );
    const promises = notUploadedAlready.map((image, index) =>
      ImageService.uploadCloudinary(image, signatures[index]),
    );

    const uploaded = await Promise.all(promises);

    return [...uploadedAlready, ...uploaded?.map(item => item.public_id)];
  } catch (error) {
    toastr.error(i18n.t('toastr:failure'), i18n.t('toastr:imageError'));
  }
};
