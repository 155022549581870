import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CardContainer, ImageWrapper } from 'components';
import { ROOT } from 'constants/routes';
import useQuery from 'hooks/useQuery';
import { ReactComponent as IconSuccess } from '../../assets/img/illustrations/admin-approval-success.svg';
import { ReactComponent as IconFailure } from '../../assets/img/illustrations/admin-approval-failure.svg';
import AuthServiceRambla from '../../api/services/AuthServiceRambla';

const RegisterApprovalPage = () => {
  const { t } = useTranslation(['pages']);
  const { uid } = useParams();
  const query = useQuery();
  const [status, setStatus] = useState(null);

  const verification_code_param = query.get('verification_code');

  useEffect(() => {
    AuthServiceRambla.registerApproval(uid, verification_code_param)
      .then(() => setStatus(200))
      .catch(error => {
        setStatus(error.response.status);
      });
  }, []);

  if (!status) {
    return null;
  }

  const pageTitle =
    status !== 200
      ? t('adminRegistration.approvalTitleFailure')
      : t('adminRegistration.approvalTitleSuccess');

  const pageIcon = status !== 200 ? <IconFailure /> : <IconSuccess />;

  return (
    <CardContainer lg="8">
      <Helmet>
        <title>Admin Account Approval</title>
      </Helmet>
      <h1 className="display-4 text-center mb-4">{pageTitle}</h1>
      <ImageWrapper>{pageIcon}</ImageWrapper>
      <Link to={ROOT}>
        <Button className="mt-5" block variant="primary" type="submit">
          {t('adminRegistration.backToLogin')}
        </Button>
      </Link>
    </CardContainer>
  );
};

export default RegisterApprovalPage;
