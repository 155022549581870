import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowRoundBack } from 'react-icons/io';

import history from 'utils/history';
import { LayoutContext } from 'components/Layout/layout';
import { Spinner, Wrapper, Button as ComponentsButton } from 'components';
import * as Ui from './Page.styles';

const Page = ({
  children,
  logo,
  title,
  description,
  withBackButton = true,
  loading = false,
  onBack,
  Button,
  footer,
  ...rest
}) => {
  const { sideBarCollapsed } = useContext(LayoutContext);
  const footerRef = useRef();

  const renderFooter = () => {
    if (footer && footer?.visible) {
      return (
        <Ui.Footer ref={footerRef} sideBarCollapsed={sideBarCollapsed}>
          {footer.component}
        </Ui.Footer>
      );
    }
    return null;
  };

  return (
    <Ui.Container paddingBottom={(footerRef.current?.clientHeight || 0) + 30} {...rest}>
      {(logo || title || description) && (
        <Ui.TitleWrapper withBackButton={withBackButton}>
          <Wrapper d-flex>
            {logo && <Ui.Icon>{logo}</Ui.Icon>}
            <Wrapper d-flex flex-column pt-2 pl-4>
              {title && <h1>{title}</h1>}
              {description && <p>{description}</p>}
            </Wrapper>
          </Wrapper>

          {!!Button && <Button />}
        </Ui.TitleWrapper>
      )}
      {withBackButton && (
        <ComponentsButton
          className="mb-4"
          variant="light"
          onClick={() => {
            if (onBack) {
              onBack();
            } else {
              history.goBack();
            }
          }}
        >
          <IoIosArrowRoundBack size={16} />
          Go back
        </ComponentsButton>
      )}
      {loading ? (
        <div
          style={{ height: `calc(100vh - 360px)` }}
          className="d-flex justify-content-center align-items-center w-100"
        >
          <Spinner />
        </div>
      ) : (
        <>
          {children}
          {renderFooter()}
        </>
      )}
    </Ui.Container>
  );
};

Page.propTypes = {
  logo: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  withBackButton: PropTypes.bool,
  loading: PropTypes.bool,
  Button: PropTypes.node,
  onBack: PropTypes.func,
  avedaPage: PropTypes.bool,
  footer: PropTypes.objectOf({
    component: PropTypes.element,
    visible: PropTypes.bool,
  }),
};

export default Page;
