import produce from 'immer';
import {
  RAMBLA_CREATE_PARENT_PRODUCT_SUCCESS,
  RAMBLA_DELETE_PARENT_PRODUCT_SUCCESS,
  RAMBLA_FETCH_PARENT_PRODUCTS_SUCCESS,
  RAMBLA_UPDATE_PARENT_PRODUCT_SUCCESS,
  RAMBLA_CREATE_CHILD_PRODUCT_SUCCESS,
  RAMBLA_DELETE_CHILD_PRODUCT_SUCCESS,
  RAMBLA_FETCH_CHILD_PRODUCTS_SUCCESS,
  RAMBLA_UPDATE_CHILD_PRODUCT_SUCCESS,
  RAMBLA_CREATE_COLOR_SUCCESS,
  RAMBLA_DELETE_COLOR_SUCCESS,
  RAMBLA_FETCH_COLORS_SUCCESS,
  RAMBLA_UPDATE_COLOR_SUCCESS,
  RAMBLA_CREATE_SIZE_SUCCESS,
  RAMBLA_DELETE_SIZE_SUCCESS,
  RAMBLA_FETCH_SIZES_SUCCESS,
  RAMBLA_UPDATE_SIZE_SUCCESS,
  RAMBLA_CREATE_CATEGORY_SUCCESS,
  RAMBLA_DELETE_CATEGORY_SUCCESS,
  RAMBLA_FETCH_CATEGORIES_SUCCESS,
  RAMBLA_UPDATE_CATEGORY_SUCCESS,
  RAMBLA_CREATE_INVENTORY_SUCCESS,
  RAMBLA_DELETE_INVENTORY_SUCCESS,
  RAMBLA_FETCH_INVENTORIES_SUCCESS,
  RAMBLA_UPDATE_INVENTORY_SUCCESS,
} from './constants';

export const initialState = {
  parent_products: { fetched: false, data: [] },
  child_products: { fetched: false, data: [] },
  colors: { fetched: false, data: [] },
  sizes: { fetched: false, data: [] },
  categories: { fetched: false, data: [] },
  inventories: { fetched: false, data: [] },
};

const colorReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case RAMBLA_FETCH_PARENT_PRODUCTS_SUCCESS:
        draft.parent_products.data = payload;
        draft.parent_products.fetched = true;
        break;
      case RAMBLA_CREATE_PARENT_PRODUCT_SUCCESS:
        draft.parent_products.data.push(payload);
        break;
      case RAMBLA_UPDATE_PARENT_PRODUCT_SUCCESS:
        draft.parent_products.data = draft.parent_products.data.map(parent_product =>
          parent_product.uid === payload.uid ? payload : parent_product,
        );
        break;
      case RAMBLA_DELETE_PARENT_PRODUCT_SUCCESS:
        draft.parent_products.data = draft.parent_products.data.filter(
          parent_product => parent_product.uid !== payload.uid,
        );
        break;
      case RAMBLA_FETCH_CHILD_PRODUCTS_SUCCESS:
        draft.child_products.data = payload;
        draft.child_products.fetched = true;
        break;
      case RAMBLA_CREATE_CHILD_PRODUCT_SUCCESS:
        draft.child_products.data.push(payload);
        break;
      case RAMBLA_UPDATE_CHILD_PRODUCT_SUCCESS:
        draft.child_products.data = draft.child_products.data.map(child_product =>
          child_product.uid === payload.uid ? payload : child_product,
        );
        break;
      case RAMBLA_DELETE_CHILD_PRODUCT_SUCCESS:
        draft.child_products.data = draft.child_products.data.filter(
          child_product => child_product.uid !== payload.uid,
        );
        break;
      case RAMBLA_FETCH_COLORS_SUCCESS:
        draft.colors.data = payload;
        draft.colors.fetched = true;
        break;
      case RAMBLA_CREATE_COLOR_SUCCESS:
        draft.colors.data.push(payload);
        break;
      case RAMBLA_UPDATE_COLOR_SUCCESS:
        draft.colors.data = draft.colors.data.map(color =>
          color.uid === payload.uid ? payload : color,
        );
        break;
      case RAMBLA_DELETE_COLOR_SUCCESS:
        draft.colors.data = draft.colors.data.filter(color => color.uid !== payload.uid);
        break;
      case RAMBLA_FETCH_SIZES_SUCCESS:
        draft.sizes.data = payload;
        draft.sizes.fetched = true;
        break;
      case RAMBLA_CREATE_SIZE_SUCCESS:
        draft.sizes.data.push(payload);
        break;
      case RAMBLA_UPDATE_SIZE_SUCCESS:
        draft.sizes.data = draft.sizes.data.map(size =>
          size.uid === payload.uid ? payload : size,
        );
        break;
      case RAMBLA_DELETE_SIZE_SUCCESS:
        draft.sizes.data = draft.sizes.data.filter(size => size.uid !== payload.uid);
        break;
      case RAMBLA_FETCH_CATEGORIES_SUCCESS:
        draft.categories.data = payload;
        draft.categories.fetched = true;
        break;
      case RAMBLA_CREATE_CATEGORY_SUCCESS:
        draft.categories.data.push(payload);
        break;
      case RAMBLA_UPDATE_CATEGORY_SUCCESS:
        draft.categories.data = draft.categories.data.map(category =>
          category.uid === payload.uid ? payload : category,
        );
        break;
      case RAMBLA_DELETE_CATEGORY_SUCCESS:
        draft.categories.data = draft.categories.data.filter(
          category => category.uid !== payload.uid,
        );
        break;
      case RAMBLA_FETCH_INVENTORIES_SUCCESS:
        draft.inventories.data = payload;
        draft.inventories.fetched = true;
        break;
      case RAMBLA_CREATE_INVENTORY_SUCCESS:
        draft.inventories.data.push(payload);
        break;
      case RAMBLA_UPDATE_INVENTORY_SUCCESS:
        draft.inventories.data = draft.inventories.data.map(inventory =>
          inventory.uid === payload.uid ? payload : inventory,
        );
        break;
      case RAMBLA_DELETE_INVENTORY_SUCCESS:
        draft.inventories.data = draft.inventories.data.filter(
          inventory => inventory.uid !== payload.uid,
        );
        break;
      default:
        return state;
    }
  });

export default colorReducer;
