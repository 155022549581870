import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Form } from 'components';
import FormHelpBox from 'components/Form/formHelpBox';

import { EMAIL_INVITE, EMAIL_INVITE_APPROVAL, SSO_INTEGRATION } from 'constants/brandConfiguration';
import { ONBOARDING_COMMISSION_TRACKING } from 'constants/routes';

import useEntityForm from 'hooks/useEntityForm';
import { brandSelector } from 'redux/brand/selectors';
import { updateBrandConfiguration } from 'redux/brand/actions';

const OnboardingSellerAccessForm = ({ type = 'update', initialValues }) => {
  const { t } = useTranslation(['pages', 'app']);
  const dispatch = useDispatch();
  const [helpContentKey, setHelpContentKey] = useState(null);
  const { validationSchema } = useEntityForm(type, 'sellerAccess');

  const nextStep = ONBOARDING_COMMISSION_TRACKING;
  const flagName = 'retailers_invite';

  const content = [
    { key: EMAIL_INVITE, helpText: t('pages:sellerAccess.emailOnlyHelp') },
    { key: EMAIL_INVITE_APPROVAL, helpText: t('pages:sellerAccess.emailApprovalFlowHelp') },
    { key: SSO_INTEGRATION, helpText: t('pages:sellerAccess.ssoIntegrationHelp') },
    { key: 'default', helpText: t('pages:sellerAccess.defaultHelp') },
  ];

  const brand = useSelector(brandSelector);

  const resetHelpContentKey = () => {
    setHelpContentKey(null);
  };

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, ['uid', flagName]),
        } || {
          flagNames: null,
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={async ({ retailers_invite }) => {
        const { uid } = brand.data.config;
        dispatch(updateBrandConfiguration({ uid, configuration: { retailers_invite }, nextStep }));
      }}
      validationSchema={validationSchema}
    >
      <Form.Group id={flagName} label={t('pages:sellerAccess.name')}>
        <Form.RadioButton
          id={`option-${EMAIL_INVITE}`}
          name={flagName}
          value={EMAIL_INVITE}
          label={t('pages:sellerAccess.emailOnlyRadioLabel')}
          handleMouseOver={() => setHelpContentKey(EMAIL_INVITE)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${EMAIL_INVITE_APPROVAL}`}
          name={flagName}
          value={EMAIL_INVITE_APPROVAL}
          label={t('pages:sellerAccess.emailApprovalFlowRadioLabel')}
          handleMouseOver={() => setHelpContentKey(EMAIL_INVITE_APPROVAL)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${SSO_INTEGRATION}`}
          name={flagName}
          value={SSO_INTEGRATION}
          label={t('pages:sellerAccess.ssoIntegrationRadioLabel')}
          handleMouseOver={() => setHelpContentKey(SSO_INTEGRATION)}
          handleMouseOut={() => resetHelpContentKey()}
        />
      </Form.Group>
      <FormHelpBox helpContentKey={helpContentKey} content={content} valueName={flagName} />
      <Form.Submit className="mt-5" size="lg" variant="primary" withArrows block>
        {t('onboarding.nextStep')}
      </Form.Submit>
    </Form>
  );
};

OnboardingSellerAccessForm.propTypes = {
  type: PropTypes.oneOf(['update']),
  initialValues: PropTypes.object,
};

export default OnboardingSellerAccessForm;
