import { groupProductsByCategory } from 'utils/products';

export const userLooksSelector = state => state.looks.userLooks.data;
export const areLooksFetchedSelector = state => state.looks.userLooks.fetched;
export const singleLookSelector = state => state.looks.look;
export const singleLookCoverPhotosSelector = state => state.looks.look.data.cover_photos;
export const lookProductsSelector = state => state.looks.look.data?.products || [];
export const availableProductsSelector = state => state.looks.availableProducts;
export const filteredProductsSelector = state => state.looks.filteredProducts;
export const productsByCategorySelector = state =>
  groupProductsByCategory(state.looks.availableProducts);
export const productsCategoriesSelector = state =>
  Object.keys(groupProductsByCategory(state.looks.availableProducts));
