import * as types from './constants';

export const getAvailableProducts = () => ({
  type: types.GET_AVAILABLE_PRODUCTS,
  payload: {},
});

export const getAvailableProductsSuccess = products => ({
  type: types.GET_AVAILABLE_PRODUCTS_SUCCESS,
  payload: products,
});

export const getUserLooks = () => ({
  type: types.GET_USER_LOOKS,
  payload: {},
});

export const getTemplateLooks = () => ({
  type: types.GET_TEMPLATE_LOOKS,
  payload: {},
});

export const getTemplateLooksSuccess = payload => ({
  type: types.GET_TEMPLATE_LOOKS_SUCCESS,
  payload,
});

export const getUserLooksSuccess = looks => ({
  type: types.GET_USER_LOOKS_SUCCESS,
  payload: looks,
});

export const getSingleLook = lookId => ({
  type: types.GET_LOOK,
  payload: lookId,
});

export const getSingleLookSuccess = look => ({
  type: types.GET_LOOK_SUCCESS,
  payload: look,
});

export const updateLookCoverPhoto = ({ image, aspectRatio }, isRetry = false) => ({
  type: types.UPDATE_LOOK_COVER_PHOTO,
  payload: { image, aspectRatio },
  isRetry,
});

export const addCoverPhoto = image => ({
  type: types.ADD_COVER_PHOTO,
  payload: image,
});

export const getCoverPhotos = () => ({
  type: types.GET_COVER_PHOTOS,
  payload: {},
});

export const getCoverPhotosSuccess = coverPhotos => ({
  type: types.GET_COVER_PHOTOS_SUCCESS,
  payload: coverPhotos,
});

export const removeCoverPhoto = coverPhotoId => ({
  type: types.REMOVE_COVER_PHOTO,
  payload: coverPhotoId,
});

export const removeCoverPhotoSuccess = coverPhotoId => ({
  type: types.REMOVE_COVER_PHOTO_SUCCESS,
  payload: coverPhotoId,
});

export const updateLookIsPrivate = isPrivate => ({
  type: types.UPDATE_LOOK_IS_PRIVATE,
  payload: isPrivate,
});

export const updateIsPrivateSuccess = isPrivate => ({
  type: types.UPDATE_LOOK_IS_PRIVATE_SUCCESS,
  payload: isPrivate,
});

export const updateLook = (values, propertyName) => ({
  type: types.UPDATE_LOOK,
  payload: {
    values,
    propertyName,
  },
});

export const updateLookSuccess = look => ({
  type: types.UPDATE_LOOK_SUCCESS,
  payload: look,
});

export const updateLookCoverPhotoSuccess = photoUrl => ({
  type: types.UPDATE_LOOK_COVER_PHOTO_SUCCESS,
  payload: photoUrl,
});

export const createNewLook = () => ({
  type: types.LOOK_CREATE_NEW,
  payload: {},
});

export const createNewTemplateLook = () => ({
  type: types.TEMPLATE_LOOK_CREATE_NEW,
  payload: {},
});

export const searchProducts = value => ({
  type: types.SEARCH_PRODUCTS,
  payload: value,
});

export const searchProductsSuccess = products => ({
  type: types.SEARCH_PRODUCTS_SUCCESS,
  payload: products,
});

export const deleteLook = (lookId, isTemplateLook = false) => ({
  type: types.DELETE_LOOK,
  payload: { lookId, isTemplateLook },
});

export const deleteLookSuccess = lookId => ({
  type: types.DELETE_LOOK_SUCCESS,
  payload: lookId,
});

export const deleteTemplateLookSuccess = lookUid => ({
  type: types.DELETE_TEMPLATE_LOOK_SUCCESS,
  payload: lookUid,
});

export const addProductsToLook = payload => ({
  type: types.ADD_PRODUCTS_TO_LOOK,
  payload,
});

export const addProductsToLookSuccess = payload => ({
  type: types.ADD_PRODUCTS_TO_LOOK_SUCCESS,
  payload,
});

export const createLookFromDraft = () => ({
  type: types.CREATE_LOOK_FROM_DRAFT,
});

export const removeProductFromLook = productId => ({
  type: types.REMOVE_PRODUCT_FROM_LOOK,
  payload: {
    productId,
  },
});

export const removeProductFromLookSuccess = productId => ({
  type: types.REMOVE_PRODUCT_FROM_LOOK_SUCCESS,
  payload: {
    productId,
  },
});

export const clearLook = lookId => ({
  type: types.CLEAR_LOOK,
  payload: lookId,
});
