export default {
  apiBaseUrl: 'https://mac-staging-api.hausmart.com',
  apiBaseUrlRambla: 'https://rambla-dev-api.hausmart.com',
  avedaWebApp: 'https://avedanow.netlify.app',
  webAppUrl: 'https://avedanow.netlify.app',
  getAppUrl: 'https://macnow.app.link/',
  avedaCheckoutPrepopulatedUrl:
    'https://www.aveda.com/shared/add_to_bag.tmpl?SKU_BASE_ID=122899:1,110558:1&socialshoppe=1&applied_offer_confirm=1&promocode=',
  macCheckoutPrepopulatedUrl:
    'https://maccosmetics.de/checkout/viewcart.tmpl?socialshoppe=1&applied_offer_confirm=1&promocode=',
  featureFlags: {
    quicksight: true,
    ownerGroup: true,
    offerCodes: false,
    templateLooks: true,
    landingPage: false,
    embeddableWidgets: false,
  },
};
