import {
  RAMBLA_FETCH_RETAILERS,
  RAMBLA_FETCH_RETAILERS_SUCCESS,
  RAMBLA_UPDATE_RETAILER,
  RAMBLA_UPDATE_RETAILER_SUCCESS,
  RAMBLA_DELETE_RETAILER,
  RAMBLA_DELETE_RETAILER_SUCCESS,
  RAMBLA_INVITE_RETAILER,
  RAMBLA_INVITE_RETAILER_SUCCESS,
} from './constants';

export const fetchRetailersRambla = params => ({
  type: RAMBLA_FETCH_RETAILERS,
  payload: params,
});

export const fetchRetailersRamblaSuccess = retailers => ({
  type: RAMBLA_FETCH_RETAILERS_SUCCESS,
  payload: retailers,
});

export const updateRetailerRambla = ({ uid, email, status, region_uid }) => ({
  type: RAMBLA_UPDATE_RETAILER,
  payload: { uid, email, status, region_uid },
});

export const updateRetailerRamblaSuccess = retailer => ({
  type: RAMBLA_UPDATE_RETAILER_SUCCESS,
  payload: retailer,
});

export const deleteRetailerRambla = uid => ({
  type: RAMBLA_DELETE_RETAILER,
  payload: { uid },
});

export const deleteRetailerRamblaSuccess = uid => ({
  type: RAMBLA_DELETE_RETAILER_SUCCESS,
  payload: { uid },
});

export const inviteRetailerRambla = ({ email, region_uid }) => ({
  type: RAMBLA_INVITE_RETAILER,
  payload: { email, region_uid },
});

export const inviteRetailerRamblaSuccess = retailer => ({
  type: RAMBLA_INVITE_RETAILER_SUCCESS,
  payload: retailer,
});
