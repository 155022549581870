import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { Form, Spinner } from 'components';
import useQuery from 'hooks/useQuery';
import { createBrand, updateBrand } from 'redux/brand/actions';

const BrandInformationForm = ({ type = 'create', buttonText, initialValues }) => {
  const [domainName, setDomainName] = useState('');
  const [isDomainAvailable, setIsDomainAvailable] = useState(false);
  const [isCheckingDomainAvailability, setIsCheckingDomainAvailability] = useState(false);

  const DomainAvailabilityCheck = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      if (values.domain.length && domainName !== values.domain) {
        setDomainName(values.domain);
        setIsCheckingDomainAvailability(true);
        setTimeout(() => setIsCheckingDomainAvailability(false), 3000);
        setTimeout(() => setIsDomainAvailable(true), 3000);
      } else if (domainName !== values.domain) {
        setIsDomainAvailable(false);
      }
    }, [values]);
    return null;
  };

  const { t } = useTranslation(['pages']);
  const query = useQuery();
  const dispatch = useDispatch();

  const domainTaken = 'fe-x-circle text-danger';
  const domainAvailable = 'fe-check-circle text-success';

  return (
    <Form
      initialValues={{
        uid: query.get('uid') || initialValues?.uid || '',
        name: query.get('name') || initialValues?.name || '',
        domain: query.get('domain') || initialValues?.domain || '',
      }}
      onSubmit={({ uid, name, domain }) =>
        type === 'update'
          ? dispatch(updateBrand(uid, name, domain))
          : dispatch(createBrand(name, domain))
      }
      validationSchema={Yup.object({
        name: Yup.string()
          .min(3, t('validation:atLeastChars', { number: 3 }))
          .max(50, t('validation:atMostChars', { number: 50 }))
          .matches(/^[a-zA-Z0-9_ ]*$/, t('validation:mustNotContainSpecialChars'))
          .required(t('validation:required')),
        domain: Yup.string()
          .min(3, t('validation:atLeastChars', { number: 3 }))
          .max(50, t('validation:atMostChars', { number: 50 }))
          .matches(
            /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/,
            t('validation:mustBeValidDomainName'),
          )
          .required(t('validation:required')),
      })}
    >
      <Form.Group>
        <Form.Label>{t('brandRegistration.name')}</Form.Label>
        <Form.Input name="name" type="text" placeholder="Hausmart" />
      </Form.Group>

      <Form.Group>
        <Form.Label>{t('brandRegistration.domain')}</Form.Label>
        <Form.Input
          name="domain"
          type="text"
          placeholder="hausmart"
          append=".ramblas.io"
          prepend={
            isCheckingDomainAvailability ? (
              <Spinner style={{ width: '17px', height: '17px' }} />
            ) : isDomainAvailable ? (
              <i className={`fe ${domainAvailable}`} />
            ) : (
              <i className={`fe ${domainTaken}`} />
            )
          }
        />
      </Form.Group>

      <Form.Submit className="mt-5" size="lg" variant="primary" block>
        {buttonText || `${t('brandRegistration.save')}`}
      </Form.Submit>

      <DomainAvailabilityCheck />
    </Form>
  );
};

BrandInformationForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  buttonText: PropTypes.string,
  initialValues: PropTypes.object,
};

export default BrandInformationForm;
