import styled, { keyframes } from 'styled-components/macro';

const animationSpeed = '0.8s';
const interval = '0.2';
const animation1 = keyframes`
  from {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  30% {
    transform: scale(1);
  }
  to {
    transform: scale(1);
  }
`;

export const Spinner = styled.div`
  background: transparent;
  outline: none;
  border: none;
  color: #d2dded;
  font-size: 20px;
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    width: 100px;
    height: 100px;
    gap: 10px;
  }
  .shape1 {
    width: 100%;
    height: 100%;
    background-color: #be1622;
    transform-origin: 100% 100%;

    animation-name: ${animation1};
    animation-duration: ${animationSpeed};
    animation-timing-function: ease-in-out;
    animation-delay: ${interval * 0}s;
    animation-iteration-count: infinite;
  }
  .shape2 {
    width: 100%;
    height: 100%;
    background-color: rgba(181, 21, 32, 0.25);
    transform-origin: 0% 100%;

    animation-name: ${animation1};
    animation-duration: ${animationSpeed};
    animation-timing-function: ease-in-out;
    animation-delay: ${interval * 1}s;
    animation-iteration-count: infinite;
  }
  .shape3 {
    width: 100%;
    height: 100%;
    transform-origin: 100% 0%;
    background-color: rgba(0, 0, 0, 0.75);

    animation-name: ${animation1};
    animation-duration: ${animationSpeed};
    animation-timing-function: ease-in-out;
    animation-delay: ${interval * 3}s;
    animation-iteration-count: infinite;
  }
  .shape4 {
    width: 100%;
    height: 100%;
    transform-origin: 0% 0%;
    background-color: rgba(181, 21, 32, 0.75);

    animation-name: ${animation1};
    animation-duration: ${animationSpeed};
    animation-timing-function: ease-in-out;
    animation-delay: ${interval * 2}s;
    animation-iteration-count: infinite;
  }
`;
