import { entityActions } from 'redux/entities/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import entitySelectors from 'redux/entities/selectors';
import { createSelector } from 'reselect';
import validationSchemas from '../containers/forms/schemas';

const useEntityForm = (formType, entity, options = {}) => {
  const dispatch = useDispatch();
  let onSubmit;

  const generateDependecies = data => {
    let result = {};
    options.dependencies?.forEach((dependency, i) => {
      result = { ...result, [dependency]: data[i] };
    });
    return result;
  };

  const selectors =
    options.dependencies &&
    createSelector(
      options.dependencies.map(dependency => entitySelectors[`${dependency}Selector`]),
      (...data) => generateDependecies(data),
    );

  const dependencies = useSelector(selectors || (() => null));

  useEffect(() => {
    if (options?.dependencies) {
      options.dependencies.forEach(dependency => {
        dispatch(entityActions.fetchEntity(`${dependency}`));
      });
    }
  }, []);

  if (formType === 'create') {
    onSubmit = (values, onActionSuccess) =>
      dispatch(
        entityActions.createEntity(
          entity,
          { values, relations: options.dependencies },
          onActionSuccess,
        ),
      );
  }

  if (formType === 'update') {
    onSubmit = values =>
      dispatch(entityActions.updateEntity(entity, { values, relations: options.dependencies }));
  }

  return { onSubmit, dependencies, validationSchema: validationSchemas[entity] };
};
export default useEntityForm;
