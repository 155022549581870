import React from 'react';
import PropTypes from 'prop-types';

import * as UI from './ButtonWithArrowsStyled';

const ButtonWithArrows = ({
  children,
  className = '',
  disabled = false,
  variant,
  back = false,

  ...rest
}) => (
  <UI.ButtonWithArrows
    variant={variant}
    className={`button ${className}`}
    disabled={disabled}
    {...rest}
  >
    <span className={back ? 'backArrow' : 'fwdArrow'}>{children}</span>
  </UI.ButtonWithArrows>
);

ButtonWithArrows.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  back: PropTypes.bool,
};

export default ButtonWithArrows;
