import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Form } from 'components';
import FormHelpBox from 'components/Form/formHelpBox';

import { PAYPAL, QUERY_PARAMS, SHOPIFY, SUPPORT } from 'constants/brandConfiguration';
import { ONBOARDING_PRODUCT_CATALOG } from 'constants/routes';

import useEntityForm from 'hooks/useEntityForm';
import { brandSelector } from 'redux/brand/selectors';
import { updateBrandConfiguration } from 'redux/brand/actions';

const OnboardingCheckoutTypeForm = ({ type = 'update', initialValues }) => {
  const { t } = useTranslation(['pages', 'app']);
  const dispatch = useDispatch();
  const [helpContentKey, setHelpContentKey] = useState(null);
  const { validationSchema } = useEntityForm(type, 'checkoutType');

  const nextStep = ONBOARDING_PRODUCT_CATALOG;
  const flagName = 'checkout';

  const content = [
    { key: QUERY_PARAMS, helpText: t('pages:checkoutType.queryParamsHelp') },
    { key: PAYPAL, helpText: t('pages:checkoutType.paypalHelp') },
    { key: SHOPIFY, helpText: t('pages:checkoutType.shopifyHelp') },
    { key: SUPPORT, helpText: t('pages:checkoutType.supportHelp') },
    { key: 'default', helpText: t('pages:checkoutType.defaultHelp') },
  ];

  const brand = useSelector(brandSelector);

  const resetHelpContentKey = () => {
    setHelpContentKey(null);
  };

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, ['uid', flagName]),
        } || {
          flagName: null,
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={async ({ checkout }) => {
        const { uid } = brand.data.config;
        dispatch(
          updateBrandConfiguration({
            uid,
            configuration: { checkout },
            nextStep,
          }),
        );
      }}
      validationSchema={validationSchema}
    >
      <Form.Group id={flagName} label={t('pages:checkoutType.name')}>
        <Form.RadioButton
          id={`option-${QUERY_PARAMS}`}
          name={flagName}
          value={QUERY_PARAMS}
          label={t('pages:checkoutType.queryParamsRadioLabel')}
          handleMouseOver={() => setHelpContentKey(QUERY_PARAMS)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${PAYPAL}`}
          name={flagName}
          value={PAYPAL}
          label={t('pages:checkoutType.paypalRadioLabel')}
          handleMouseOver={() => setHelpContentKey(PAYPAL)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${SHOPIFY}`}
          name={flagName}
          value={SHOPIFY}
          label={t('pages:checkoutType.shopifyRadioLabel')}
          handleMouseOver={() => setHelpContentKey(SHOPIFY)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${SUPPORT}`}
          name={flagName}
          value={SUPPORT}
          label={t('pages:checkoutType.supportRadioLabel')}
          handleMouseOver={() => setHelpContentKey(SUPPORT)}
          handleMouseOut={() => resetHelpContentKey()}
        />
      </Form.Group>
      <FormHelpBox helpContentKey={helpContentKey} content={content} valueName={flagName} />
      <Form.Submit className="mt-5" size="lg" variant="primary" withArrows block>
        {t('onboarding.nextStep')}
      </Form.Submit>
    </Form>
  );
};

OnboardingCheckoutTypeForm.propTypes = {
  type: PropTypes.oneOf(['update']),
  initialValues: PropTypes.object,
};

export default OnboardingCheckoutTypeForm;
