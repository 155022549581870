/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { addProductsToLook } from 'redux/looks/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddedProduct from 'pages/Looks/components/AddedProduct/AddedProduct';
import { lookProductsSelector, singleLookSelector } from 'redux/looks/selectors';
import { productPrice } from 'utils/products';

import AddProductCard from '../../components/AddProductCard/AddProductCard';
import ProductSearch from '../../components/ProductSearch/ProductSearch';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';

import * as Ui from './AddProductsPage.styles';

const AddProductsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ownerPortal');
  const { data: look } = useSelector(singleLookSelector);

  const lookProducts = useSelector(lookProductsSelector);
  const [productsToAdd, setProductsToAdd] = useState([]);

  const [searchedProducts, setSearchedProducts] = useState([]);
  const { visibleItems, loadNextItems } = useInfiniteScroll(searchedProducts);

  const addProducts = async onSuccess => {
    const payload = productsToAdd.map(p => ({
      store_id: look._id,
      parent_product: p.parent_product,
      sub_skus: p.selected_variants,
      primary_sku: p.primary_sku,
    }));
    dispatch(addProductsToLook(payload));
    setProductsToAdd([]);
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleAddProduct = productToBeAdded => {
    setProductsToAdd([...productsToAdd, productToBeAdded]);
  };

  return (
    <Ui.Modal
      header={{ title: t('Looks.addProductsTitle') }}
      closeOnDocumentClick={false}
      trigger={<Ui.AddProductsButton>{t('Looks.addProducts')}</Ui.AddProductsButton>}
      footer={closePopup => (
        <Ui.Footer>
          {!!productsToAdd.length && (
            <Ui.SelectedProducts>
              <p className="text-muted m-0 text-uppercase title">{t('Looks.selectedProducts')}</p>
              <Ui.SelectedProductsRow>
                {productsToAdd.map(product => (
                  <AddedProduct
                    name={product.name}
                    variants={product.selected_variants.length}
                    image={product.image}
                    price={productPrice(product.sub_skus)}
                    productId={product.parent_product}
                    onClick={productId =>
                      setProductsToAdd(productsToAdd.filter(p => p.parent_product !== productId))
                    }
                  />
                ))}
              </Ui.SelectedProductsRow>
            </Ui.SelectedProducts>
          )}

          <Ui.ButtonsWrapper>
            <Button variant="outline-dark" onClick={closePopup}>
              {t('app:back')}
            </Button>
            <Button
              variant="primary"
              disabled={!productsToAdd.length || (!look?.is_editable && !look?.is_template)}
              onClick={() => addProducts(closePopup)}
            >{`Add ${productsToAdd.length ? `(${productsToAdd.length})` : ''} to look`}</Button>
          </Ui.ButtonsWrapper>
        </Ui.Footer>
      )}
    >
      <ProductSearch setSearchedProducts={setSearchedProducts} />
      {!!visibleItems.length && (
        <Ui.ProductsWrapper
          scrollableTarget="modal-content"
          dataLength={visibleItems.length}
          next={loadNextItems}
          hasMore
        >
          {visibleItems.map(p => (
            <AddProductCard
              key={p.uid}
              lookId={look._id}
              parentProduct={p}
              lookProduct={lookProducts.find(product => product.parent === p.uid)}
              onProductAdd={productToBeAdded => handleAddProduct(productToBeAdded)}
              addedProducts={productsToAdd}
            />
          ))}
        </Ui.ProductsWrapper>
      )}
    </Ui.Modal>
  );
};

export default AddProductsModal;
