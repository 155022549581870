import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { CardContainer, ImageWrapper } from 'components';
import { OnboardingRegionForm } from 'containers/forms';

import countries from 'constants/countries';
import languages from 'constants/languages';
import { currencies } from 'constants/currenciesList';

import { ReactComponent as Icon } from '../../assets/img/illustrations/region.svg';

const DEFAULT_CURRENCY = 'USD';
const DEFAULT_COUNTRY = 'US';
const DEFAULT_LANGUAGE = 'en';

const defaultCurrency = currencies.find(currency => currency.code === DEFAULT_CURRENCY);
const defaultCountry = countries.find(country => country.code === DEFAULT_COUNTRY);
const defaultLanguage = languages.find(language => language.value === DEFAULT_LANGUAGE);

const OnboardingRegionPage = () => {
  const { t } = useTranslation(['pages']);

  return (
    <CardContainer lg="8">
      <Helmet>
        <title>Region declaration</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.regionTitle')}</h1>
      <h2 className="display-5 text-center">{t('onboarding.regionSubtitle')}</h2>
      <ImageWrapper>
        <Icon />
      </ImageWrapper>
      <OnboardingRegionForm
        type="create"
        initialValues={{
          country_code: defaultCountry.code,
          base_currency: defaultCurrency.code,
          base_language: defaultLanguage.value,
        }}
      />
    </CardContainer>
  );
};

export default OnboardingRegionPage;
