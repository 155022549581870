// TODO: remove prefix after resolving entity saga

// parent products
export const RAMBLA_FETCH_PARENT_PRODUCTS = 'RAMBLA_FETCH_PARENT_PRODUCTS';
export const RAMBLA_FETCH_PARENT_PRODUCTS_SUCCESS = 'RAMBLA_FETCH_PARENT_PRODUCTS_SUCCESS';
export const RAMBLA_CREATE_PARENT_PRODUCT = 'RAMBLA_CREATE_PARENT_PRODUCT';
export const RAMBLA_CREATE_PARENT_PRODUCT_SUCCESS = 'RAMBLA_CREATE_PARENT_PRODUCT_SUCCESS';
export const RAMBLA_UPDATE_PARENT_PRODUCT = 'RAMBLA_UPDATE_PARENT_PRODUCT';
export const RAMBLA_UPDATE_PARENT_PRODUCT_SUCCESS = 'RAMBLA_UPDATE_PARENT_PRODUCT_SUCCESS';
export const RAMBLA_DELETE_PARENT_PRODUCT = 'RAMBLA_DELETE_PARENT_PRODUCT';
export const RAMBLA_DELETE_PARENT_PRODUCT_SUCCESS = 'RAMBLA_DELETE_PARENT_PRODUCT_SUCCESS';

// child products
export const RAMBLA_FETCH_CHILD_PRODUCTS = 'RAMBLA_FETCH_CHILD_PRODUCTS';
export const RAMBLA_FETCH_CHILD_PRODUCTS_SUCCESS = 'RAMBLA_FETCH_CHILD_PRODUCTS_SUCCESS';
export const RAMBLA_CREATE_CHILD_PRODUCT = 'RAMBLA_CREATE_CHILD_PRODUCT';
export const RAMBLA_CREATE_CHILD_PRODUCT_SUCCESS = 'RAMBLA_CREATE_CHILD_PRODUCT_SUCCESS';
export const RAMBLA_UPDATE_CHILD_PRODUCT = 'RAMBLA_UPDATE_CHILD_PRODUCT';
export const RAMBLA_UPDATE_CHILD_PRODUCT_SUCCESS = 'RAMBLA_UPDATE_CHILD_PRODUCT_SUCCESS';
export const RAMBLA_DELETE_CHILD_PRODUCT = 'RAMBLA_DELETE_CHILD_PRODUCT';
export const RAMBLA_DELETE_CHILD_PRODUCT_SUCCESS = 'RAMBLA_DELETE_CHILD_PRODUCT_SUCCESS';

// colors
export const RAMBLA_FETCH_COLORS = 'RAMBLA_FETCH_COLORS';
export const RAMBLA_FETCH_COLORS_SUCCESS = 'RAMBLA_FETCH_COLORS_SUCCESS';
export const RAMBLA_CREATE_COLOR = 'RAMBLA_CREATE_COLOR';
export const RAMBLA_CREATE_COLOR_SUCCESS = 'RAMBLA_CREATE_COLOR_SUCCESS';
export const RAMBLA_UPDATE_COLOR = 'RAMBLA_UPDATE_COLOR';
export const RAMBLA_UPDATE_COLOR_SUCCESS = 'RAMBLA_UPDATE_COLOR_SUCCESS';
export const RAMBLA_DELETE_COLOR = 'RAMBLA_DELETE_COLOR';
export const RAMBLA_DELETE_COLOR_SUCCESS = 'RAMBLA_DELETE_COLOR_SUCCESS';
export const RAMBLA_GENERATE_COLORS = 'RAMBLA_GENERATE_COLORS';
export const RAMBLA_GENERATE_COLORS_SUCCESS = 'RAMBLA_GENERATE_COLORS_SUCCESS';

// sizes
export const RAMBLA_FETCH_SIZES = 'RAMBLA_FETCH_SIZES';
export const RAMBLA_FETCH_SIZES_SUCCESS = 'RAMBLA_FETCH_SIZES_SUCCESS';
export const RAMBLA_CREATE_SIZE = 'RAMBLA_CREATE_SIZE';
export const RAMBLA_CREATE_SIZE_SUCCESS = 'RAMBLA_CREATE_SIZE_SUCCESS';
export const RAMBLA_UPDATE_SIZE = 'RAMBLA_UPDATE_SIZE';
export const RAMBLA_UPDATE_SIZE_SUCCESS = 'RAMBLA_UPDATE_SIZE_SUCCESS';
export const RAMBLA_DELETE_SIZE = 'RAMBLA_DELETE_SIZE';
export const RAMBLA_DELETE_SIZE_SUCCESS = 'RAMBLA_DELETE_SIZE_SUCCESS';
export const RAMBLA_GENERATE_SIZES = 'RAMBLA_GENERATE_SIZES';
export const RAMBLA_GENERATE_SIZES_SUCCESS = 'RAMBLA_GENERATE_SIZES_SUCCESS';

// categories
export const RAMBLA_FETCH_CATEGORIES = 'RAMBLA_FETCH_CATEGORIES';
export const RAMBLA_FETCH_CATEGORIES_SUCCESS = 'RAMBLA_FETCH_CATEGORIES_SUCCESS';
export const RAMBLA_CREATE_CATEGORY = 'RAMBLA_CREATE_CATEGORY';
export const RAMBLA_CREATE_CATEGORY_SUCCESS = 'RAMBLA_CREATE_CATEGORY_SUCCESS';
export const RAMBLA_UPDATE_CATEGORY = 'RAMBLA_UPDATE_CATEGORY';
export const RAMBLA_UPDATE_CATEGORY_SUCCESS = 'RAMBLA_UPDATE_CATEGORY_SUCCESS';
export const RAMBLA_DELETE_CATEGORY = 'RAMBLA_DELETE_CATEGORY';
export const RAMBLA_DELETE_CATEGORY_SUCCESS = 'RAMBLA_DELETE_CATEGORY_SUCCESS';
export const RAMBLA_GENERATE_CATEGORIES = 'RAMBLA_GENERATE_CATEGORIES';
export const RAMBLA_GENERATE_CATEGORIES_SUCCESS = 'RAMBLA_GENERATE_CATEGORIES_SUCCESS';

// inventories
export const RAMBLA_FETCH_INVENTORIES = 'RAMBLA_FETCH_INVENTORIES ';
export const RAMBLA_FETCH_INVENTORIES_SUCCESS = 'RAMBLA_FETCH_INVENTORIES_SUCCESS';
export const RAMBLA_CREATE_INVENTORY = 'RAMBLA_CREATE_INVENTORY';
export const RAMBLA_CREATE_INVENTORY_SUCCESS = 'RAMBLA_CREATE_INVENTORY_SUCCESS';
export const RAMBLA_UPDATE_INVENTORY = 'RAMBLA_UPDATE_INVENTORY';
export const RAMBLA_UPDATE_INVENTORY_SUCCESS = 'RAMBLA_UPDATE_INVENTORY_SUCCESS';
export const RAMBLA_DELETE_INVENTORY = 'RAMBLA_DELETE_INVENTORY';
export const RAMBLA_DELETE_INVENTORY_SUCCESS = 'RAMBLA_DELETE_INVENTORY_SUCCESS';
export const RAMBLA_GENERATE_INVENTORIES = 'RAMBLA_GENERATE_INVENTORIES';
export const RAMBLA_GENERATE_INVENTORIES_SUCCESS = 'RAMBLA_GENERATE_INVENTORIES_SUCCESS';
