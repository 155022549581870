import Cookies from 'universal-cookie/es6';

const cookies = new Cookies();

export function getToken() {
  return cookies.get('TOKEN');
}

// TODO: remove rambla prefix after auth service cleanup
export function getRamblaToken() {
  return cookies.get('RAMBLA_TOKEN');
}

export function setToken(token) {
  return cookies.set('TOKEN', token, { path: '/' });
}

export function setRamblaToken(token) {
  return cookies.set('RAMBLA_TOKEN', token, { path: '/' });
}

export function getRefreshToken() {
  return cookies.get('REFRESH_TOKEN');
}

export function setRefreshToken(token) {
  return cookies.set('REFRESH_TOKEN', token, { path: '/' });
}

export function removeToken() {
  return cookies.remove('TOKEN');
}

export function removeRamblaToken() {
  return cookies.remove('RAMBLA_TOKEN');
}

export function removeRefreshToken() {
  return cookies.remove('REFRESH_TOKEN');
}
