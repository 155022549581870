import React from 'react';
import PropTypes from 'prop-types';
import withDefaultProps from 'helpers/withDefaultProps';
import * as Ui from './Button.styles';

const Button = ({ className = '', children, $type, ...restProps }) => (
  <Ui.BootstrapButton $type={$type} className={`${className}`} {...restProps}>
    {children}
  </Ui.BootstrapButton>
);

Button.propTypes = {
  $type: PropTypes.oneOf(['avedaPrimary', 'avedaPrimaryOutline', 'avedaSecondary', 'avedaDanger']),
  ...withDefaultProps(),
};

export default Button;
