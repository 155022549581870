import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';

import useEntityForm from 'hooks/useEntityForm';
import { pick } from 'lodash';
import translationLanguages from 'constants/translationLanguages';

const BrandForm = ({ type = 'create', initialValues, FormGroup, onActionSuccess }) => {
  const { t } = useTranslation(['pages', 'app']);
  const { onSubmit, validationSchema, dependencies } = useEntityForm(type, 'brand', {
    dependencies: ['theme'],
  });

  return (
    <Form
      initialValues={
        pick(initialValues, ['name', 'description', 'uid', 'default_theme', 'language']) || {
          name: '',
          description: '',
          default_theme: null,
        }
      }
      onSubmit={values => {
        onSubmit(values, onActionSuccess);
      }}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={t('app:name')}>
          <Form.Input type="text" name="name" />
        </FormGroup>
        <FormGroup label={t('app:description')}>
          <Form.Input as="textarea" name="description" />
        </FormGroup>

        <FormGroup label={t('pages:theme.defaultTheme')}>
          <Form.MultiSelect
            options={dependencies.theme
              .filter(i => i.id === initialValues?.id || i.brand === null)
              .map(item => ({
                value: item.id,
                label: item.name,
              }))}
            name="default_theme"
            isMulti={false}
          />
        </FormGroup>
        <FormGroup label={t('app:defaultLanguage')}>
          <Form.Select
            options={translationLanguages.map(el => ({ label: el.name, value: el.code }))}
            name="language"
          />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

BrandForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  onActionSuccess: PropTypes.func,
};

export default BrandForm;
