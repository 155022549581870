import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Form } from 'components';
import FormHelpBox from 'components/Form/formHelpBox';

import { BRAND_TRACKING_GTM_ID, BRAND_TRACKING_SUPPORT } from 'constants/brandConfiguration';
import { ONBOARDING_FINAL_SPLASH } from 'constants/routes';

import useEntityForm from 'hooks/useEntityForm';
import { brandSelector } from 'redux/brand/selectors';
import { updateBrand, updateBrandConfiguration } from 'redux/brand/actions';

const OnboardingBrandTrackingForm = ({ type = 'update', initialValues }) => {
  const { t } = useTranslation(['pages', 'app']);
  const dispatch = useDispatch();
  const [helpContentKey, setHelpContentKey] = useState(null);
  const { validationSchema } = useEntityForm(type, 'brandTracking');
  const [currentValues, setCurrentValues] = useState(null);

  const nextStep = ONBOARDING_FINAL_SPLASH;

  const flagName = 'brand_sites_track';

  const content = [
    { key: BRAND_TRACKING_GTM_ID, helpText: t('pages:brandTracking.gtmIdHelp') },
    { key: BRAND_TRACKING_SUPPORT, helpText: t('pages:brandTracking.supportHelp') },
    { key: 'default', helpText: t('pages:brandTracking.defaultHelp') },
  ];

  const brand = useSelector(brandSelector);

  const resetHelpContentKey = () => {
    setHelpContentKey(null);
  };

  const FormValuesSetter = () => {
    const { values } = useFormikContext();
    if (currentValues !== values) setCurrentValues(values);
    return null;
  };

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, ['uid', flagName]),
        } || {
          flagName: null,
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={async ({ brand_sites_track }) => {
        const { uid } = brand.data.config;
        dispatch(
          updateBrandConfiguration({
            uid,
            configuration: { brand_sites_track },
            nextStep,
          }),
        );
        dispatch(updateBrand(brand?.data?.uid, brand?.data?.name, brand?.data?.domain, true, true));
      }}
      validationSchema={validationSchema}
    >
      <Form.Group id={flagName} label={t('pages:brandTracking.name')}>
        <Form.RadioButton
          id={`option-${BRAND_TRACKING_GTM_ID}`}
          name={flagName}
          value={BRAND_TRACKING_GTM_ID}
          label={t('pages:brandTracking.gtmIdRadioLabel')}
          handleMouseOver={() => setHelpContentKey(BRAND_TRACKING_GTM_ID)}
          handleMouseOut={() => resetHelpContentKey()}
          checked={BRAND_TRACKING_GTM_ID === currentValues?.brand_sites_track}
        />
        <Form.RadioButton
          id={`option-${BRAND_TRACKING_SUPPORT}`}
          name={flagName}
          value={BRAND_TRACKING_SUPPORT}
          label={t('pages:brandTracking.supportRadioLabel')}
          handleMouseOver={() => setHelpContentKey(BRAND_TRACKING_SUPPORT)}
          handleMouseOut={() => resetHelpContentKey()}
          checked={BRAND_TRACKING_SUPPORT === currentValues?.brand_sites_track}
        />
      </Form.Group>
      <FormHelpBox content={content} helpContentKey={helpContentKey} valueName={flagName} />
      <FormValuesSetter />
      <Form.Submit className="mt-5" size="lg" variant="primary" withArrows block>
        {t('onboarding.nextStep')}
      </Form.Submit>
    </Form>
  );
};

OnboardingBrandTrackingForm.propTypes = {
  type: PropTypes.oneOf(['update']),
  initialValues: PropTypes.object,
};

export default OnboardingBrandTrackingForm;
