import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Modal as Mod } from 'components';
import InfiniteScroll from 'react-infinite-scroll-component';

export const CategoryTitle = styled.h2`
  padding-top: 24px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px 16px 24px;
  background: white;
  border-top: 1px solid #e9ecef;
  button {
    border-radius: 0;
  }
`;

export const SelectedProducts = styled.div`
  padding-top: 6px;

  .title {
    font-size: 13px;
  }
`;

export const SelectedProductsRow = styled.div`
  overflow-x: scroll;
  padding: 12px 0;
  border-bottom: 1px solid #e9ecef;
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-top: 12px;

  button {
    border-radius: 0;
  }
`;

export const AddProductsButton = styled(Button).attrs({
  variant: 'outline-dark',
  size: 'lg',
})`
  border-radius: 0;
  max-width: 220px;
  min-height: 250px;
`;

export const ProductsWrapper = styled(InfiniteScroll)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 400px;
  gap: 8px;
  min-height: 450px;
`;

export const Modal = styled(Mod).attrs({
  wide: true,
})``;
