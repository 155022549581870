import styled from 'styled-components';
import { Card, Button } from 'react-bootstrap';

export const ProductCard = styled(Card)`
  max-width: 250px;
  margin-bottom: 0;
  padding-bottom: 12px;
  height: 100%;

  border-radius: 0;
  font-size: 15px;

  img {
    max-height: 150px;
    object-fit: contain;
  }
  .name {
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  .description {
    line-height: 1.2;
    height: 2rem;
    font-size: 0.8rem;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ProductDetailsWrapper = styled.div.attrs({
  className: 'd-flex flex-column justify-content-between p-3',
})`
  flex: 1;
`;

export const DeleteButton = styled(Button).attrs({
  variant: 'link',
  size: 'sm',
})`
  color: var(--bs-font-sans-serif);
  text-decoration: underline;
`;

export const EditButton = styled(Button).attrs({
  variant: 'outline-dark',
  size: 'sm',
})`
  border-radius: 0;
  padding: 0.125rem 1.5rem;
`;
