/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { productPrice, getProductImage, getVariants, VariantType } from 'utils/products';
import { Modal } from 'components';
import capitalize from 'utils/capitalize';
import { removeProductFromLook } from 'redux/looks/actions';

import * as Ui from './ProductCard.styles';
import VariantForm from '../VariantForm/VariantForm';
import StatusBadge from '../StatusBadge/StatusBadge';
import { Color } from '../ColorPicker/ColorPicker.styles';
import { Size } from '../SizePicker/SizePicker.styles';

const ProductCard = ({
  lookId,
  lookProduct,
  parentProduct,
  small = false,
  onProductAdd,
  isEditable = true,
}) => {
  const { t } = useTranslation('ownerPortal');
  const dispatch = useDispatch();
  const [optionModalOpen, setOptionModalOpen] = useState(false);
  const { variants: currentVariants } = lookProduct ? getVariants(lookProduct.sub_skus) : null;
  const currentPrimarySkuId = lookProduct?.primary_sku?.id;
  const { variants, variantType } = getVariants(parentProduct?.sub_skus);

  const getVariantName = () =>
    variants.variantType === VariantType.COLOR ? t('Looks.colors') : t('Looks.sizes');

  const renderVariantSelectionModal = () => (
    <Modal
      header={{
        title: `${t('Looks.selectProduct')} ${getVariantName().toLowerCase()}`,
      }}
      open={optionModalOpen}
      onClose={() => setOptionModalOpen(false)}
      wide
    >
      <VariantForm
        lookId={lookId}
        productId={lookProduct?._id}
        variantType={variantType}
        availableVariants={variants}
        currentlyUsedSkus={currentVariants}
        primarySizeId={currentPrimarySkuId}
        onProductAdd={onProductAdd}
        parentProduct={parentProduct}
      />
    </Modal>
  );

  const onChangeVariants = () => {
    setOptionModalOpen(true); // to do zmienic nazwe
  };

  const renderProductVariants = () => {
    if (lookProduct.sub_skus.length > 1) {
      return (
        <p className="text-small text-muted my-2" style={{ lineHeight: '20px' }}>
          Added variants: <b>{lookProduct.sub_skus.length}</b>
        </p>
      );
    }

    if (variantType === VariantType.COLOR) {
      return (
        <div className="d-flex align-items-center my-2">
          <Color style={{ width: 20, height: 20 }} color={currentVariants[0]?.hexValue} />
          <p className="text-small text-muted pl-2 m-0"> {currentVariants[0]?.variantName}</p>
        </div>
      );
    }
    return (
      <Size selected className="d-inline-block my-2" style={{ border: 'none' }}>
        {currentVariants[0].variantName}
      </Size>
    );
  };

  if (!parentProduct) {
    return null;
  }

  return (
    <Ui.ProductCard small={small}>
      <StatusBadge product={parentProduct} />
      {renderVariantSelectionModal()}
      <img alt="product" src={getProductImage(parentProduct?.sub_skus)} />
      <Ui.ProductDetailsWrapper>
        <div>
          <p
            className="name"
            dangerouslySetInnerHTML={{
              __html: capitalize(parentProduct.name),
            }}
            style={{ minHeight: '30px' }}
          />
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: parentProduct.description }}
          />
        </div>
        <div>
          <p className="m-0">{productPrice(lookProduct.sub_skus)}</p>

          {renderProductVariants()}
          {isEditable && (
            <div className="d-flex justify-content-end">
              <Ui.DeleteButton
                disabled={!isEditable}
                onClick={() => dispatch(removeProductFromLook(lookProduct._id))}
              >
                Remove
              </Ui.DeleteButton>

              {variants.length > 1 && (
                <Ui.EditButton disabled={!isEditable} onClick={onChangeVariants}>
                  Edit
                </Ui.EditButton>
              )}
            </div>
          )}
        </div>
      </Ui.ProductDetailsWrapper>
    </Ui.ProductCard>
  );
};

ProductCard.propTypes = {
  lookId: PropTypes.string.isRequired,
  lookProduct: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
    primary_sku: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    sub_skus: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
  }),
  parentProduct: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    sub_skus: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        is_primary: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }),
  small: PropTypes.bool,
  isEditable: PropTypes.bool,
  onProductAdd: PropTypes.func,
};

export default ProductCard;
