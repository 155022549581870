import React from 'react';
import withDefaultProps from 'helpers/withDefaultProps';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Card, Container, Row, Col } from 'react-bootstrap';
import { ButtonWithArrows } from 'components';

const CardContainer = ({ children, showBackButton, backButtonTarget, ...restProps }) => {
  const history = useHistory();
  const { t } = useTranslation(['pages']);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col {...restProps}>
          <Card className="py-5 px-6 m-2 d-flex justify-content-center">
            {children}
            {(showBackButton || backButtonTarget) && (
              <ButtonWithArrows
                className="mt-3"
                block
                size="lg"
                variant="outline-primary"
                type="submit"
                onClick={() =>
                  backButtonTarget ? history.push(backButtonTarget) : history.goBack()
                }
                back
              >
                {t('onboarding.goBack')}
              </ButtonWithArrows>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

CardContainer.propTypes = { ...withDefaultProps() };

export default CardContainer;
