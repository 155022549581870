import entities, { API_V4_ENTITIES } from 'constants/entities';
import produce from 'immer';
import { pick } from 'lodash';

export const initialState = {
  brand: { fetched: false, data: [] },
  bundle: { fetched: false, data: [] },
  category: { fetched: false, data: [] },
  color: { fetched: false, data: [] },
  color_category: { fetched: false, data: [] },
  product: { fetched: false, data: [] },
  product_group: { fetched: false, data: [] },
  region: { fetched: false, data: [] },
  retailer: { fetched: false, data: { count: 0, next: null, previous: null, results: [] } },
  size: { fetched: false, data: [] },
  sku: { fetched: false, data: [] },
  tier: { fetched: false, data: [] },
  theme: { fetched: false, data: [] },
  admin: { fetched: false, data: [] },
  brand_content: { fetched: false, data: [] },
  location: { fetched: false, data: { count: 0, next: null, previous: null, results: [] } },
  available_sellers: { fetched: false, data: [] },
  available_stores: { fetched: false, data: [] },
  locationBundle: { fetched: false, data: [] },
  welcome_screen: { fetched: false, data: [] },
  store_templates: { fetched: false, data: [] },
  learning_center: { fetched: false, data: [] },
  qr_code: { data: {} },
  custom_artists: { fetched: false, data: [] },
};

const entityReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case 'CLEAR': {
        // eslint-disable-next-line
        Object.keys(draft).forEach(key => (draft[key].fetched = false));
        break;
      }

      case 'QR_CODE_FETCHED': {
        const { shortId, qr } = payload;
        draft.qr_code.data[shortId] = qr;
        break;
      }

      case 'FETCH_FLAG_DOWN': {
        draft[payload.toLowerCase()].fetched = false;
        break;
      }

      case 'ASSIGN_TO_ENTITY_SUCCESS': {
        const { entityType, entity } = payload;

        const index = draft[entityType].data.findIndex(item => item.id === entity.id);
        draft[entityType].data[index] = entity;
        break;
      }

      case 'DELETE_VARIANT_SUCCESS': {
        const { uid, productId } = payload;

        const product = draft.product.data.find(item => item.id === parseInt(productId, 10));
        const index = product.sub_skus.findIndex(item => item.uid === uid);

        if (index !== -1) product.sub_skus.splice(index, 1);

        break;
      }

      case 'UPDATE_VARIANT_SUCCESS':
      case 'UPDATE_SKU_SUCCESS': {
        const sku = payload;

        const draftProduct = draft.product.data.find(item => item.id === sku.base_product);
        draftProduct.sub_skus = draftProduct.sub_skus.map(item =>
          item.uid === sku.uid
            ? { ...item, ...sku, color: item.color }
            : { ...item, is_primary: false },
        );

        break;
      }
      default: {
        const actionType = type.slice(0, type.indexOf('_'));
        const entity = type.slice(type.indexOf('_') + 1, type.lastIndexOf('_'));
        const status = type.slice(type.lastIndexOf('_') + 1);

        if (!entities[entity.toLowerCase()]) {
          return;
        }

        switch (`${actionType}_${status}`) {
          case 'FETCH_SUCCESS':
            if (
              [entities.learning_center, entities.welcome_screen].includes(entity.toLowerCase())
            ) {
              draft[entity.toLowerCase()].data = payload[`${entity.toLowerCase()}s`];
              draft[entity.toLowerCase()].fetched = true;
              return;
            }

            draft[entity.toLowerCase()].data = payload;
            draft[entity.toLowerCase()].fetched = true;
            break;

          case 'CREATE_SUCCESS':
            if (API_V4_ENTITIES.includes(entity.toLowerCase())) {
              return;
            }
            draft[entity.toLowerCase()].data = draft[entity.toLowerCase()].data.concat(payload);
            if (entity.toLowerCase() === entities.sku) {
              const index = draft.product.data.findIndex(item => item.id === payload.base_product);
              draft.product.data[index].sub_skus.push(
                pick(payload, [
                  'uid',
                  'id',
                  'sku',
                  'description',
                  'color',
                  'inventory',
                  'retail_price',
                ]),
              );
            }
            break;

          case 'UPDATE_SUCCESS': {
            let index;
            if (
              entity.toLowerCase() === entities.retailer ||
              entity.toLowerCase() === entities.location
            ) {
              index = draft[entity.toLowerCase()].data.results.findIndex(
                item => item.uid === payload.uid,
              );
              if (index !== -1) draft[entity.toLowerCase()].data.results[index] = payload;
            } else {
              index = draft[entity.toLowerCase()].data.findIndex(item => item.uid === payload.uid);
              if (index !== -1) draft[entity.toLowerCase()].data[index] = payload;
            }

            break;
          }

          case 'DELETE_SUCCESS': {
            let index;

            if ([entities.retailer, entities.location].includes(entity.toLowerCase())) {
              index = draft[entity.toLowerCase()].data.results.findIndex(
                item => item.uid === payload.uid,
              );
              if (index !== -1) draft[entity.toLowerCase()].data.results.splice(index, 1);
            } else {
              index = draft[entity.toLowerCase()].data.findIndex(item => item.uid === payload.uid);
              if (index !== -1) draft[entity.toLowerCase()].data.splice(index, 1);
              if (entity.toLowerCase() === entities.sku) {
                const productIndex = draft.product.data.findIndex(
                  item => item.id === payload.base_product,
                );
                draft.product.data[productIndex].sub_skus.push(
                  pick(payload, [
                    'uid',
                    'id',
                    'sku',
                    'description',
                    'color',
                    'inventory',
                    'retail_price',
                  ]),
                );
              }
            }
            break;
          }

          default:
            break;
        }
      }
    }
  });

export default entityReducer;
