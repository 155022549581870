import React from 'react';
import withDefaultProps from 'helpers/withDefaultProps';

const ThemeVisualizer = props => {
  const { mainColor } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="120 0 600 447.23">
      <g style={{ isolation: 'isolate' }}>
        <g id="Background_Complete" data-name="Background Complete">
          <g>
            <rect
              x="612.49"
              y="64.57"
              width="44.44"
              height="11.76"
              style={{ fill: '#ebebeb', strokeWidth: '0px' }}
            />
            <path
              d="m638.14,88.71s0-.08-.01-.21c0-.16,0-.36-.01-.6,0-.55-.02-1.32-.03-2.31-.02-2.03-.04-4.95-.08-8.63l.14.14c-10.15.02-26.29.05-44.44.09.1-.1-.23.23.22-.23h0v.75s0,.75,0,.75c0,.5,0,1,0,1.5,0,1,0,1.99,0,2.97,0,1.96,0,3.89,0,5.78l-.22-.22c12.66.04,23.73.08,31.67.1,3.96.03,7.13.05,9.35.06,1.08.01,1.92.02,2.53.03.27,0,.49.01.66.01.15,0,.23,0,.23.01,0,0-.06,0-.21.01-.16,0-.37,0-.63.01-.59,0-1.42.02-2.48.03-2.21.01-5.36.04-9.3.06-7.98.03-19.11.06-31.83.1h-.22s0-.22,0-.22c0-1.89,0-3.82,0-5.78,0-.98,0-1.97,0-2.97,0-.5,0-1,0-1.5v-.75s0-.38,0-.38v-.35h0v-.02h0c.45-.46.13-.13.23-.23,18.15.04,34.29.07,44.44.09h.14s0,.14,0,.14c-.03,3.71-.06,6.66-.08,8.71-.01.98-.03,1.74-.03,2.28,0,.24-.01.43-.01.58,0,.13,0,.19-.01.19Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
          </g>
          <g>
            <rect
              x="499"
              y="299.52"
              width="44.44"
              height="11.76"
              style={{ fill: '#ebebeb', strokeWidth: '0px' }}
            />
            <path
              d="m524.65,323.66s0-.08-.01-.21c0-.16,0-.36-.01-.6,0-.55-.02-1.32-.03-2.31-.02-2.03-.04-4.95-.08-8.63l.14.14c-10.15.02-26.29.05-44.44.09.1-.1-.23.23.22-.23h0v.75s0,.75,0,.75c0,.5,0,1,0,1.5,0,1,0,1.99,0,2.97,0,1.96,0,3.89,0,5.78l-.22-.22c12.66.04,23.73.08,31.67.1,3.96.03,7.13.05,9.35.06,1.08.01,1.92.02,2.53.03.27,0,.49.01.66.01.15,0,.23,0,.23.01,0,0-.06,0-.21.01-.16,0-.37,0-.63.01-.59,0-1.42.02-2.48.03-2.21.01-5.36.04-9.3.06-7.98.03-19.11.06-31.83.1h-.22s0-.22,0-.22c0-1.89,0-3.82,0-5.78,0-.98,0-1.97,0-2.97,0-.5,0-1,0-1.5v-.75s0-.38,0-.38v-.35h0v-.02h0c.45-.46.13-.13.23-.23,18.15.04,34.29.07,44.44.09h.14s0,.14,0,.14c-.03,3.71-.06,6.66-.08,8.71-.01.98-.03,1.74-.03,2.28,0,.24-.01.43-.01.58,0,.13,0,.19-.01.19Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
          </g>
          <rect
            x="322.67"
            y="39.5"
            width="44.44"
            height="11.76"
            style={{ fill: '#ebebeb', strokeWidth: '0px' }}
          />
          <g>
            <path
              d="m355.2,258.63s0-.08-.01-.21c0-.16,0-.36-.01-.6,0-.55-.02-1.32-.03-2.31-.02-2.03-.04-4.95-.08-8.63l.14.14c-10.15.02-26.29.05-44.44.09.1-.1-.23.23.22-.23h0v.38s0,.38,0,.38v.75c0,.5,0,1,0,1.5,0,1,0,1.99,0,2.97,0,1.96,0,3.89,0,5.78l-.22-.22c12.66.04,23.73.08,31.67.1,3.96.03,7.13.05,9.35.06,1.08.01,1.92.02,2.53.03.27,0,.49.01.66.01.15,0,.23,0,.23.01,0,0-.06,0-.21.01-.16,0-.37,0-.63.01-.59,0-1.42.02-2.48.03-2.21.01-5.36.04-9.3.06-7.98.03-19.11.06-31.83.1h-.22s0-.22,0-.22c0-1.89,0-3.82,0-5.78,0-.98,0-1.97,0-2.97,0-.5,0-1,0-1.5v-.75s0-.38,0-.38v-.35h0v-.02h0c.45-.46.13-.13.23-.23,18.15.04,34.29.07,44.44.09h.14s0,.14,0,.14c-.03,3.71-.06,6.66-.08,8.71-.01.98-.03,1.74-.03,2.28,0,.24-.01.43-.01.58,0,.13,0,.19-.01.19Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
            <path
              d="m361.23,246.87s-.01-.08-.02-.22c0-.17-.01-.37-.02-.62,0-.58-.02-1.37-.04-2.36-.02-2.06-.05-4.97-.09-8.55l.16.16c-6.92.02-16.33.04-26.7.06-.2.2.27-.28.22-.23h0v.38s0,.39,0,.39v.77c0,.51,0,1.02,0,1.52,0,1.01,0,2.01,0,2.99,0,1.97,0,3.87,0,5.7l-.22-.22c7.71.04,14.35.08,19.1.1,2.35.03,4.23.05,5.56.06.63.01,1.12.02,1.5.03.34,0,.53.02.54.03,0,0-.16.02-.48.03-.37,0-.85.02-1.46.03-1.33.01-3.19.03-5.52.06-4.79.03-11.48.06-19.25.1h-.22s0-.22,0-.22c0-1.83,0-3.73,0-5.7,0-.98,0-1.98,0-2.99,0-.51,0-1.01,0-1.52v-.77s0-.39,0-.39v-.36h0v-.02h0s.43-.43.23-.23c10.37.02,19.78.05,26.7.06h.16s0,.16,0,.16c-.04,3.62-.07,6.57-.09,8.65-.02.98-.03,1.75-.04,2.32-.01.53-.02.8-.03.78Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
          </g>
          <g>
            <path
              d="m562.72,34.03s0-.08-.01-.21c0-.16,0-.36-.01-.6,0-.55-.02-1.32-.03-2.31-.02-2.03-.04-4.95-.08-8.63l.14.14c-10.15.02-26.29.05-44.44.09.1-.1-.23.23.22-.23h0v.38s0,.38,0,.38v.75c0,.5,0,1,0,1.5,0,1,0,1.99,0,2.97,0,1.96,0,3.89,0,5.78l-.22-.22c12.66.04,23.73.08,31.67.1,3.96.03,7.13.05,9.35.06,1.08.01,1.92.02,2.53.03.27,0,.49.01.66.01.15,0,.23,0,.23.01,0,0-.06,0-.21.01-.16,0-.37,0-.63.01-.59,0-1.42.02-2.48.03-2.21.02-5.36.04-9.3.06-7.98.03-19.11.06-31.83.1h-.22s0-.22,0-.22c0-1.89,0-3.82,0-5.78,0-.98,0-1.97,0-2.97,0-.5,0-1,0-1.5v-.75s0-.38,0-.38v-.35h0v-.02h0c.45-.46.13-.13.23-.23,18.15.04,34.29.07,44.44.09h.14s0,.14,0,.14c-.03,3.71-.06,6.66-.08,8.71-.01.98-.03,1.74-.03,2.28,0,.24-.01.43-.01.58,0,.13,0,.19-.01.19Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
            <path
              d="m568.75,22.27s-.01-.08-.02-.22c0-.17-.01-.37-.02-.62,0-.58-.02-1.36-.04-2.36-.02-2.06-.05-4.97-.09-8.55l.16.16c-6.92.02-16.33.04-26.7.06-.2.2.27-.28.22-.23h0v.38s0,.39,0,.39v.77c0,.51,0,1.02,0,1.52,0,1.01,0,2.01,0,2.99,0,1.97,0,3.87,0,5.7l-.22-.22c7.71.04,14.35.08,19.1.1,2.35.03,4.23.05,5.56.06.63.01,1.12.02,1.5.03.34,0,.53.02.54.03,0,0-.16.02-.48.03-.37,0-.85.02-1.46.03-1.33.01-3.19.03-5.52.06-4.79.03-11.48.06-19.25.1h-.22s0-.22,0-.22c0-1.83,0-3.73,0-5.7,0-.98,0-1.98,0-2.99,0-.51,0-1.01,0-1.52v-.77s0-.39,0-.39v-.36h0v-.02h0s.43-.43.23-.23c10.37.02,19.78.05,26.7.06h.16s0,.16,0,.16c-.04,3.62-.07,6.57-.09,8.65-.02.98-.03,1.75-.04,2.32-.01.53-.02.8-.03.78Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
          </g>
          <g>
            <path
              d="m242.69,23.79s0-.08-.01-.21c0-.16,0-.36-.01-.6,0-.55-.02-1.32-.03-2.31-.02-2.03-.04-4.95-.08-8.63l.14.14c-10.15.02-26.29.05-44.44.09.1-.1-.23.23.22-.23h0v.38s0,.38,0,.38v.75c0,.5,0,1,0,1.5,0,1,0,1.99,0,2.97,0,1.96,0,3.89,0,5.78l-.22-.22c12.66.04,23.73.08,31.67.1,3.96.03,7.13.05,9.35.06,1.08.01,1.92.02,2.53.03.27,0,.49.01.66.01.15,0,.23,0,.23.01,0,0-.06,0-.21.01-.16,0-.37,0-.63.01-.59,0-1.42.02-2.48.03-2.21.01-5.36.04-9.3.06-7.98.03-19.11.06-31.83.1h-.22s0-.22,0-.22c0-1.89,0-3.82,0-5.78,0-.98,0-1.97,0-2.97,0-.5,0-1,0-1.5v-.75s0-.38,0-.38v-.35h0v-.02h0c.45-.46.13-.13.23-.23,18.15.04,34.29.07,44.44.09h.14s0,.14,0,.14c-.03,3.71-.06,6.66-.08,8.71-.01.98-.03,1.74-.03,2.28,0,.24-.01.43-.01.58,0,.13,0,.19-.01.19Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
            <path
              d="m248.72,12.03s-.01-.08-.02-.22c0-.17-.01-.37-.02-.62,0-.58-.02-1.36-.04-2.36-.02-2.06-.05-4.97-.09-8.55l.16.16c-6.92.02-16.33.04-26.7.06-.2.2.27-.28.22-.23h0v.38s0,.39,0,.39v.77c0,.51,0,1.02,0,1.52,0,1.01,0,2.01,0,2.99,0,1.97,0,3.87,0,5.7l-.22-.22c7.71.04,14.35.08,19.1.1,2.35.03,4.23.05,5.56.06.63.01,1.12.02,1.5.03.34,0,.53.02.54.03,0,0-.16.02-.48.03-.37,0-.85.02-1.46.03-1.33.01-3.19.03-5.52.06-4.79.03-11.48.06-19.25.1h-.22s0-.22,0-.22c0-1.83,0-3.73,0-5.7,0-.98,0-1.98,0-2.99,0-.51,0-1.01,0-1.52v-.77s0-.39,0-.39v-.36h0v-.02h0s.43-.43.23-.23c10.37.02,19.78.05,26.7.06h.16s0,.16,0,.16c-.04,3.62-.07,6.57-.09,8.65-.02.98-.03,1.75-.04,2.32-.01.53-.02.8-.03.78Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
          </g>
        </g>
        <g id="Floor">
          <path
            d="m700.13,446.79c0,.11-122.55.21-273.68.21s-273.71-.09-273.71-.21,122.52-.21,273.71-.21,273.68.09,273.68.21Z"
            style={{ fill: '#263238', strokeWidth: '0px' }}
          />
        </g>
        <g id="Text">
          <g>
            <rect
              x="245.42"
              y="9.46"
              width="311.22"
              height="261.58"
              rx="18.63"
              ry="18.63"
              style={{
                fill: '#4c6576',
                mixBlendMode: 'multiply',
                opacity: 0.1,
                strokeWidth: '0px',
              }}
            />
            <path
              d="m522.63,332.09h-29.58c-.43,0-.78-.35-.78-.78s.35-.78.78-.78h29.58c.43,0,.78.35.78.78s-.35.78-.78.78Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <rect
              x="253.88"
              y="2.3"
              width="311.22"
              height="261.58"
              rx="18.63"
              ry="18.63"
              style={{ fill: '#e7f5ff', strokeWidth: '0px' }}
            />
            <path
              d="m549.86,267.66h-273.97c-10.48,0-19.01-8.53-19.01-19.01V24.32c0-10.48,8.53-19.01,19.01-19.01h273.97v.78h-273.97c-10.06,0-18.24,8.18-18.24,18.24v224.33c0,10.06,8.18,18.24,18.24,18.24h273.97c10.06,0,18.24-8.18,18.24-18.24V65.05h.78v183.6c0,10.48-8.53,19.01-19.01,19.01Z"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <path
              d="m546.47,2.3h-273.96c-10.24,0-18.63,8.38-18.63,18.63v5.44h311.21v-5.44c0-10.24-8.38-18.63-18.63-18.63Z"
              style={{ fill: '#4c6576', strokeWidth: '0px' }}
            />
            <path
              d="m281.42,20.96c-3.66,0-6.63-2.97-6.63-6.63s2.98-6.63,6.63-6.63,6.63,2.97,6.63,6.63-2.98,6.63-6.63,6.63Zm0-12.49c-3.23,0-5.86,2.63-5.86,5.85s2.63,5.85,5.86,5.85,5.86-2.63,5.86-5.85-2.63-5.85-5.86-5.85Z"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <path
              d="m307.3,14.33c0,3.45-2.79,6.24-6.24,6.24s-6.24-2.79-6.24-6.24,2.79-6.24,6.24-6.24,6.24,2.79,6.24,6.24Z"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <rect
              x="301.54"
              y=".64"
              width="230.2"
              height="289.48"
              rx="5.43"
              ry="5.43"
              transform="translate(555.03 -272.26) rotate(90)"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <circle
              cx="409.36"
              cy="141.36"
              r="67.54"
              transform="translate(19.94 330.87) rotate(-45)"
              style={{ fill: '#e7f5ff', strokeWidth: '0px' }}
            />
            <path
              d="m539.9,253.68c-.11,0-.19-.09-.19-.19v-115.54h-86.27v115.54c0,.11-.09.19-.19.19s-.19-.09-.19-.19v-115.73c0-.11.09-.19.19-.19h86.66c.11,0,.19.09.19.19v115.73c0,.11-.09.19-.19.19Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <path
              d="m524.9,174.23h-63.06c-.11,0-.19-.09-.19-.19v-22.55c0-.11.09-.19.19-.19h63.06c.11,0,.19.09.19.19v22.55c0,.11-.09.19-.19.19Zm-62.86-.39h62.67v-22.16h-62.67v22.16Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <path
              d="m524.9,207.6h-63.06c-.11,0-.19-.09-.19-.19v-22.55c0-.11.09-.19.19-.19h63.06c.11,0,.19.09.19.19v22.55c0,.11-.09.19-.19.19Zm-62.86-.39h62.67v-22.16h-62.67v22.16Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <path
              d="m524.9,241.15h-63.06c-.11,0-.19-.09-.19-.19v-22.55c0-.11.09-.19.19-.19h63.06c.11,0,.19.09.19.19v22.55c0,.11-.09.19-.19.19Zm-62.86-.39h62.67v-22.16h-62.67v22.16Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <path
              d="m310.02,171.8c0-4.73-3.83-8.1-8.57-7.57l-19.73,2.27c-2.45.29-4.8,1.52-6.52,3.46l-11.22,12.52-1.61,62.8c2.19-.37,4.35-1.51,6.03-3.38l38.97-43.52c1.72-1.94,2.7-4.41,2.69-6.83l-.05-19.74Zm-5.15,3.26c-1.68,1.86-4.39,2.19-6.07.7-1.68-1.47-1.68-4.19,0-6.07,1.68-1.86,4.39-2.19,6.07-.7,1.68,1.47,1.68,4.19,0,6.07Z"
              style={{ fill: '#e7f5ff', strokeWidth: '0px' }}
            />
            <path
              d="m302.19,172.54l-.7-.33c.89-1.88,1.57-4.5,2.35-7.53,2.07-8.02,4.91-19,14.55-24.05,8.49-4.45,20.57-3.68,36.94,2.36,18.06,6.66,30.96,7.04,38.32,1.12,7.91-6.35,6.42-17.96,6.41-18.07l.77-.1c.02.12,1.56,12.16-6.69,18.78-7.59,6.1-20.74,5.76-39.08-1-16.14-5.95-28.02-6.74-36.31-2.4-9.34,4.89-12.01,15.24-14.16,23.56-.79,3.07-1.48,5.72-2.4,7.67Z"
              style={{ fill: '#e7f5ff', strokeWidth: '0px' }}
            />
            <g>
              <path
                d="m303.07,161.9c0-4.73-3.83-8.55-8.57-8.57l-19.73-.05c-2.45,0-4.8.96-6.52,2.7l-38.97,38.96c-3.59,3.6-3.59,9.41,0,12.99l19.18,19.2c3.59,3.59,9.41,3.59,12.99,0l38.97-38.97c1.72-1.74,2.7-4.08,2.69-6.52l-.05-19.74Zm-5.15,2.65c-1.68,1.68-4.39,1.68-6.07,0s-1.68-4.39,0-6.07,4.39-1.68,6.07,0,1.68,4.39,0,6.07Z"
                style={{ fill: mainColor, strokeWidth: '0px' }}
              />
              <path
                d="m295.23,161.69l-.69-.35c.89-1.78,1.57-4.31,2.35-7.25,2.01-7.56,4.77-17.91,13.85-22.07,8.52-3.9,20.84-1.82,37.67,6.36,18.29,8.88,31.27,10.71,38.58,5.43,7.58-5.48,6.13-16.99,6.12-17.1l.77-.1c.02.12,1.52,12.08-6.43,17.83-7.55,5.46-20.8,3.66-39.37-5.36-16.61-8.07-28.72-10.15-37.01-6.35-8.75,4-11.34,13.74-13.43,21.56-.79,2.98-1.48,5.55-2.4,7.4Z"
                style={{ fill: '#4c6576', strokeWidth: '0px' }}
              />
              <g>
                <path
                  d="m251.21,189.06c-.87,0-1.68.34-2.28.95-.62.61-.95,1.43-.95,2.28s.33,1.68.95,2.3,1.41.95,2.28.95,1.68-.34,2.28-.95.95-1.43.95-2.3-.33-1.68-.95-2.28c-.61-.61-1.41-.95-2.28-.95Z"
                  style={{ fill: 'none', strokeWidth: '0px' }}
                />
                <path
                  d="m276.59,189.06c-.87,0-1.68.34-2.28.95-.62.61-.96,1.43-.96,2.28s.34,1.68.96,2.3,1.41.95,2.28.95,1.68-.34,2.28-.95.95-1.43.95-2.3-.33-1.68-.95-2.28c-.61-.61-1.41-.95-2.28-.95Z"
                  style={{ fill: 'none', strokeWidth: '0px' }}
                />
                <path
                  d="m247.83,188.9c-1.88,1.88-1.88,4.9,0,6.78s4.9,1.86,6.77,0c1.86-1.88,1.86-4.9,0-6.78-1.86-1.86-4.9-1.86-6.77,0Zm5.67,5.68c-.61.61-1.41.95-2.28.95s-1.68-.34-2.28-.95-.95-1.43-.95-2.3.33-1.68.95-2.28c.61-.61,1.41-.95,2.28-.95s1.68.34,2.28.95c.62.61.95,1.43.95,2.28s-.33,1.68-.95,2.3Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
                <path
                  d="m279.97,188.9c-1.86-1.86-4.9-1.86-6.77,0s-1.88,4.9,0,6.78,4.9,1.86,6.77,0c1.86-1.88,1.86-4.9,0-6.78Zm-1.1,5.68c-.61.61-1.41.95-2.28.95s-1.68-.34-2.28-.95-.96-1.43-.96-2.3.34-1.68.96-2.28c.61-.61,1.41-.95,2.28-.95s1.68.34,2.28.95c.62.61.95,1.43.95,2.28s-.33,1.68-.95,2.3Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
              </g>
              <path
                d="m262.06,210.58c-.21,0-.42-.04-.63-.13-.61-.25-.99-.82-.99-1.49v-29.64c0-.43.17-.83.47-1.14l3.87-3.87c.47-.47,1.14-.6,1.75-.35.61.25.99.82.99,1.49v29.64c0,.42-.17.84-.47,1.14l-3.87,3.87c-.31.31-.71.47-1.13.47Zm3.85-36.35c-.31,0-.62.12-.86.36l-3.87,3.87c-.23.23-.36.54-.36.86v29.64c0,.5.29.94.75,1.13.47.19.98.09,1.33-.27l3.87-3.87c.23-.23.36-.54.36-.86v-29.64c0-.5-.29-.94-.76-1.13-.16-.06-.32-.1-.47-.1Z"
                style={{ fill: '#fff', strokeWidth: '0px' }}
              />
            </g>
            <g>
              <path
                d="m415.19,94.79h-9.86l-11.9-27.13c0,5.61,33.67,5.61,33.67,0l-11.9,27.13Z"
                style={{ fill: '#374954', strokeWidth: '0px' }}
              />
              <path
                d="m426.15,116.72v-6.94s3.5-18.17,8.27-41.05c.33-1.58-.88-3.06-2.49-3.06h-5.73l-15.95,26.04-15.95-26.04h-5.73c-1.61,0-2.82,1.48-2.49,3.06,4.77,22.88,8.27,41.05,8.27,41.05v6.94c-13.07,37.65-23.09,96.29-23.09,96.29l38.98,4.04v-2.21l38.98-1.83s-10.02-58.64-23.09-96.29Z"
                style={{ fill: '#4c6576', strokeWidth: '0px' }}
              />
              <path
                d="m398.48,110.44s-.07,0-.1-.03c-.09-.06-.12-.18-.06-.27l27.72-44.58c.06-.09.18-.12.27-.06.09.06.12.18.06.27l-27.72,44.58c-.04.06-.1.09-.17.09Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
              <path
                d="m409.49,110.77c-6.37,0-12.29-.29-15.16-.81-.1-.02-.17-.12-.16-.23.02-.1.1-.18.23-.16,5.48.98,22.17,1.16,31.73,0,.1-.02.2.06.22.17.01.11-.06.2-.17.22-4.59.56-10.83.81-16.68.81Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
              <path
                d="m409.49,117.72c-6.37,0-12.29-.29-15.16-.81-.1-.02-.17-.12-.16-.23.02-.11.1-.18.23-.16,5.47.98,22.16,1.16,31.73,0,.1-.02.2.06.22.17.01.11-.06.2-.17.22-4.59.56-10.83.8-16.68.8Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
              <path
                d="m422.53,114.8c-14.05,0-24.99-2-28.24-3.49-.1-.04-.14-.16-.1-.26.04-.1.16-.14.26-.1,3.64,1.67,16.68,3.78,31.7,3.42h0c.1,0,.19.08.19.19,0,.11-.08.2-.19.2-1.23.03-2.44.04-3.63.04Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
              <path
                d="m409.33,112.81c-2.44,0-4.55-.11-5.91-.33-.11-.02-.18-.12-.16-.22.02-.1.1-.18.22-.16,4.44.7,16.84.29,22.62-1.49.11-.03.21.03.24.13.03.1-.03.21-.13.24-4.05,1.25-11.32,1.83-16.88,1.83Z"
                style={{ fill: '#fff', strokeWidth: '0px' }}
              />
              <path
                d="m406.38,99.53s-.07,0-.1-.03c-.09-.06-.12-.18-.06-.27l5.96-9.58c.06-.09.18-.12.27-.06.09.06.12.18.06.27l-5.96,9.58c-.04.06-.1.09-.17.09Z"
                style={{ fill: '#fff', strokeWidth: '0px' }}
              />
              <polygon
                points="378.58 204.04 376.76 215.67 400.84 213.01 378.58 204.04"
                style={{ fill: '#4c6576', strokeWidth: '0px' }}
              />
              <polygon
                points="432.85 203.21 435.31 216 411.23 213.35 432.85 203.21"
                style={{ fill: '#4c6576', strokeWidth: '0px' }}
              />
              <path
                d="m410.26,217.25h0c-.11,0-.19-.09-.19-.2l2.09-76.8c0-.11.09-.19.19-.19h0c.11,0,.19.09.19.2l-2.09,76.8c0,.11-.09.19-.19.19Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
              <path
                d="m376.76,215.87s-.02,0-.04,0c-.1-.02-.17-.12-.15-.23l12.67-64.18c.02-.1.11-.18.23-.15.1.02.17.12.15.23l-12.67,64.18c-.02.09-.1.16-.19.16Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
              <path
                d="m387.22,211.16s-.02,0-.03,0c-.11-.02-.18-.12-.16-.22l6.27-35.22c.02-.1.12-.18.22-.16.11.02.18.12.16.23l-6.27,35.22c-.02.09-.1.16-.19.16Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
              <path
                d="m421.11,212.71c-.1,0-.19-.08-.19-.19l-1.7-42.63c0-.11.08-.2.19-.2.1,0,.2.08.2.19l1.7,42.63c0,.11-.08.2-.19.2h0Z"
                style={{ fill: '#fff', strokeWidth: '0px' }}
              />
              <path
                d="m434.87,213.88c-.09,0-.18-.07-.19-.17l-8.55-57.1c-.02-.11.06-.2.16-.22.1-.02.2.06.22.16l8.55,57.1c.02.11-.06.2-.16.22,0,0-.02,0-.03,0Z"
                style={{ fill: '#3c525e', strokeWidth: '0px' }}
              />
            </g>
            <rect
              x="275.83"
              y="30"
              width="267.06"
              height="28"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <path
              d="m537.36,54.59c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73,2.73,1.22,2.73,2.73-1.22,2.73-2.73,2.73Zm0-5.07c-1.29,0-2.34,1.05-2.34,2.34s1.05,2.34,2.34,2.34,2.34-1.05,2.34-2.34-1.05-2.34-2.34-2.34Z"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <path
              d="m526.36,54.59c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73,2.73,1.22,2.73,2.73-1.22,2.73-2.73,2.73Zm0-5.07c-1.29,0-2.34,1.05-2.34,2.34s1.05,2.34,2.34,2.34,2.34-1.05,2.34-2.34-1.05-2.34-2.34-2.34Z"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <rect
              x="275.83"
              y="70.12"
              width="68.42"
              height="3.7"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <rect
              x="275.83"
              y="84.92"
              width="37.74"
              height="3.7"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <rect
              x="325.53"
              y="84.92"
              width="4.68"
              height="3.7"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <rect
              x="318.05"
              y="84.92"
              width="4.68"
              height="3.7"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <rect
              x="275.83"
              y="77.52"
              width="22.41"
              height="3.7"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <rect
              x="304.36"
              y="77.52"
              width="16.03"
              height="3.7"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <path
              d="m318.25,116.62h-42.61v-17.65h42.61v17.65Zm-42.22-.39h41.83v-16.87h-41.83v16.87Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <path
              d="m353.16,279.18h-50.65c-4.31,0-7.82-3.51-7.82-7.82v-20.7c0-4.31,3.51-7.82,7.82-7.82h50.65c4.31,0,7.82,3.51,7.82,7.82v20.7c0,4.31-3.51,7.82-7.82,7.82Zm-50.65-35.95c-4.1,0-7.43,3.33-7.43,7.43v20.7c0,4.1,3.33,7.43,7.43,7.43h50.65c4.1,0,7.43-3.33,7.43-7.43v-20.7c0-4.1-3.33-7.43-7.43-7.43h-50.65Z"
              style={{ fill: '#dc404a', strokeWidth: '0px' }}
            />
            <rect
              x="299.25"
              y="239.1"
              width="65.89"
              height="35.95"
              rx="4.91"
              ry="4.91"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <rect
              x="374.16"
              y="239.1"
              width="49.06"
              height="35.95"
              rx="4.91"
              ry="4.91"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <g>
              <path
                d="m310.24,266.04v-16.78h6.17c1.16,0,2.15.11,2.96.32.81.22,1.48.52,1.99.91.51.39.89.87,1.12,1.43.23.56.35,1.19.35,1.88,0,.38-.05.74-.16,1.09-.11.35-.28.68-.51.99-.23.31-.53.59-.89.83-.36.25-.79.47-1.3.65,1.11.27,1.92.7,2.45,1.31.53.6.79,1.38.79,2.32,0,.71-.14,1.38-.42,1.99s-.68,1.15-1.22,1.61-1.19.81-1.98,1.07c-.78.26-1.68.39-2.68.39h-6.7Zm3.89-9.72h2.03c.43,0,.82-.03,1.16-.09s.64-.17.88-.32c.24-.15.43-.36.55-.63.13-.27.19-.61.19-1.01s-.05-.73-.15-1c-.1-.26-.25-.48-.46-.64-.21-.16-.47-.28-.79-.34-.32-.07-.7-.1-1.13-.1h-2.28v4.15Zm0,2.6v4.18h2.74c.51,0,.93-.07,1.26-.2.33-.13.58-.3.77-.51.18-.21.31-.44.38-.7.07-.26.1-.53.1-.79,0-.31-.04-.58-.12-.83-.08-.25-.22-.45-.41-.62s-.46-.3-.78-.39c-.32-.09-.73-.14-1.22-.14h-2.72Z"
                style={{ fill: '#fff', strokeWidth: '0px' }}
              />
              <path
                d="m332.67,262.96c.52,0,.99-.09,1.4-.26.41-.17.76-.42,1.04-.74s.5-.72.65-1.18c.15-.46.22-.99.22-1.58v-9.94h3.89v9.94c0,1.03-.17,1.97-.5,2.83-.33.86-.81,1.6-1.43,2.22s-1.38,1.11-2.27,1.45-1.89.52-3.01.52-2.13-.17-3.02-.52c-.89-.35-1.65-.83-2.27-1.45s-1.1-1.36-1.43-2.22-.5-1.8-.5-2.83v-9.94h3.89v9.93c0,.59.07,1.12.22,1.58.15.46.37.86.65,1.19.28.33.63.58,1.04.75s.88.26,1.4.26Z"
                style={{ fill: '#fff', strokeWidth: '0px' }}
              />
              <path
                d="m350.97,259.66v6.38h-3.89v-6.38l-6.08-10.4h3.43c.34,0,.6.08.8.24.2.16.36.36.49.6l2.37,4.88c.2.38.38.74.54,1.08.16.33.3.66.42.98.12-.33.25-.66.4-1,.15-.33.33-.69.53-1.07l2.35-4.88c.05-.1.12-.2.2-.3.08-.1.17-.19.27-.27.1-.08.22-.15.36-.2.13-.05.28-.08.44-.08h3.46l-6.08,10.4Z"
                style={{ fill: '#fff', strokeWidth: '0px' }}
              />
            </g>
            <path
              d="m398.69,268.22c-6.15,0-11.15-5-11.15-11.15s5-11.15,11.15-11.15,11.15,5,11.15,11.15-5,11.15-11.15,11.15Zm0-21.91c-5.93,0-10.76,4.83-10.76,10.76s4.83,10.76,10.76,10.76,10.76-4.83,10.76-10.76-4.83-10.76-10.76-10.76Z"
              style={{ fill: mainColor, strokeWidth: '0px' }}
            />
            <path
              d="m471.95,137.94c-5.68,0-10.3-4.62-10.3-10.3,0-.11.09-.19.19-.19h20.22c.11,0,.19.09.19.19,0,5.68-4.62,10.3-10.3,10.3Zm-9.91-10.11c.1,5.38,4.51,9.72,9.91,9.72s9.81-4.34,9.91-9.72h-19.83Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
            <path
              d="m431.4,153.87c-9.59,0-17.39-7.8-17.39-17.39s7.8-17.39,17.39-17.39,17.39,7.8,17.39,17.39-7.8,17.39-17.39,17.39Zm0-34.39c-9.37,0-17,7.63-17,17s7.63,17,17,17,17-7.63,17-17-7.63-17-17-17Z"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <path
              d="m387.22,103.62c-5.53,0-10.02-4.5-10.02-10.02s4.5-10.02,10.02-10.02,10.02,4.5,10.02,10.02-4.5,10.02-10.02,10.02Zm0-19.65c-5.31,0-9.63,4.32-9.63,9.63s4.32,9.63,9.63,9.63,9.63-4.32,9.63-9.63-4.32-9.63-9.63-9.63Z"
              style={{ fill: '#fff', strokeWidth: '0px' }}
            />
            <path
              d="m467.6,80.14c-5.52,0-10.02-4.5-10.02-10.02s4.5-10.02,10.02-10.02,10.02,4.49,10.02,10.02-4.5,10.02-10.02,10.02Zm0-19.65c-5.31,0-9.63,4.32-9.63,9.63s4.32,9.63,9.63,9.63,9.63-4.32,9.63-9.63-4.32-9.63-9.63-9.63Z"
              style={{ fill: '#c5d5de', strokeWidth: '0px' }}
            />
          </g>
        </g>
        <g id="Stairs">
          <g>
            <g>
              <g>
                <rect
                  x="487.7"
                  y="286.25"
                  width="38.14"
                  height="40.26"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <rect
                  x="485.68"
                  y="284.48"
                  width="41.58"
                  height="1.77"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <path
                  d="m525.84,294.12c0,.14-8.33.26-18.59.26s-18.6-.12-18.6-.26,8.32-.26,18.6-.26,18.59.12,18.59.26Z"
                  style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
                />
                <path
                  d="m525.84,302.91c0,.14-8.33.26-18.59.26s-18.6-.12-18.6-.26,8.32-.26,18.6-.26,18.59.12,18.59.26Z"
                  style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
                />
                <path
                  d="m525.84,311.7c0,.14-8.33.26-18.59.26s-18.6-.12-18.6-.26,8.32-.26,18.6-.26,18.59.12,18.59.26Z"
                  style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
                />
                <path
                  d="m525.84,320.49c0,.14-8.33.26-18.59.26s-18.6-.12-18.6-.26,8.32-.26,18.6-.26,18.59.12,18.59.26Z"
                  style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
                />
                <path
                  d="m525.84,286.75s-.03.21-.13.6c-.1.39-.27.96-.49,1.7-.45,1.47-1.13,3.59-2.25,6.11-1.13,2.49-2.7,5.45-5.3,8.07-1.3,1.29-2.83,2.5-4.65,3.37-1.8.86-3.9,1.39-6.04,1.25-2.14-.1-4.16-.77-5.93-1.68-1.77-.93-3.33-2.1-4.63-3.39-2.62-2.58-4.29-5.47-5.52-7.92-1.22-2.47-1.98-4.56-2.49-6.01-.25-.73-.43-1.29-.55-1.68-.12-.39-.17-.59-.15-.6s.1.19.25.56c.17.43.38.97.64,1.64.56,1.42,1.38,3.49,2.63,5.91,1.26,2.41,2.95,5.24,5.53,7.74,2.55,2.46,6.12,4.67,10.26,4.89,2.05.14,4.05-.36,5.79-1.19,1.76-.83,3.25-2,4.53-3.25,2.56-2.53,4.14-5.42,5.31-7.88,1.15-2.48,1.88-4.57,2.39-6.02.24-.68.43-1.23.58-1.67.14-.38.22-.58.23-.57Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
              </g>
              <path
                d="m490.63,284.65c.1,2.22,2.08,4.2,4.3,4.3,1.07.05,2.23-.27,3.18.24,1.68.9,1.57,3.74,3.33,4.46.98.4,2.09-.08,2.96-.69.87-.6,1.69-1.36,2.72-1.59,1.25-.28,2.52.29,3.75.66,1.23.37,2.75.47,3.62-.47.85-.93.92-2.68,2.15-2.96.7-.16,1.37.32,2.07.5,1.24.34,2.59-.29,3.46-1.24s1.37-2.17,1.85-3.37l-33.39.16Z"
                style={{ fill: mainColor, strokeWidth: '0px' }}
              />
              <path
                d="m527.44,286.31c0,.14-9.37.26-20.93.26s-20.93-.12-20.93-.26,9.37-.26,20.93-.26,20.93.12,20.93.26Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
            </g>
            <path
              d="m508.07,446.79l-.19-118.78h-7.36c.47.81,0,118.78,0,118.78h7.56Z"
              style={{ fill: '#6f393d', strokeWidth: '0px' }}
            />
            <g>
              <rect
                x="466.39"
                y="326.51"
                width="75.1"
                height="7.92"
                style={{ fill: '#ae6166', strokeWidth: '0px' }}
              />
              <rect
                x="459.16"
                y="370.35"
                width="50.86"
                height="7.92"
                style={{ fill: '#ae6166', strokeWidth: '0px' }}
              />
              <rect
                x="441.28"
                y="416.32"
                width="53.36"
                height="7.92"
                style={{ fill: '#ae6166', strokeWidth: '0px' }}
              />
              <polygon
                points="470.52 326.51 430.67 446.74 439.35 446.74 479.54 326.51 470.52 326.51"
                style={{ fill: '#ae6166', strokeWidth: '0px' }}
              />
              <polygon
                points="518.6 326.51 480.06 446.74 489.09 446.74 527.83 326.51 518.6 326.51"
                style={{ fill: '#ae6166', strokeWidth: '0px' }}
              />
              <path
                d="m537.28,446.79l-.19-118.78h-7.36c.47.81,0,118.78,0,118.78h7.56Z"
                style={{ fill: '#ae6166', strokeWidth: '0px' }}
              />
            </g>
            <rect
              x="517.44"
              y="326.51"
              width="24.06"
              height="7.92"
              style={{ fill: '#6f393d', strokeWidth: '0px' }}
            />
          </g>
        </g>
        <g id="Stains">
          <path
            d="m330.55,329.65c-.8-3.49-5.96-4.13-7.47-7.37-1.25-2.68.51-5.7,1.81-8.37,1.3-2.66,1.79-6.58-.83-7.96-3.23-1.71-7.45,2.56-10.6.69-1.44-.86-1.95-2.67-3.03-3.94-1.17-1.37-3.07-2.06-4.85-1.8h0s-.02,0-.03,0c-.22.03-.44.08-.66.14-.29.08-.56.2-.83.34-4.79,1.89-6.59,6.71-7.56,8.65-1.07,2.13-1.22,4.63-2.27,6.78-1.17,2.4-3.34,4.13-5.42,5.8-2.08,1.68-4.21,3.48-5.25,5.94-1.04,2.46-.58,5.77,1.71,7.14,1.52.92,7.12-.58,8.78.05,3.29,1.24,2.84,4.9,2.55,9.53-.22,3.51,3.85,6.72,7.37,6.68,3.23-.03,5.56-3.08,6.94-6.01,1.37-2.93,2.49-6.25,5.21-8.01,2.58-1.67,5.9-1.46,8.9-2.12s6.22-3.15,5.53-6.15Z"
            style={{ fill: mainColor, strokeWidth: '0px' }}
          />
        </g>
        <g id="Paints">
          <g>
            <g>
              <rect
                x="275.32"
                y="403.75"
                width="40.46"
                height="42.71"
                style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
              />
              <rect
                x="273.18"
                y="401.88"
                width="44.11"
                height="1.87"
                style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
              />
              <path
                d="m315.79,412.11c0,.14-8.83.26-19.73.26s-19.73-.12-19.73-.26,8.83-.26,19.73-.26,19.73.12,19.73.26Z"
                style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
              />
              <path
                d="m315.79,421.44c0,.14-8.83.26-19.73.26s-19.73-.12-19.73-.26,8.83-.26,19.73-.26,19.73.12,19.73.26Z"
                style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
              />
              <path
                d="m315.79,430.76c0,.14-8.83.26-19.73.26s-19.73-.12-19.73-.26,8.83-.26,19.73-.26,19.73.12,19.73.26Z"
                style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
              />
              <path
                d="m315.79,440.09c0,.14-8.83.26-19.73.26s-19.73-.12-19.73-.26,8.83-.26,19.73-.26,19.73.12,19.73.26Z"
                style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
              />
              <path
                d="m315.79,404.29s-.03.23-.14.64c-.11.41-.28,1.02-.52,1.8-.48,1.55-1.2,3.8-2.39,6.48-1.2,2.65-2.87,5.78-5.63,8.55-1.38,1.36-3,2.65-4.93,3.57-1.91.92-4.13,1.47-6.4,1.32-2.27-.11-4.41-.81-6.29-1.78-1.88-.99-3.53-2.23-4.91-3.59-2.78-2.73-4.55-5.8-5.86-8.4-1.29-2.62-2.1-4.84-2.64-6.37-.27-.77-.46-1.37-.59-1.78-.12-.41-.18-.63-.16-.63.02,0,.11.2.26.6.17.45.4,1.03.68,1.74.6,1.51,1.46,3.7,2.78,6.28,1.34,2.55,3.13,5.56,5.87,8.22,1.36,1.33,2.98,2.53,4.81,3.48,1.83.93,3.91,1.61,6.08,1.71,2.18.14,4.3-.38,6.15-1.26,1.86-.88,3.45-2.12,4.81-3.45,2.71-2.69,4.4-5.76,5.63-8.36,1.22-2.63,1.99-4.85,2.53-6.39.25-.73.46-1.31.62-1.77.14-.4.23-.61.24-.61Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m317.48,403.82c0,.14-9.94.26-22.2.26s-22.2-.12-22.2-.26,9.94-.26,22.2-.26,22.2.12,22.2.26Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
            </g>
            <path
              d="m307.35,401.88h-23.4c-.29,1.41.27,3.22,1.3,4.22,1.03,1,2.59,1.43,3.99,1.1,1-.24,2-.83,2.99-.54.79.23,1.3.97,1.86,1.58,2.16,2.36,5.92,3.09,8.8,1.7,2.89-1.38,4.95-4.9,4.46-8.06Z"
              style={{ fill: '#455a64', strokeWidth: '0px' }}
            />
          </g>
        </g>
        <g id="Character_2" data-name="Character 2">
          <g>
            <g>
              <path
                d="m641.3,446.79l-.19-246.29h-7.36c.47,1.69,0,246.29,0,246.29h7.56Z"
                style={{ fill: '#f5f5f5', strokeWidth: '0px' }}
              />
              <g>
                <rect
                  x="585.74"
                  y="326.51"
                  width="72.84"
                  height="7.92"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <g style={{ opacity: 0.3 }}>
                  <rect
                    x="642.73"
                    y="326.51"
                    width="15.85"
                    height="7.92"
                    style={{ strokeWidth: '0px' }}
                  />
                </g>
                <rect
                  x="572.4"
                  y="366.87"
                  width="72.84"
                  height="7.92"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <rect
                  x="561.05"
                  y="409.87"
                  width="72.84"
                  height="7.92"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <polyline
                  points="606.5 272.94 693.48 272.94 693.48 280.87 606.5 280.87"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <g style={{ opacity: 0.3 }}>
                  <rect
                    x="657.68"
                    y="272.94"
                    width="36.06"
                    height="7.64"
                    style={{ strokeWidth: '0px' }}
                  />
                </g>
                <polygon
                  points="647.21 148.31 550.58 446.74 559.27 446.74 655.9 150.76 647.21 148.31"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <g style={{ opacity: 0.3 }}>
                  <rect
                    x="628.07"
                    y="366.87"
                    width="17.17"
                    height="7.92"
                    style={{ strokeWidth: '0px' }}
                  />
                </g>
                <g style={{ opacity: 0.3 }}>
                  <rect
                    x="611.63"
                    y="409.87"
                    width="22.11"
                    height="7.92"
                    style={{ strokeWidth: '0px' }}
                  />
                </g>
                <path
                  d="m694.46,127.64c-12.19-.13-22.03.85-30.11,4.41-8.82,3.88-14.86,10.15-17.69,17.86l9.24.85s6.01-11.09,17.58-14.29c8.35-2.3,20.19-1.94,22.81-1.36,5.42,1.19,8.3,6.91,4.75,17.68h0l-88.51,293.95h9.02l89.35-296.03c1.04-3.42,5.46-22.83-16.44-23.07Z"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <path
                  d="m693.48,446.79l-.19-246.29h-7.36c.47,1.69,0,246.29,0,246.29h7.56Z"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="m616.85,335.05l.7,20.49s-20.59,7.59-20.83,11.39l39.77.07.13-18.44-19.77-13.5Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
                <path
                  d="m629.85,353.83c.77.21,1.3,1.08,1.12,1.86-.18.78-1.06,1.32-1.83,1.13-.77-.2-1.44-1.22-1.18-1.97.27-.75,1.33-1.34,2.03-.96"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <path
                  d="m636.49,366.99l-.1-3.22-38.09,1.14s-1.77.77-1.57,2.01l39.77.07Z"
                  style={{ fill: '#455a64', strokeWidth: '0px' }}
                />
                <path
                  d="m616.9,355.33c0,.19.98.28,1.93.91.97.6,1.46,1.44,1.64,1.37.18-.04-.11-1.2-1.28-1.94-1.16-.75-2.34-.52-2.29-.34Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
                <path
                  d="m612.73,357.05c-.04.19.8.52,1.45,1.34.68.8.85,1.69,1.04,1.68.18.03.32-1.11-.52-2.11-.83-1.01-1.97-1.1-1.98-.91Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
                <path
                  d="m610.35,362.04c.18.04.45-.93-.05-1.99-.49-1.07-1.4-1.49-1.49-1.33-.11.16.48.73.87,1.61.42.87.47,1.69.66,1.71Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
                <path
                  d="m617.26,350.64c.08.18.97-.09,2.07-.01,1.1.05,1.95.42,2.05.26.11-.14-.72-.86-2.01-.93-1.29-.08-2.2.53-2.11.69Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
              </g>
              <g>
                <path
                  d="m601.09,294.83l1.26,19.62s-19.52,7.87-19.64,11.51l38.13-1.07-.7-30.65-19.04.59Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
                <path
                  d="m614.08,312.47c.74.18,1.28,1,1.13,1.75-.15.75-.97,1.3-1.72,1.13-.75-.17-1.42-1.13-1.18-1.86.23-.73,1.24-1.32,1.92-.98"
                  style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                />
                <path
                  d="m620.83,324.89l-.19-3.09-36.48,2.18s-1.68.79-1.45,1.98l38.13-1.07Z"
                  style={{ fill: '#455a64', strokeWidth: '0px' }}
                />
                <path
                  d="m601.72,314.27c.01.19.94.24,1.88.82.95.55,1.44,1.34,1.61,1.26.17-.04-.14-1.15-1.28-1.82-1.14-.68-2.25-.43-2.21-.26Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
                <path
                  d="m597.77,316.04c-.04.18.78.48,1.43,1.25.68.75.86,1.6,1.05,1.58.18.02.27-1.07-.56-2.01-.82-.95-1.92-1-1.92-.82Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
                <path
                  d="m595.63,320.89c.17.03.4-.9-.1-1.91-.5-1.01-1.39-1.39-1.46-1.23-.1.16.48.69.88,1.52.42.82.5,1.61.68,1.62Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
                <path
                  d="m601.93,309.77c.08.17.93-.11,1.98-.07,1.06.02,1.88.35,1.98.19.1-.14-.71-.8-1.96-.84-1.24-.04-2.1.58-2,.72Z"
                  style={{ fill: '#fff', strokeWidth: '0px' }}
                />
              </g>
              <g>
                <g>
                  <path
                    d="m627.36,64.27c-.67-1.48-2.36-2.43-3.97-2.23-1.79-1.59-4.72-1.7-6.62-.25-1.37-.92-3.22-1.06-4.71-.36-1.49.7-2.56,2.22-2.72,3.85-2.15,1.01-3.64.94-4.29,1.87-.96,1.36-.67,3.4.42,4.66-1.82.48-3.11,2.45-2.82,4.32.29,1.86,2.13,3.34,4.01,3.24l19.73-10.65c1.22-1.07,1.64-2.96.97-4.45Z"
                    style={{ fill: '#263238', strokeWidth: '0px' }}
                  />
                  <g>
                    <g>
                      <path
                        d="m606.08,79.87c1.16-8.42,8.6-14.53,17.08-14.05l.84.05c9.06,1.05,13.23,9.33,11.93,18.36l-3.04,30.19-.91,8.21-20.47-3.73.47-7.71s-8.66-1.86-8.27-10.6c.19-4.22,1.28-12.89,2.37-20.72Z"
                        style={{ fill: '#ff9da4', strokeWidth: '0px' }}
                      />
                      <path
                        d="m611.91,111.17s5.56.65,11.64-2.7c0,0-3.05,6.13-11.72,4.85l.08-2.15Z"
                        style={{ fill: '#ff6c76', strokeWidth: '0px' }}
                      />
                      <path
                        d="m607.91,88.38c-.06.66.45,1.25,1.13,1.33.68.08,1.29-.39,1.35-1.05.06-.66-.45-1.25-1.13-1.33-.69-.08-1.29.39-1.35,1.05Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m607.71,84.25c.15.17,1.13-.47,2.47-.39,1.34.06,2.29.81,2.44.65.08-.07-.07-.37-.48-.71-.4-.34-1.11-.68-1.94-.72-.84-.04-1.56.22-1.98.52-.43.29-.59.58-.52.66Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m619.76,89.27c-.06.66.45,1.25,1.13,1.33.68.08,1.29-.39,1.35-1.05.06-.66-.45-1.25-1.13-1.33-.69-.08-1.29.39-1.35,1.05Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m620.89,85.25c.15.17,1.13-.47,2.47-.39,1.34.06,2.29.81,2.44.65.08-.07-.06-.37-.48-.71-.4-.34-1.11-.68-1.94-.72-.84-.04-1.56.22-1.98.52-.43.29-.59.58-.52.66Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m615.45,94.93c.01-.08-.82-.27-2.16-.52-.34-.05-.66-.14-.71-.38-.07-.25.1-.61.29-1,.37-.81.76-1.66,1.16-2.55,1.62-3.64,2.81-6.63,2.67-6.7-.15-.06-1.58,2.83-3.2,6.47-.39.9-.77,1.75-1.12,2.57-.15.38-.4.81-.25,1.34.08.26.32.46.54.53.22.08.41.09.58.11,1.36.15,2.2.2,2.21.13Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m619.35,96.09c-.22-.03-.3,1.43-1.61,2.4-1.3.97-2.85.71-2.87.92-.03.09.33.3.98.36.64.06,1.59-.09,2.39-.68.8-.59,1.17-1.42,1.26-2.01.09-.61-.05-.98-.15-.97Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m620.85,79.97c.11.37,1.46.28,3.03.56,1.57.25,2.81.78,3.04.47.1-.15-.1-.51-.59-.89-.49-.38-1.28-.76-2.22-.92-.94-.16-1.81-.06-2.4.14-.59.2-.9.47-.85.65Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m607.75,80.39c.22.32,1.16.05,2.27.1,1.11,0,2.04.32,2.28.02.1-.15-.03-.46-.42-.78-.39-.31-1.05-.58-1.82-.6-.77-.02-1.44.22-1.85.51-.41.29-.56.6-.46.75Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m607.06,75.36s2.62-5.34,5.33-6.47c3.28-1.36,4.46,2.22,7.55,1.54,3.09-.68,6.23-1.99,9.2.54,3.26,2.77,1.56,4.49,1.55,8.37-.04,15.77,2.57,20.37,3.81,13.02.14-.81,2.42-1.32,2.95-4.73.69-4.42,1.16-9.8.35-13.01-.68-2.71-2.1-4.63-4.7-6.81-4.11-3.44-10-3.84-14.02-3.67-4.02.17-12,4.34-12.03,11.21Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                      <path
                        d="m634.04,92.65c.13-1.35,1.35-2.34,2.69-2.14,1.62.25,3.38,1.28,3.08,4.64-.53,5.96-6.41,4.2-6.4,4.03,0-.12.39-4.03.63-6.52Z"
                        style={{ fill: '#ff9da4', strokeWidth: '0px' }}
                      />
                      <path
                        d="m634.91,97.05s.1.08.27.18c.16.09.45.17.77.09.65-.18,1.27-1.13,1.39-2.2.06-.54,0-1.07-.16-1.51-.14-.45-.4-.78-.71-.87-.31-.1-.55.07-.64.23-.09.16-.07.28-.1.29-.02.01-.12-.11-.05-.35.04-.12.12-.25.27-.35.15-.1.37-.14.59-.1.46.07.83.53,1,1.02.19.49.28,1.09.21,1.69-.15,1.2-.86,2.27-1.74,2.44-.43.07-.77-.09-.94-.24-.17-.16-.19-.3-.17-.31Z"
                        style={{ fill: '#ff6c76', strokeWidth: '0px' }}
                      />
                    </g>
                    <path
                      d="m634.59,81.07c-.81.49-1.66.98-2.6,1.02-.94.04-1.98-.56-2.08-1.49-.05-.42.08-.9-.17-1.23-.3-.4-.91-.32-1.42-.29-1.75.09-3.46-1.24-3.8-2.96-.48.64-1.51.55-2.16.08-.65-.47-1.04-1.2-1.53-1.83s-1.2-1.21-1.99-1.14c-.45.04-.87.28-1.32.21-.78-.11-1.11-1.05-1.19-1.84-.07-.79-.11-1.7-.76-2.16-.59-.43-1.4-.25-2.13-.18-.73.07-1.65-.16-1.8-.87-.08-.38.11-.76.33-1.07,1.01-1.41,3.08-1.95,4.65-1.2.7-1.94,2.63-3.37,4.69-3.47,2.06-.1,4.12,1.14,5,3,1.33-1.07,3.19-1.45,4.83-.98,1.64.47,3.01,1.78,3.57,3.4,1.47-.3,3.1.36,3.95,1.6.85,1.24.88,2.99.08,4.26,1.04.67,1.9,1.79,1.85,3.03-.05,1.24-1.32,2.4-2.5,2.01.43.99-.04,2.27-1,2.76-.96.48-2.27.09-2.81-.84"
                      style={{ fill: '#263238', strokeWidth: '0px' }}
                    />
                  </g>
                  <path
                    d="m638.54,78.98s.08.51-.44,1.13c-.26.29-.68.59-1.24.65-.54.06-1.2-.11-1.67-.59l.25-.02c-.32.5-.74,1-1.28,1.42-.34.27-.72.49-1.13.61-.4.13-.85.12-1.21.04-.75-.13-1.39-.57-1.67-1.09-.29-.51-.37-.96-.41-1.27-.04-.31-.04-.48-.02-.49.03,0,.07.16.15.46.08.29.2.73.49,1.17.52.89,2.34,1.43,3.58.29.49-.39.9-.87,1.22-1.33l.12-.17.14.15c.39.41.94.57,1.41.54.48-.03.86-.26,1.11-.51.51-.52.53-1,.59-.99Z"
                    style={{ fill: '#455a64', strokeWidth: '0px' }}
                  />
                  <path
                    d="m628.03,64.69s-.16.03-.43.13c-.27.1-.66.3-.97.74l-.08.11-.08-.12c-.52-.78-1.4-1.8-2.75-2.5-.68-.35-1.47-.6-2.32-.65-.85-.05-1.76.14-2.57.58-.63.34-1.18.81-1.59,1.34-.4.54-.66,1.15-.76,1.76l-.03.19-.18-.06c-.88-.3-1.81-.18-2.5.16-.7.34-1.17.88-1.44,1.38-.54,1.02-.41,1.76-.47,1.75-.01,0-.05-.17-.04-.49.01-.32.08-.8.35-1.34.26-.54.74-1.14,1.49-1.53.74-.38,1.74-.54,2.72-.22l-.21.13c.09-.67.37-1.33.8-1.92.44-.58,1.02-1.08,1.7-1.44.87-.47,1.84-.67,2.75-.61.91.06,1.74.34,2.44.72,1.41.76,2.28,1.84,2.77,2.67h-.15c.36-.47.8-.65,1.09-.73.3-.07.46-.05.46-.04Z"
                    style={{ fill: '#455a64', strokeWidth: '0px' }}
                  />
                </g>
                <path
                  d="m627.88,92.75c-.72-.24-1.51-.11-2.26.03l-2.19.11c-.49.02-1.19.33-1.19,1.43,0,.6.63,1.02,1.22,1.12.59.1,1.19-.02,1.79,0,.72.01,1.42.21,2.14.16.71-.05,1.49-.43,1.66-1.13.18-.74-.44-1.48-1.16-1.72Z"
                  style={{ fill: mainColor, strokeWidth: '0px' }}
                />
              </g>
              <g>
                <path
                  d="m548.41,129.92s12.38,36.31,19.38,35.27c4.43-.66,33.09-20.55,33.09-20.55l-5.06-18.69-25.89,16.52-14.09-20.49-7.43,7.94Z"
                  style={{ fill: '#ff9da4', strokeWidth: '0px' }}
                />
                <g>
                  <rect
                    x="549.03"
                    y="103.11"
                    width="6.96"
                    height="21.65"
                    transform="translate(-11.34 95.94) rotate(-9.82)"
                    style={{ fill: '#455a64', strokeWidth: '0px' }}
                  />
                  <g>
                    <path
                      d="m560.14,123.55s.56-4.51-.11-6c-.66-1.49-2.95-2.83-3.76-2.21-.81.63-1.12,1.9-1.12,1.9,0,0-5.99-3.65-7.44-2.31-1.45,1.35-1.78,3.02-1.78,3.02,0,0-2.74.48-2.14,3.14,0,0-1.66,1.45-1.14,3.95l.56,1.1s-1.6,2.91-.39,3.84,10.21,3.58,10.21,3.58l7.13-5.82-.04-4.2Z"
                      style={{ fill: '#ff9da4', strokeWidth: '0px' }}
                    />
                    <g>
                      <path
                        d="m549.13,103.54l-.19-1.64c-.21-1.85,1-3.53,2.82-3.92l11.56-2.48c2.32-.5,3.81-2.72,3.41-5.05l-.08-.46c-.35-1.98-1.99-3.48-4-3.63l-27.13-.36.03-2,27.19.36c2.99.22,5.38,2.39,5.88,5.28l.08.46c.59,3.4-1.58,6.63-4.96,7.35l-11.56,2.48c-.81.17-1.34.92-1.25,1.74l.27,1.5-2.06.37Z"
                        style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
                      />
                      <path
                        d="m549.13,103.54s.18-.05.51-.12c.36-.07.86-.17,1.54-.31l-.05.07c-.08-.41-.17-.89-.28-1.42-.11-.56.12-1.29.74-1.69.31-.21.69-.26,1.04-.35.36-.08.74-.17,1.13-.25.79-.17,1.63-.36,2.53-.56,1.8-.4,3.82-.84,6.03-1.33,1.11-.26,2.29-.42,3.3-1.08,1.03-.61,1.89-1.58,2.42-2.75.52-1.17.68-2.54.39-3.89-.21-1.36-.87-2.67-1.93-3.64-1.05-.98-2.49-1.58-4-1.62-1.56-.02-3.15-.04-4.77-.06-6.5-.08-13.49-.17-20.77-.26-.49,0-.98-.01-1.43-.02l.26-.26c0,.7-.02,1.37-.03,2l-.26-.26c8.22.12,16.01.23,23.08.33.88.01,1.75.03,2.61.04.43,0,.86.01,1.28.02.43.01.89.1,1.3.23,1.68.56,2.88,2.11,3.08,3.73.38,1.58-.14,3.3-1.22,4.35-.54.53-1.2.92-1.89,1.13-.7.19-1.35.3-2.01.44-2.59.54-4.94,1.03-7.01,1.47-2.02.49-3.99.52-4.91,1.75-.47.55-.71,1.16-.81,1.71-.09.55-.03,1.03,0,1.4.03.36.05.65.07.88.02.2.02.31.02.32,0,0-.02-.09-.05-.28-.03-.23-.08-.51-.13-.88-.06-.39-.14-.88-.06-1.46.08-.57.32-1.23.8-1.81.48-.58,1.23-1.08,2.14-1.27.88-.2,1.84-.41,2.86-.64,2.06-.45,4.4-.97,6.99-1.54.64-.15,1.33-.27,1.97-.45.64-.2,1.23-.56,1.72-1.05.98-.99,1.45-2.51,1.08-4.01-.2-1.5-1.29-2.87-2.81-3.37-.74-.27-1.59-.2-2.46-.22-.86,0-1.73-.02-2.61-.03-7.07-.09-14.85-.18-23.07-.28h-.26s0-.26,0-.26c0-.64.02-1.31.03-2v-.26s.26,0,.26,0c.47,0,.95.01,1.43.02,7.29.1,14.28.2,20.78.29,1.62.02,3.22.05,4.78.07,1.61.06,3.16.71,4.28,1.76,1.14,1.04,1.84,2.47,2.05,3.9.29,1.41.12,2.89-.44,4.13-.57,1.24-1.5,2.27-2.59,2.91-1.08.7-2.33.86-3.42,1.1-2.22.46-4.24.89-6.05,1.27-.9.19-1.75.36-2.53.53-.39.08-.77.16-1.14.23-.37.08-.71.12-.98.3-.55.34-.77.98-.68,1.49.09.54.17,1.01.23,1.43v.06s-.05,0-.05,0c-.69.11-1.21.19-1.59.24-.34.05-.49.07-.49.06Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                    </g>
                    <g>
                      <rect
                        x="522.74"
                        y="78.34"
                        width="39.19"
                        height="13.55"
                        transform="translate(-2.23 15.69) rotate(-1.65)"
                        style={{ fill: '#ff9da4', strokeWidth: '0px' }}
                      />
                      <path
                        d="m562.12,91.33s-.01-.09-.02-.25c-.01-.19-.02-.42-.04-.7-.03-.65-.07-1.55-.12-2.69-.09-2.35-.22-5.71-.38-9.9l.18.17c-9.55.3-23.57.73-39.17,1.22-.23.24.3-.33.25-.27h0s0,.01,0,.01h0s0,.04,0,.04v.06s0,.11,0,.11v.22s.02.44.02.44l.03.88c.02.58.03,1.17.05,1.75.03,1.16.07,2.3.1,3.44.06,2.26.12,4.47.18,6.61l-.26-.24c11.24-.28,20.99-.52,27.98-.69,3.47-.07,6.25-.13,8.2-.17.94-.01,1.67-.02,2.21-.03.23,0,.42,0,.58,0,.13,0,.2,0,.21,0,.01.01-.24.03-.72.05-.53.02-1.25.05-2.16.09-1.94.07-4.7.18-8.14.31-7.03.23-16.84.56-28.14.93h-.25s0-.24,0-.24c-.06-2.13-.13-4.34-.2-6.6-.03-1.13-.07-2.28-.1-3.44-.02-.58-.03-1.16-.05-1.75l-.03-.88v-.44s-.02-.22-.02-.22v-.11s0-.06,0-.06v-.03s0-.01,0-.01h0s0-.01,0-.01c-.06.06.48-.51.26-.27,15.61-.42,29.63-.79,39.18-1.04h.18s0,.17,0,.17c.08,4.23.15,7.63.19,10.01.01,1.12.03,2.01.03,2.65,0,.27,0,.5,0,.67,0,.15,0,.22-.01.22Z"
                        style={{ fill: '#263238', strokeWidth: '0px' }}
                      />
                    </g>
                  </g>
                  <path
                    d="m561.91,84.34c-.39.96-1.44,1.76-2.44,2.01s-2.12-.01-2.91-.68c-.77-.66-1.2-1.62-1.82-2.43-.62-.8-1.63-1.49-2.6-1.18-.71.23-1.17.92-1.45,1.62-.28.69-.46,1.44-.89,2.06-.55.78-1.51,1.26-2.46,1.23-.95-.03-1.88-.58-2.37-1.4-.38-.62-.53-1.41-1.08-1.88-.92-.78-2.42-.22-3.12.77s-.86,2.23-1.21,3.39c-.35,1.15-1.03,2.36-2.19,2.68-1.1.3-2.29-.33-2.94-1.27-.65-.93-.87-2.1-.98-3.23-.06-.64-.1-1.31-.4-1.88-.3-.57-.96-1.01-1.59-.83-.47.13-.77.56-1.12.9-1.02,1.02-2.57,1.13-4.01.97-2.14-.25-3.48-1.52-3.7-2.95l-.08-3.3,39.18-1.13.19,6.56Z"
                    style={{ fill: mainColor, strokeWidth: '0px' }}
                  />
                </g>
              </g>
              <g>
                <path
                  d="m598.21,213.88c-.52.81,1.05,70.35,1.31,77.33.26,6.98,14.52,61.42,14.52,61.42l23.92-1.61s-7.28-54.43-7.81-56.58c-.52-2.15-.79-7.52-.79-8.32s10.15-55.41,10.15-55.41c0,0,5.39-12.35,6.17-16.11.79-3.76-.43-25.49-.43-25.49l-44.42,5.99-2.62,18.8Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
                <path
                  d="m578.29,246.57c0-2.7.62-5.36,1.8-7.77,2.66-5.4,10.41-20.03,12.82-23.45,3.4-4.83,10.11-18.66,10.11-18.66l21.99,15.84-15.99,39.47,10.53,54.49-23.45,4.66-17.05-59.45c-.5-1.66-.76-3.39-.76-5.13h0Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
                <path
                  d="m595.63,218.55s.02.12.04.35c.01.26.03.6.05,1.03.04.94.09,2.27.15,3.96.12,3.47.28,8.44.48,14.57.2,6.16.45,13.48.71,21.61.15,4.07.31,8.33.47,12.76.2,4.41.32,9.02.74,13.66.85,4.58,1.97,9.05,2.97,13.36,1.04,4.3,2.04,8.46,2.99,12.41,1.93,7.9,3.67,15.01,5.14,21,1.46,5.96,2.64,10.78,3.46,14.16.39,1.65.7,2.94.92,3.85.09.42.17.75.23,1.01.05.23.07.34.06.35,0,0-.05-.11-.11-.33-.07-.25-.16-.58-.28-.99-.24-.91-.58-2.19-1.02-3.83-.86-3.37-2.1-8.18-3.62-14.12-1.5-5.98-3.27-13.09-5.25-20.98-.96-3.96-1.97-8.11-3.02-12.41-1-4.32-2.13-8.75-2.99-13.41-.42-4.71-.54-9.28-.73-13.71-.15-4.42-.3-8.69-.44-12.76-.23-8.13-.43-15.45-.6-21.61-.13-6.13-.24-11.1-.32-14.57-.02-1.7-.04-3.03-.06-3.96,0-.43,0-.77,0-1.03,0-.23,0-.35.02-.35Z"
                  style={{ fill: '#455a64', strokeWidth: '0px' }}
                />
                <path
                  d="m597.62,276.19c1.17,2.41,2.25,5.96,4.95,6.67,2.24.59,4.41-1.69,4.61-3.99.2-2.31-.92-4.5-2.02-6.54,2.74-.02,4.78-2.71,5.32-5.4.82-4.07-.84-8.33-3.46-11.56-2.61-3.23-6.79-5.38-10.23-7.72l.82,28.54Z"
                  style={{ fill: mainColor, strokeWidth: '0px' }}
                />
                <path
                  d="m615.56,292.53c-.42-1.32-2.05-2.63-3.29-3.23-.61-.33-1.81.04-2.31.52-.5.48-.77,1.15-.94,1.83-.27,1.08-.36,2.22-.05,3.29s1.05,2.04,2.07,2.46c1.28.53,2.86.08,3.77-.96.91-1.04,1.16-2.59.74-3.91Z"
                  style={{ fill: mainColor, strokeWidth: '0px' }}
                />
              </g>
              <g>
                <path
                  d="m632.75,117.5l-21.68-.63-11.06,2.76s-4.17,11.75-5.51,17.82l.55,13.4.8,33.84-1.07,22.38c-.78,1.36-.64,1.87-.11,3.43,12.43,5.36,35.86,6.38,52.17-.4l1.01-60-1.19-28.19-13.9-4.42Z"
                  style={{ fill: '#be1622', strokeWidth: '0px' }}
                />
                <path
                  d="m660.31,175.01c5.78,6.11,10.59,1.58,11.84-.9s-.23-8.27-.23-8.27l-7.83-14.98-14.28,8,10.5,16.15Z"
                  style={{ fill: '#ff9da4', strokeWidth: '0px' }}
                />
                <path
                  d="m646.65,121.9s6.83,2.73,9.71,10.19c.67,1.74,11.01,22.41,11.01,22.41l-11,7.85-4.09,2.92-5.63-9.73v-11.82s0-21.82,0-21.82Z"
                  style={{ fill: '#455a64', strokeWidth: '0px' }}
                />
                <path
                  d="m648.23,135.27s.07.46.09,1.29c.02.83.04,2.03.03,3.51-.01,2.96-.11,7.05-.25,11.56-.16,4.46-.3,8.49-.41,11.55-.05,1.4-.1,2.57-.14,3.5-.04.83-.08,1.28-.11,1.28-.04,0-.07-.46-.09-1.29-.02-.83-.04-2.03-.03-3.51.01-2.96.11-7.05.25-11.56.16-4.46.3-8.49.41-11.55.05-1.4.1-2.57.14-3.5.04-.83.08-1.28.11-1.28Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
                <path
                  d="m573.67,139.48l18.77-16.2s9.81-8.52,14.93.98c4.61,8.56-.84,18.56-2.83,23.02l-18.28,14.06-12.59-21.86Z"
                  style={{ fill: '#455a64', strokeWidth: '0px' }}
                />
                <path
                  d="m604.61,148.26c-.1-.07,1.37-1.61,2.71-4.69,1.37-3.02,2.65-7.62,2.2-12.71-.22-2.53-1.02-4.85-2.26-6.67-1.17-1.86-2.98-2.86-4.53-3.42-1.56-.56-2.94-.58-3.86-.49-.93.1-1.43.26-1.44.22,0-.02.11-.07.35-.16.24-.08.59-.18,1.06-.26.93-.15,2.36-.19,4,.35.82.26,1.68.66,2.52,1.21.87.54,1.64,1.35,2.28,2.29,1.3,1.85,2.16,4.3,2.38,6.89.46,5.21-.91,9.88-2.38,12.91-.34.78-.76,1.43-1.09,2.01-.37.56-.7,1.04-.98,1.42-.61.73-.95,1.12-.98,1.1Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
                <path
                  d="m632.58,183.57c1.82,1.75,4.61,2.3,6.23,4.23,1.01,1.2,1.42,2.78,1.81,4.3.39,1.52.82,3.1,1.84,4.28,1.03,1.19,3.25,2.12,4.61,1.35l.37-24.01c-2.29-1.72-6.41-2.97-9.26-2.66-2.84.31-5.57,2.01-6.83,4.58-1.26,2.57-.84,5.95,1.23,7.93Z"
                  style={{ fill: mainColor, strokeWidth: '0px' }}
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Character_1" data-name="Character 1">
          <g>
            <path
              d="m159.9,209.55l-.91-1.66s-5.74,7.41-6.36,12.11c-.51,3.88-3.32,24.91-4.74,34.81-.58,4.04.4,8.23,3,11.38,2.47,2.99,6.21,5.1,11.27,1.56,3.47-2.44,12.37-12.38,12.37-12.38l-14.62-45.81Z"
              style={{ fill: '#ff9da4', strokeWidth: '0px' }}
            />
            <g>
              <path
                d="m174.83,144.45c2.83-.88,6.01-.55,8.6.89,1.44-1.85,3.92-2.84,6.24-2.49,2.32.36,4.39,2.04,5.22,4.24,2.47-1,5.59.06,6.94,2.36,1.35,2.29.77,5.54-1.3,7.22,2.02.84,3.61,2.64,4.19,4.75.58,2.11.13,4.47-1.17,6.23,2.16,1.05,2.53,3.98,2.21,6.36-.32,2.38-1,4.93.06,7.09.82,1.66,2.53,2.69,3.69,4.14s1.57,3.94,0,4.92c2.68.36,4.76,3.22,4.27,5.88-.49,2.66-3.45,4.6-6.08,3.98.88,1.87.5,4.18-.65,5.9-1.15,1.72-2.99,2.89-4.94,3.6-5.6,2.03-12.4.11-16.11-4.54l-17.76-54.92c1.33-2.65,3.77-4.71,6.6-5.59Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m166.39,159.66l-.24-2.96,17.61-4.46c5.46-.91,11.73,4.24,12.41,9.73.75,6.1,1.43,13.58.98,18.2-.91,9.28-7.88,10.87-7.88,10.87l.51,9.13-20.31,1.48-3.09-41.98Z"
                style={{ fill: '#ff9da4', strokeWidth: '0px' }}
              />
              <path
                d="m194.02,170.72c.06.62-.42,1.18-1.07,1.26-.65.07-1.22-.37-1.28-.99-.06-.62.42-1.18,1.06-1.26.65-.07,1.22.37,1.28.99Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m194.46,165.99c-.14.16-1.07-.44-2.34-.36-1.27.06-2.16.77-2.31.62-.07-.06.06-.35.45-.67.38-.32,1.04-.64,1.84-.69.79-.04,1.47.21,1.87.48.41.28.56.55.49.62Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m182.9,171.41c.06.62-.42,1.18-1.06,1.26-.65.07-1.22-.37-1.28-.99-.06-.62.42-1.18,1.06-1.26.65-.07,1.22.37,1.28.99Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m182.01,166.97c-.14.16-1.07-.44-2.34-.36-1.27.06-2.16.77-2.31.62-.07-.06.06-.35.45-.67.38-.32,1.04-.64,1.83-.69.79-.04,1.47.21,1.87.48.41.28.56.55.49.62Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m187.18,176.1c-.01-.07.77-.26,2.04-.5.32-.05.62-.14.67-.36.06-.24-.09-.58-.27-.95-.35-.77-.72-1.57-1.11-2.41-1.54-3.43-2.68-6.26-2.54-6.32.14-.06,1.5,2.67,3.04,6.1.37.85.73,1.65,1.07,2.43.14.36.38.77.24,1.26-.07.25-.3.44-.5.51-.2.08-.39.09-.55.11-1.28.15-2.08.2-2.09.13Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m189.27,191.06s-6.47.77-12.82-2.54c0,0,3.49,6.25,12.95,4.83l-.13-2.29Z"
                style={{ fill: '#ff6c76', strokeWidth: '0px' }}
              />
              <path
                d="m181.72,162.6c-.13.35-1.39.18-2.88.36-1.49.15-2.7.59-2.89.28-.09-.15.12-.47.61-.81.48-.33,1.25-.65,2.15-.75.89-.1,1.71.04,2.26.27.55.22.82.49.77.66Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m194.16,162.85c-.2.3-1.1.06-2.15.12-1.05.02-1.93.33-2.15.04-.1-.14.02-.44.39-.74.36-.3.99-.56,1.71-.59.72-.03,1.37.19,1.75.46.39.27.53.56.45.71Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m186.26,179.18c-.42-.61-1.18-.98-1.97-.93-.54.03-1.1.26-1.45.69-.35.42-.45,1.04-.17,1.48.31.49,1.01.63,1.61.49.6-.14,1.13-.5,1.64-.87.14-.1.28-.21.37-.35.09-.14.12-.34.02-.46"
                style={{ fill: '#ff6c76', strokeWidth: '0px' }}
              />
              <path
                d="m183.23,176.89c.2,0,.14,1.35,1.25,2.36,1.11,1.02,2.55.94,2.55,1.13.01.09-.33.25-.94.23-.6,0-1.46-.24-2.13-.87-.68-.62-.94-1.42-.96-1.98-.02-.57.14-.89.24-.88Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m180.52,174.1c-.31-.41-.87-.58-1.38-.54s-1,.24-1.47.44c-.23.08-.91.61-1.42,1.07-.51.46-.79,1.25-.46,1.84.27.49.88.72,1.44.68.56-.03,1.09-.27,1.59-.53.59-.29,1.18-.62,1.57-1.15.39-.53.52-1.3.12-1.83Z"
                style={{ fill: mainColor, strokeWidth: '0px' }}
              />
              <path
                d="m176.2,171.08c0,.5-.41.9-.9.9s-.9-.41-.9-.9.41-.9.9-.9.9.41.9.9Z"
                style={{ fill: mainColor, strokeWidth: '0px' }}
              />
            </g>
            <polygon
              points="215.28 268.42 209.4 238.09 200.26 228.6 210.17 270.32 215.28 268.42"
              style={{ fill: '#263238', strokeWidth: '0px' }}
            />
            <g>
              <path
                d="m211.47,418.97l-1.67,17.44s19.01,7.1,19.22,10.61l-35.94.1.02-2.63.5-9.98,3.22-16.41,14.65.87Z"
                style={{ fill: '#455a64', strokeWidth: '0px' }}
              />
              <path
                d="m193.07,447.12l.02-2.63,34.47.66s1.64.72,1.45,1.87l-35.94.1Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m210.39,436.22c0,.18-.9.26-1.79.83-.9.55-1.36,1.33-1.52,1.26-.17-.04.1-1.11,1.19-1.79,1.08-.69,2.16-.47,2.12-.31Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m214.24,437.83c.04.17-.74.48-1.35,1.24-.63.74-.79,1.56-.97,1.55-.17.03-.29-1.02.49-1.95.77-.93,1.83-1.01,1.83-.84Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m216.42,442.45c-.16.04-.41-.86.05-1.84.46-.99,1.3-1.37,1.38-1.22.1.15-.45.67-.81,1.49-.39.8-.44,1.56-.62,1.58Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m211.66,432.03c-.08.16-.9-.09-1.91-.02-1.02.05-1.81.38-1.9.23-.1-.13.67-.79,1.87-.85,1.2-.07,2.04.5,1.95.64Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
            </g>
            <g>
              <path
                d="m167.3,419.46l-1.54,17.14s18.72,6.86,18.94,10.31l-35.3.31v-2.58s.92-9.58.92-9.58l2.58-16.38,14.4.77Z"
                style={{ fill: '#455a64', strokeWidth: '0px' }}
              />
              <path
                d="m149.4,447.23v-2.58s33.87.44,33.87.44c0,0,1.61.69,1.43,1.83l-35.3.31Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m166.35,436.42c0,.18-.89.26-1.75.83-.88.55-1.33,1.31-1.49,1.24-.16-.03.1-1.09,1.16-1.76,1.05-.68,2.12-.47,2.08-.31Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m170.15,437.97c.04.17-.72.47-1.32,1.22-.62.73-.77,1.54-.94,1.53-.17.03-.29-1,.47-1.92.75-.92,1.79-1,1.79-.83Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m172.31,442.5c-.16.04-.41-.84.04-1.81.44-.97,1.27-1.36,1.35-1.21.1.14-.43.67-.79,1.47-.38.79-.42,1.54-.6,1.55Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m167.58,432.29c-.07.16-.88-.08-1.88,0-1,.05-1.77.39-1.87.24-.1-.13.65-.78,1.83-.85,1.17-.08,2,.48,1.92.62Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
            </g>
            <path
              d="m169.89,260c-.01.05.82.33,2.22.64,1.39.3,3.35.6,5.52.68,2.18.08,4.15-.06,5.56-.26,1.41-.19,2.27-.41,2.26-.46-.01-.06-.88.05-2.29.16-1.41.12-3.36.21-5.51.13-2.15-.08-4.09-.33-5.48-.55-1.4-.22-2.25-.4-2.27-.34Z"
              style={{ fill: '#263238', strokeWidth: '0px' }}
            />
            <path
              d="m167.23,270.32s.12-.28.27-.81c.16-.61.37-1.38.63-2.32.53-2.09,1.26-4.94,2.12-8.33l.02-.06-.02-.06c-1.05-3.39-2.4-7.38-3.93-11.6-2.27-6.26-4.47-11.87-6.08-15.92-.8-1.96-1.45-3.58-1.94-4.79-.22-.51-.4-.94-.55-1.29-.13-.29-.21-.44-.23-.44-.02,0,.03.17.13.48.13.36.28.8.46,1.33.45,1.22,1.05,2.86,1.78,4.85,1.52,4.09,3.66,9.72,5.93,15.97,1.53,4.22,2.9,8.19,4,11.56v-.12c-.79,3.41-1.45,6.27-1.94,8.37-.21.96-.37,1.74-.51,2.35-.11.54-.16.84-.13.84Z"
              style={{ fill: '#263238', strokeWidth: '0px' }}
            />
            <path
              d="m161.33,254.67c.13.06,1.19-2.06,2.37-4.75,1.18-2.69,2.03-4.9,1.89-4.96-.14-.05-1.2,2.07-2.37,4.75-1.18,2.67-2.02,4.89-1.89,4.96Z"
              style={{ fill: '#263238', strokeWidth: '0px' }}
            />
            <path
              d="m161.87,257.62l-17.32-5.47s5.01-28.11,6.64-31.52c1.63-3.41,3.55-5.75,3.55-5.75l7.54,25.9-.41,16.85Z"
              style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
            />
            <g>
              <path
                d="m156.16,202.09l35.31-6.12s9.8,17.26,13.47,35.4c3.67,18.14,5.1,31.09,5.1,31.09l-28.68,4.72c-3.4.42-8.03,4.84-11.41,4.33l-3.4-.51,2.79-11.52c-4.66-11.64-8.06-21.59-10.08-29.8-4.38-17.82-3.11-27.59-3.11-27.59Z"
                style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
              />
              <g>
                <g>
                  <path
                    d="m213.85,262.46s6.91,29.51,7.2,47.86c.29,18.35.59,47.54-3.23,79.73s-5.58,45.13-5.58,45.13h-19.39s-2.06-20.16-1.76-38.21c.29-18.05,1.7-23.77-.03-43.62-1.73-19.86-4.48-31.78-7.37-37.8,0,0-8-9.51-8.59-22.56-.59-13.05,2.56-30.52,2.56-30.52h36.18Z"
                    style={{ fill: '#be1622', strokeWidth: '0px' }}
                  />
                  <path
                    d="m195.02,301.91c-.32.99-6.08,41.44-10.27,59.28-4.2,17.84-8.87,22.56-9.68,37.07-.94,16.69-5.52,36.92-5.52,36.92h-20.64s2.9-30.85,5.81-54.97c2.9-24.12,7.1-53.85,6.45-62.77-.64-8.92-2.26-19.94,0-32.43,2.26-12.5,7.14-15.79,7.14-15.79l17.51-.79,9.21,33.49Z"
                    style={{ fill: '#be1622', strokeWidth: '0px' }}
                  />
                  <path
                    d="m182.09,313.62s.2.31.46.92c.27.6.64,1.48,1.08,2.57.88,2.18,2.09,5.21,3.16,8.65,1.07,3.44,1.8,6.62,2.32,8.91.25,1.15.45,2.08.57,2.73.12.65.17,1.01.14,1.02s-.15-.34-.34-.97c-.2-.75-.45-1.64-.73-2.69-.61-2.27-1.39-5.43-2.46-8.84-1.07-3.42-2.22-6.45-3.01-8.67-.36-1.02-.67-1.9-.93-2.63-.21-.62-.31-.98-.28-.99Z"
                    style={{ fill: '#455a64', strokeWidth: '0px' }}
                  />
                  <path
                    d="m205.56,292.37c-.06-.07.79-.85,1.96-2.28,1.17-1.43,2.62-3.55,3.76-6.13,1.12-2.58,1.7-5.09,1.95-6.92.25-1.83.25-2.99.34-2.99.04,0,.07.29.09.81.03.52.01,1.28-.07,2.22-.16,1.87-.69,4.45-1.83,7.09-1.16,2.64-2.69,4.77-3.95,6.16-.63.69-1.18,1.23-1.58,1.56-.4.34-.63.51-.66.49Z"
                    style={{ fill: '#455a64', strokeWidth: '0px' }}
                  />
                  <path
                    d="m214.76,347.06c-2,.41-3.5,2.15-4.27,4.04-.77,1.89-.95,3.96-1.3,5.97-.34,2.01-.88,4.08-2.22,5.63-1.17,1.36-2.82,2.18-4.24,3.28-1.42,1.1-2.68,2.71-2.5,4.49.27,2.66,3.46,4.03,4.44,6.52,1.33,3.37-1.87,7.22-.74,10.66,1.06,3.22,5.57,4.12,8.58,2.55,3.01-1.58,4.79-4.75,6.24-7.82.87-9.12,1.52-19.79,2.03-31.38.11-3.33-4.03-4.36-6.03-3.95Z"
                    style={{ fill: mainColor, strokeWidth: '0px' }}
                  />
                  <path
                    d="m212.33,329.97c-1.07-2.02-3.92-5.29-7.65-3.11-1.01.56-2.17,1.9-1.53,4.33.21.81.71,1.51,1.28,2.12.57.62,1.18,1.25,1.41,2.05.39,1.33-.32,2.86.26,4.12.65,1.39,2.66,1.67,3.98.89,1.32-.78,2.05-2.27,2.49-3.73.66-2.19.82-4.65-.25-6.67Z"
                    style={{ fill: mainColor, strokeWidth: '0px' }}
                  />
                </g>
                <g>
                  <polygon
                    points="163.88 275.89 159.87 239.47 159.19 235.66 188.15 233.62 199.27 271.93 185.22 281.61 163.88 275.89"
                    style={{ fill: '#be1622', strokeWidth: '0px' }}
                  />
                  <path
                    d="m181.73,235.66s-3.71-32.11.71-37.63c4.42-5.52,7.92-2.47,7.92-2.47,0,0-3.32-.42-3.32,4.8,0,5.22,1.62,34.97,1.62,34.97l-6.92.33Z"
                    style={{ fill: '#be1622', strokeWidth: '0px' }}
                  />
                  <polygon
                    points="159.35 236.56 153.66 215.71 156.61 211 163.46 238.09 159.35 236.56"
                    style={{ fill: '#be1622', strokeWidth: '0px' }}
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                d="m240.05,208.02c-.11.32-13.84,20.71-13.84,20.71,0,0-12.81-14.43-16.71-19.73l-6.67,23.21s13.2,14.3,19.05,16.68c5.85,2.38,10.39-1.27,12.88-3.87,2.49-2.6,13.12-29.15,13.12-29.15l-7.84-7.87Z"
                style={{ fill: '#ff9da4', strokeWidth: '0px' }}
              />
              <path
                d="m188.15,196.55s5.52-3.4,11.12.2c5.6,3.6,27.44,27.8,27.44,27.8l-13.77,19.09-21.25-22.31-1.34-13.43-2.2-11.35Z"
                style={{ fill: '#e0e0e0', strokeWidth: '0px' }}
              />
              <path
                d="m226.55,238.09c.12,0,.19-2.01.15-4.47-.04-2.47-.16-4.47-.28-4.47-.12,0-.19,2-.15,4.47.04,2.47.16,4.47.28,4.47Z"
                style={{ fill: '#ff6c76', strokeWidth: '0px' }}
              />
              <rect
                x="239.87"
                y="190.2"
                width="6.31"
                height="20.24"
                transform="translate(-17.87 23.91) rotate(-5.43)"
                style={{ fill: '#455a64', strokeWidth: '0px' }}
              />
              <path
                d="m236.94,210.46s-1.09-3.89-.65-5.4c.44-1.51,2.77-2.86,3.54-3.33.22-.14,1,1.76,1,1.76,0,0,5.16-4.86,6.69-4.04,1.53.82,2.05,2.24,2.05,2.24,0,0,2.63-.29,2.4,2.25,0,0,1.73.86,1.57,3.23l-.39,1.13s1.86,2.18.84,3.34c-1.02,1.16-9.14,5.91-9.14,5.91l-7.42-3.32-.49-3.77Z"
                style={{ fill: '#ff9da4', strokeWidth: '0px' }}
              />
              <path
                d="m241.67,218.5c-.74-2.23-3.18-3.86-4.94-5.41l-7.64,11.37c-.25,1.67,1.75,3.23,3.37,2.79s2.5-2.53,1.8-4.07c1.57.85,3.36,1.72,5.06,1.18,2.23-.71,3.08-3.64,2.34-5.86Z"
                style={{ fill: mainColor, strokeWidth: '0px' }}
              />
              <polygon
                points="231.53 186.16 230.48 172.94 241.48 172.3 242.19 174.93 242.35 172.28 244.65 172.14 245.27 174.44 246.04 172.27 249.63 171.88 250.54 185 231.53 186.16"
                style={{ fill: '#ff9da4', strokeWidth: '0px' }}
              />
              <path
                d="m249.5,192.13l-15.49,1.25c-.96.08-1.81-.56-1.9-1.42l-.59-5.81,18.98-1.54.59,5.81c.09.86-.62,1.63-1.59,1.7Z"
                style={{ fill: '#455a64', strokeWidth: '0px' }}
              />
              <path
                d="m249.67,176.26c.13.87-.69,1.76-1.67,1.81-.79.04-1.56-.38-2.33-.22-.75.16-1.22.82-1.44,1.48-.22.66-.29,1.36-.61,1.98-.32.62-1.03,1.17-1.79,1.05-.7-.11-1.14-.73-1.45-1.3-.31-.57-.65-1.21-1.3-1.45-.63-.24-1.37-.03-1.96.3-.59.32-1.1.75-1.73,1-.79.31-1.73.29-2.51-.04-.78-.33-1.38-.98-1.61-1.72l-.31-.15.57,7.17,19.01-1.16-.67-9.61-.2.87Z"
                style={{ fill: 'none', strokeWidth: '0px' }}
              />
              <path
                d="m232.88,180.86c.78.33,1.72.35,2.51.04.63-.25,1.15-.68,1.73-1,.59-.32,1.32-.54,1.96-.3.65.25.99.88,1.3,1.45.31.57.75,1.18,1.45,1.3.75.12,1.46-.43,1.79-1.05.32-.62.38-1.33.61-1.98.22-.66.7-1.32,1.44-1.48.77-.16,1.55.26,2.33.22.98-.05,1.8-.94,1.67-1.81l.2-.87-.24-3.5-3.59.38-.78,2.17-.62-2.3-2.29.15-.16,2.65-.71-2.63-11,.64.48,6.05.31.15c.23.74.83,1.39,1.61,1.72Z"
                style={{ fill: mainColor, strokeWidth: '0px' }}
              />
              <g>
                <g style={{ opacity: 0.3 }}>
                  <path
                    d="m206.32,236.68c-3.63-.54-7.12-1.34-9.54-4.1-2.41-2.77-3.8-6.41-3.84-10.08,4.88,4.75,9.18,9.89,13.38,14.18Z"
                    style={{ strokeWidth: '0px' }}
                  />
                </g>
                <path
                  d="m226.71,224.54s-.06.15-.23.4c-.2.3-.44.66-.73,1.11-.69,1.01-1.63,2.38-2.78,4.07-2.44,3.49-5.77,8.25-9.54,13.66l-.18.25-.22-.22c-.98-1.02-2.01-2.08-3.06-3.17-5.28-5.52-10.06-10.51-13.58-14.19-1.68-1.79-3.06-3.26-4.07-4.34-.44-.49-.8-.88-1.09-1.2-.24-.28-.37-.43-.35-.44.01-.01.16.12.43.37.31.3.69.67,1.16,1.13,1.04,1.05,2.46,2.48,4.19,4.23,3.55,3.65,8.37,8.61,13.69,14.08,1.05,1.09,2.07,2.15,3.05,3.17l-.39.03c3.83-5.36,7.21-10.09,9.68-13.56,1.2-1.65,2.18-2.99,2.91-3.98.32-.42.59-.77.8-1.06.19-.24.29-.36.3-.35Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
              </g>
            </g>
            <g style={{ opacity: 0.3 }}>
              <path
                d="m157.3,206.1l22.07-1.97c.8,1.86-.38,4.29-2.34,4.81,1.18,2.08,1.22,4.77.1,6.89-1.13,2.11-3.38,3.58-5.76,3.77-.59.05-1.2.02-1.76.22-1.22.44-1.81,1.78-2.54,2.86-.97,1.41-2.43,2.53-4.11,2.85-1.68.33-3.56-.22-4.64-1.54-1.08-1.32-1.26-3.13-1.35-4.83-.22-4.35-.1-8.72.34-13.05Z"
                style={{ strokeWidth: '0px' }}
              />
            </g>
            <g>
              <path
                d="m167.02,136.48c-2.09-2.55-5.43-2.49-7.54-1.74s-3.69,2.79-3.89,5.02c-2.91-1.14-6.58.28-7.95,3.08-1.38,2.81-.25,6.58,2.43,8.18-.41,2.26.88,4.73,2.98,5.67,2.1.94,4.8.28,6.22-1.53l8.97-10.18c.8-2.09,1.53-5.14-1.22-8.49Z"
                style={{ fill: '#263238', strokeWidth: '0px' }}
              />
              <path
                d="m170.06,144.86c-.63-.58-1.57-.72-2.41-.58-.84.15-1.62.55-2.38.94-.36.19-.73.38-.98.69-.25.31-.36.78-.15,1.12-1.11-.6-2.61-.31-3.43.65-.81.96-.84,2.5-.06,3.49.63.81,1.74,1.17,2.74.98l-1.17.37c2.48-.02,4.93-.96,6.79-2.6.73-.64,1.39-1.42,1.67-2.36.28-.93.11-2.05-.61-2.71Z"
                style={{ fill: '#ff5e69', strokeWidth: '0px' }}
              />
              <path
                d="m195.77,157.94s.1.06.31.12c.21.05.54.11.96.07.82-.07,2.09-.61,2.85-1.96.39-.66.54-1.54.39-2.43-.15-.89-.6-1.78-1.29-2.51-.68-.74-1.64-1.31-2.7-1.55-1.06-.26-2.22-.23-3.34.03-.65.16-1.27.38-1.83.65l.29.11c-.79-1.94-2.34-3.35-3.94-4.06-1.62-.74-3.28-.75-4.55-.47-1.28.28-2.22.81-2.79,1.23-.29.21-.5.4-.63.53-.13.13-.2.21-.19.22.02.03.31-.24.91-.62.59-.37,1.52-.85,2.76-1.08,1.23-.23,2.81-.19,4.33.54,1.51.7,2.97,2.05,3.71,3.88l.08.21.2-.1c.54-.25,1.13-.47,1.75-.62,1.06-.25,2.15-.28,3.14-.04,1,.22,1.87.74,2.51,1.42.65.67,1.08,1.49,1.23,2.3.15.81.03,1.61-.31,2.22-.67,1.27-1.84,1.82-2.6,1.94-.8.13-1.24-.08-1.25-.03Z"
                style={{ fill: '#455a64', strokeWidth: '0px' }}
              />
              <g>
                <path
                  d="m151.25,171.12s-.16-.39-.36-1.11c-.2-.72-.38-1.81-.17-3.14.2-1.32.85-2.88,2.21-4.06,1.32-1.18,3.31-1.97,5.39-1.76l.32.03-.09-.31c-.18-.6-.29-1.26-.32-1.93-.11-2.33.73-4.55,2.04-6.07,1.3-1.56,3.05-2.36,4.53-2.71,1.5-.35,2.77-.26,3.62-.11.85.15,1.3.33,1.32.29,0-.01-.1-.07-.32-.15-.21-.09-.54-.2-.97-.3-.85-.2-2.16-.35-3.72-.02-1.54.32-3.37,1.13-4.76,2.75-1.39,1.58-2.29,3.9-2.17,6.35.04.71.16,1.4.34,2.04l.23-.27c-2.22-.22-4.31.64-5.69,1.9-1.42,1.27-2.06,2.93-2.24,4.3-.18,1.39.05,2.51.3,3.23.12.36.25.63.34.81.1.18.15.26.16.26Z"
                  style={{ fill: '#455a64', strokeWidth: '0px' }}
                />
                <path
                  d="m196.14,155.36c-2.07-2.71-5.08-4.7-8.39-5.54l-14.88,1.87-1.27-.02c-.28-.94-1.86-1.43-1.86-1.43-1.4-1.07-6.24-1.13-9.62,2.4-1.22,1.27-2.08,4.47-2.11,6.24-.01.67.7,1.58.15,1.95-.19.13-1.8.09-2.03.14-3.78.83-6.15,4.18-5.46,9.2.03.25.12.51.08.76-.07.4-.39.69-.66.99-1.8,1.91-2.24,4.97-1.04,7.31.37.72.88,1.39,1.01,2.19.23,1.43-.82,2.71-1.63,3.91-2.43,3.6-2.95,8.84-.1,12.12.41.47.89.91,1.26,1.41-.58,1.87-.94,3.78-.88,5.74.12,4.24,2.71,8.71,6.85,9.62-1.03,1.97-.06,4.62,1.86,5.75,1.92,1.13,4.47.84,6.31-.41,1.84-1.25,3.01-3.32,3.52-5.49,2.04,1,4.68.63,6.37-.89,1.69-1.52,2.33-4.1,1.56-6.24,1.64.63,3.61-.41,4.38-1.99.77-1.58.54-3.51-.2-5.11-.7-1.51-1.81-2.79-2.92-4.03.99-1.25,1.24-3.15.93-4.78-.4-2.13-2.11-3.25-2.61-5.01-.22-.77.41-4.7-.45-6.19-.68-1.18-1.87-2.02-2.45-3.25-.85-1.8-.2-3.9.17-5.86.69-3.7.25-7.59-1.22-11.05-.06-.51-.11-.93-.1-1.11.04-2.25,2.83-1.05,4.97-.33.52.17,1.06.27,1.6.29,1.76.05,3.52-.79,4.6-2.18,2.02,2.15,5.28,3.04,8.11,2.22,1.15,1.6,2.32,3.23,3.85,4.47,1.53,1.25,3.49,2.09,5.45,1.87.06-3.41-1.06-6.84-3.14-9.55Z"
                  style={{ fill: '#263238', strokeWidth: '0px' }}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ThemeVisualizer.propTypes = { ...withDefaultProps() };

export default ThemeVisualizer;
