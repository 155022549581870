import entities from 'constants/entities';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { entityActions } from 'redux/entities/actions';
import { isEmpty, omit } from 'lodash-es';
import EntityService from 'api/services/EntityService';
import { toastr } from 'utils';
import i18n from 'i18n';
import { UPDATE_SELECTED_LOCATION, GET_LOCATION_GROUP } from './constants';
import { locationBodySelector, locationSelector } from './selectors';
import { setSavingFlag, getLocationGroupSuccess } from './actions';

function* tryUpdate(location, tryNumber = 0) {
  try {
    const updated = yield call(
      [EntityService, 'update'],
      entities.location,
      location.uid,
      location,
    );

    yield put(entityActions.updateEntitySuccess(entities.location, updated));
    toastr.success(`${i18n.t('toastr:successUpdate')} location page`);
  } catch (e) {
    if (tryNumber < 1) {
      yield tryUpdate(location, tryNumber + 1);
    } else {
      toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
      yield put(setSavingFlag(false));
    }
  }
}

function* updateLocation() {
  const location = yield select(locationSelector);
  const body = yield select(locationBodySelector);
  const updatedLocation = {
    ...omit(location, 'vanity_url'),
    page: body
      .filter(component => !isEmpty(component.properties))
      .map(component => omit(component, 'isNew')),
  };
  yield tryUpdate(updatedLocation);
}

function* getLocationGroup() {
  const response = yield call([EntityService, 'fetch'], entities.owner_group_page);

  yield put(getLocationGroupSuccess(response));
}

export const locationSagas = [
  takeLatest(UPDATE_SELECTED_LOCATION, updateLocation),
  takeLatest(GET_LOCATION_GROUP, getLocationGroup),
];
