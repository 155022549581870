import {
  RAMBLA_FETCH_BRAND,
  RAMBLA_FETCH_BRAND_SUCCESS,
  RAMBLA_CREATE_BRAND,
  RAMBLA_CREATE_BRAND_SUCCESS,
  RAMBLA_UPDATE_BRAND,
  RAMBLA_UPDATE_BRAND_SUCCESS,
  RAMBLA_UPDATE_THEME,
  RAMBLA_UPDATE_THEME_SUCCESS,
  RAMBLA_UPDATE_BRAND_CONFIGURATION,
  RAMBLA_UPDATE_BRAND_CONFIGURATION_SUCCESS,
} from './constants';

export const fetchBrand = uid => ({
  type: RAMBLA_FETCH_BRAND,
  payload: { uid },
});

export const createBrand = (name, domain) => ({
  type: RAMBLA_CREATE_BRAND,
  payload: { name, domain },
});

export const fetchBrandSuccess = brand => ({
  type: RAMBLA_FETCH_BRAND_SUCCESS,
  payload: brand,
});

export const createBrandSuccess = brand => ({
  type: RAMBLA_CREATE_BRAND_SUCCESS,
  payload: brand,
});

export const updateBrand = (uid, name, domain, is_onboarding_process_finished, no_redirect) => ({
  type: RAMBLA_UPDATE_BRAND,
  payload: { uid, name, domain, is_onboarding_process_finished, no_redirect },
});

export const updateBrandSuccess = brand => ({
  type: RAMBLA_UPDATE_BRAND_SUCCESS,
  payload: brand,
});

export const updateBrandConfiguration = ({ uid, configuration, nextStep }) => ({
  type: RAMBLA_UPDATE_BRAND_CONFIGURATION,
  payload: {
    uid,
    configuration,
    next_step: nextStep,
  },
});

export const updateBrandConfigurationSuccess = configuration => ({
  type: RAMBLA_UPDATE_BRAND_CONFIGURATION_SUCCESS,
  payload: configuration,
});

export const updateTheme = ({ uid, color, picture_id, font_family, is_onboarding }) => ({
  type: RAMBLA_UPDATE_THEME,
  payload: { uid, color, picture_id, font_family, is_onboarding },
});

export const updateThemeSuccess = theme => ({
  type: RAMBLA_UPDATE_THEME_SUCCESS,
  payload: theme,
});
