import { toastr } from 'utils';
import { upperFirst } from 'lodash';
import i18n from 'i18n';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  RAMBLA_CREATE_PARENT_PRODUCT,
  RAMBLA_DELETE_PARENT_PRODUCT,
  RAMBLA_FETCH_PARENT_PRODUCTS,
  RAMBLA_UPDATE_PARENT_PRODUCT,
  RAMBLA_CREATE_CHILD_PRODUCT,
  RAMBLA_DELETE_CHILD_PRODUCT,
  RAMBLA_FETCH_CHILD_PRODUCTS,
  RAMBLA_UPDATE_CHILD_PRODUCT,
  RAMBLA_FETCH_COLORS,
  RAMBLA_CREATE_COLOR,
  RAMBLA_UPDATE_COLOR,
  RAMBLA_DELETE_COLOR,
  RAMBLA_GENERATE_COLORS,
  RAMBLA_FETCH_SIZES,
  RAMBLA_CREATE_SIZE,
  RAMBLA_UPDATE_SIZE,
  RAMBLA_DELETE_SIZE,
  RAMBLA_GENERATE_SIZES,
  RAMBLA_FETCH_CATEGORIES,
  RAMBLA_CREATE_CATEGORY,
  RAMBLA_UPDATE_CATEGORY,
  RAMBLA_DELETE_CATEGORY,
  RAMBLA_GENERATE_CATEGORIES,
  RAMBLA_FETCH_INVENTORIES,
  RAMBLA_CREATE_INVENTORY,
  RAMBLA_UPDATE_INVENTORY,
  RAMBLA_DELETE_INVENTORY,
  RAMBLA_GENERATE_INVENTORIES,
} from './constants';
import ProductService from '../../api/services/ProductService';
import {
  fetchParentProductsSuccess,
  createParentProductSuccess,
  updateParentProductSuccess,
  deleteParentProductSuccess,
  fetchChildProductsSuccess,
  createChildProductSuccess,
  updateChildProductSuccess,
  deleteChildProductSuccess,
  fetchColorsSuccess,
  createColorSuccess,
  updateColorSuccess,
  deleteColorSuccess,
  generateColorsSuccess,
  fetchSizesSuccess,
  createSizeSuccess,
  updateSizeSuccess,
  deleteSizeSuccess,
  generateSizesSuccess,
  fetchCategoriesSuccess,
  createCategorySuccess,
  updateCategorySuccess,
  deleteCategorySuccess,
  generateCategoriesSuccess,
  fetchInventoriesSuccess,
  createInventorySuccess,
  updateInventorySuccess,
  deleteInventorySuccess,
  generateInventoriesSuccess,
} from './actions';

// TODO: to be extracted to generic saga
function createError(error) {
  Object.values(error.response.data).forEach(message =>
    toastr.error(i18n.t('toastr:error'), upperFirst(message)),
  );
}

// parent products
function* fetchParentProducts() {
  try {
    const response = yield call([ProductService, 'fetchParentProducts']);
    yield put(fetchParentProductsSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createParentProduct(action) {
  const { name, description, details, brand_uid, category_uid } = action.payload;

  try {
    const response = yield call(
      [ProductService, 'createParentProduct'],
      name,
      description,
      details,
      brand_uid,
      category_uid,
    );

    yield put(createParentProductSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateParentProduct(action) {
  const { uid, name, description, details, category_uid } = action.payload;

  try {
    const response = yield call(
      [ProductService, 'updateParentProduct'],
      uid,
      name,
      description,
      details,
      category_uid,
    );

    yield put(updateParentProductSuccess(response));
    toastr.success(i18n.t('toastr:successUpdate'));
  } catch (err) {
    createError(err);
  }
}

function* deleteParentProduct(action) {
  const { uid } = action.payload;

  try {
    yield call([ProductService, 'deleteParentProduct'], uid);
    yield put(deleteParentProductSuccess(uid));
  } catch (err) {
    createError(err);
  }
}

// child products
function* fetchChildProducts() {
  try {
    const response = yield call([ProductService, 'fetchChildProducts']);
    yield put(fetchChildProductsSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createChildProduct(action) {
  const {
    product_code,
    picture_id,
    parent_product_uid,
    inventory_uid,
    color_uid,
    size_uid,
    prices,
  } = action.payload;

  try {
    const response = yield call(
      [ProductService, 'createChildProduct'],
      product_code,
      picture_id,
      parent_product_uid,
      inventory_uid,
      color_uid,
      size_uid,
      prices,
    );

    yield put(createChildProductSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateChildProduct(action) {
  const {
    uid,
    product_code,
    picture_id,
    parent_product_uid,
    inventory_uid,
    color_uid,
    size_uid,
    prices,
  } = action.payload;

  try {
    const response = yield call(
      [ProductService, 'updateChildProduct'],
      uid,
      product_code,
      picture_id,
      parent_product_uid,
      inventory_uid,
      color_uid,
      size_uid,
      prices,
    );

    yield put(updateChildProductSuccess(response));
    toastr.success(i18n.t('toastr:successUpdate'));
  } catch (err) {
    createError(err);
  }
}

function* deleteChildProduct(action) {
  const { uid } = action.payload;

  try {
    yield call([ProductService, 'deleteChildProduct'], uid);
    yield put(deleteChildProductSuccess(uid));
  } catch (err) {
    createError(err);
  }
}

// colors
function* fetchColors() {
  try {
    const response = yield call([ProductService, 'fetchColors']);
    yield put(fetchColorsSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createColor(action) {
  const { name, hex_value, brand_uid } = action.payload;

  try {
    const response = yield call([ProductService, 'createColor'], name, hex_value, brand_uid);

    yield put(createColorSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateColor(action) {
  const { uid, name, hex_value } = action.payload;

  try {
    const response = yield call([ProductService, 'updateColor'], uid, name, hex_value);

    yield put(updateColorSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* deleteColor(action) {
  const { uid } = action.payload;

  try {
    yield call([ProductService, 'deleteColor'], uid);
    yield put(deleteColorSuccess(uid));
  } catch (err) {
    createError(err);
  }
}

function* generateColors() {
  try {
    const response = yield call([ProductService, 'generateColors']);
    yield put(generateColorsSuccess(response));
  } catch (err) {
    createError(err);
  }
}

// sizes
function* fetchSizes() {
  try {
    const response = yield call([ProductService, 'fetchSizes']);
    yield put(fetchSizesSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createSize(action) {
  const { name, brand_uid } = action.payload;

  try {
    const response = yield call([ProductService, 'createSize'], name, brand_uid);

    yield put(createSizeSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateSize(action) {
  const { uid, name } = action.payload;

  try {
    const response = yield call([ProductService, 'updateSize'], uid, name);

    yield put(updateSizeSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* deleteSize(action) {
  const { uid } = action.payload;

  try {
    yield call([ProductService, 'deleteSize'], uid);
    yield put(deleteSizeSuccess(uid));
  } catch (err) {
    createError(err);
  }
}

function* generateSizes() {
  try {
    const response = yield call([ProductService, 'generateSizes']);
    yield put(generateSizesSuccess(response));
  } catch (err) {
    createError(err);
  }
}

// categories
function* fetchCategories() {
  try {
    const response = yield call([ProductService, 'fetchCategories']);
    yield put(fetchCategoriesSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createCategory(action) {
  const { name, brand_uid } = action.payload;

  try {
    const response = yield call([ProductService, 'createCategory'], name, brand_uid);

    yield put(createCategorySuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateCategory(action) {
  const { uid, name } = action.payload;

  try {
    const response = yield call([ProductService, 'updateCategory'], uid, name);

    yield put(updateCategorySuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* deleteCategory(action) {
  const { uid } = action.payload;

  try {
    yield call([ProductService, 'deleteCategory'], uid);
    yield put(deleteCategorySuccess(uid));
  } catch (err) {
    createError(err);
  }
}

function* generateCategories() {
  try {
    const response = yield call([ProductService, 'generateCategories']);
    yield put(generateCategoriesSuccess(response));
  } catch (err) {
    createError(err);
  }
}

// inventories
function* fetchInventories() {
  try {
    const response = yield call([ProductService, 'fetchInventories']);
    yield put(fetchInventoriesSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createInventory(action) {
  const { name, brand_uid } = action.payload;

  try {
    const response = yield call([ProductService, 'createInventory'], name, brand_uid);

    yield put(createInventorySuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateInventory(action) {
  const { uid, name } = action.payload;

  try {
    const response = yield call([ProductService, 'updateInventory'], uid, name);

    yield put(updateInventorySuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* deleteInventory(action) {
  const { uid } = action.payload;

  try {
    yield call([ProductService, 'deleteInventory'], uid);
    yield put(deleteInventorySuccess(uid));
  } catch (err) {
    createError(err);
  }
}

function* generateInventories() {
  try {
    const response = yield call([ProductService, 'generateInventories']);
    yield put(generateInventoriesSuccess(response));
  } catch (err) {
    createError(err);
  }
}

const productSagas = [
  takeLatest(RAMBLA_FETCH_PARENT_PRODUCTS, fetchParentProducts),
  takeLatest(RAMBLA_CREATE_PARENT_PRODUCT, createParentProduct),
  takeLatest(RAMBLA_UPDATE_PARENT_PRODUCT, updateParentProduct),
  takeLatest(RAMBLA_DELETE_PARENT_PRODUCT, deleteParentProduct),
  takeLatest(RAMBLA_FETCH_CHILD_PRODUCTS, fetchChildProducts),
  takeLatest(RAMBLA_CREATE_CHILD_PRODUCT, createChildProduct),
  takeLatest(RAMBLA_UPDATE_CHILD_PRODUCT, updateChildProduct),
  takeLatest(RAMBLA_DELETE_CHILD_PRODUCT, deleteChildProduct),
  takeLatest(RAMBLA_FETCH_COLORS, fetchColors),
  takeLatest(RAMBLA_CREATE_COLOR, createColor),
  takeLatest(RAMBLA_UPDATE_COLOR, updateColor),
  takeLatest(RAMBLA_DELETE_COLOR, deleteColor),
  takeLatest(RAMBLA_GENERATE_COLORS, generateColors),
  takeLatest(RAMBLA_FETCH_SIZES, fetchSizes),
  takeLatest(RAMBLA_CREATE_SIZE, createSize),
  takeLatest(RAMBLA_UPDATE_SIZE, updateSize),
  takeLatest(RAMBLA_DELETE_SIZE, deleteSize),
  takeLatest(RAMBLA_GENERATE_SIZES, generateSizes),
  takeLatest(RAMBLA_FETCH_CATEGORIES, fetchCategories),
  takeLatest(RAMBLA_CREATE_CATEGORY, createCategory),
  takeLatest(RAMBLA_UPDATE_CATEGORY, updateCategory),
  takeLatest(RAMBLA_DELETE_CATEGORY, deleteCategory),
  takeLatest(RAMBLA_GENERATE_CATEGORIES, generateCategories),
  takeLatest(RAMBLA_FETCH_INVENTORIES, fetchInventories),
  takeLatest(RAMBLA_CREATE_INVENTORY, createInventory),
  takeLatest(RAMBLA_UPDATE_INVENTORY, updateInventory),
  takeLatest(RAMBLA_DELETE_INVENTORY, deleteInventory),
  takeLatest(RAMBLA_GENERATE_INVENTORIES, generateInventories),
];

export { productSagas };
