import CoreServiceRambla from './CoreServiceRambla';

class RegionService extends CoreServiceRambla {
  constructor(endpoint) {
    super(endpoint);
  }

  fetchRegions() {
    return this.api.get('/regions');
  }

  createRegion(name, base_currency, base_language, country, brand_uid) {
    return this.api.post('/regions', {
      name,
      base_currency,
      base_language,
      country,
      brand_uid,
    });
  }

  updateRegion(uid, name, base_currency, base_language, country) {
    return this.api.patch(`/regions/${uid}`, {
      name,
      base_currency,
      base_language,
      country,
    });
  }

  deleteRegion(uid) {
    return this.api.delete(`/regions/${uid}`);
  }
}

const instance = new RegionService('/v1');

export default instance;
