import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  RAMBLA_LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  AUTO_LOGIN,
  STORE_TOKEN,
} from './constants';

export function loginUser(email, password, redirectAddress) {
  return {
    type: LOGIN_USER,
    payload: { email, password, redirectAddress },
  };
}

export function userLoginSuccess(user) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: { user },
  };
}

export function ramblaUserLoginSuccess(rambla_user) {
  return {
    type: RAMBLA_LOGIN_USER_SUCCESS,
    payload: { rambla_user },
  };
}

export function userLoginError() {
  return {
    type: LOGIN_USER_FAILURE,
    payload: {},
  };
}

export function registerUser(registerPayload) {
  return {
    type: REGISTER_USER,
    payload: { ...registerPayload },
  };
}

export function registerUserSuccess(user) {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: { user },
  };
}

export function userRegisterError() {
  return {
    type: REGISTER_USER_FAILURE,
    payload: {},
  };
}

export function logoutUser(redirectToAveda) {
  return {
    type: LOGOUT_USER,
    payload: { redirectToAveda },
  };
}

export function logoutUserSuccess() {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
}

export function autoLogin() {
  return {
    type: AUTO_LOGIN,
    payload: {},
  };
}

export function storeToken(token) {
  return {
    type: STORE_TOKEN,
    payload: { token },
  };
}
