import produce from 'immer';
import User from 'api/models/User';
import { userRole } from 'utils/userRole';

import {
  LOGOUT_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  STORE_TOKEN,
  ACCEPT_TERMS_SUCCESS,
  RAMBLA_LOGIN_USER_SUCCESS,
} from './constants';
import { RAMBLA_CREATE_BRAND_SUCCESS } from '../brand/constants';

export const initialState = {
  autoLoginProcessing: true,
  user: new User(),
  role: null,
  rambla_user: null,
  is_onboarding_finished: false,
};

const authReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case RAMBLA_LOGIN_USER_SUCCESS:
        draft.autoLoginProcessing = false;
        draft.rambla_user = payload.rambla_user;
        draft.role = userRole(payload.rambla_user);
        break;
      case RAMBLA_CREATE_BRAND_SUCCESS:
        draft.is_onboarding_finished = true;
        break;
      case LOGIN_USER_FAILURE:
        draft.autoLoginProcessing = false;
        draft.user = new User();
        break;
      case LOGOUT_USER_SUCCESS:
        draft.user = new User();
        draft.rambla_user = null;
        break;
      case STORE_TOKEN:
        draft.token = payload.token;
        break;
      case ACCEPT_TERMS_SUCCESS:
        draft.user.acceptedTerms = true;
        break;
      default:
        return state;
    }
  });

export default authReducer;
