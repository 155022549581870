import { toastr } from 'utils';
import { upperFirst } from 'lodash';
import i18n from 'i18n';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RAMBLA_FETCH_STORES, RAMBLA_UPDATE_STORE } from './constants';
import StoreService from '../../api/services/StoreService';
import { fetchStoresSuccess, updateStoreRamblaSuccess } from './actions';

// TODO: to be extracted to generic saga
function createError(error) {
  Object.values(error.response.data).forEach(message =>
    toastr.error(i18n.t('toastr:error'), upperFirst(message)),
  );
}

function* fetchStores() {
  try {
    const response = yield call([StoreService, 'fetchStores']);
    yield put(fetchStoresSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateStore(action) {
  const { uid, is_private } = action.payload;

  try {
    const response = yield call([StoreService, 'updateStore'], uid, is_private);
    yield put(updateStoreRamblaSuccess(response));
  } catch (err) {
    createError(err);
  }
}

const storeSagas = [
  takeLatest(RAMBLA_FETCH_STORES, fetchStores),
  takeLatest(RAMBLA_UPDATE_STORE, updateStore),
];

export { storeSagas };
