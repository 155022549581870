export const mapRoleToString = (isOwner, isManager) => {
  if (isOwner) return 'Owner';
  if (isManager) return 'Manager';
  return 'Artist';
};

export const mapInitialValues = initialValues => {
  const mappedInitialValues = {
    ...initialValues,
    region_uid: initialValues?.region?.uid,
  };

  return mappedInitialValues;
};

export const getLocationUidById = (locations, ids) =>
  ids.map(id => locations.find(location => location.id === Number(id)));

export const getLocationsByOwner = (locations, ownerId) =>
  locations.filter(({ owners }) => owners.includes(ownerId)).map(location => location.id);
