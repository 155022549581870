import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import useEntityForm from 'hooks/useEntityForm';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/auth/selectors';
import { userRole } from 'utils/userRole';
import * as Roles from 'constants/roles';
import { pickOrDefault } from 'utils/pickOrDefault';

const TierForm = ({ type = 'create', initialValues, FormGroup, onActionSuccess }) => {
  const { t } = useTranslation(['pages', 'app']);
  const { onSubmit, dependencies, validationSchema } = useEntityForm(type, 'tier', {
    dependencies: ['product_group'],
  });

  const user = useSelector(userSelector);
  const adminRole = userRole(user);

  return (
    <Form
      initialValues={pickOrDefault(
        initialValues,
        ['name', 'product_groups', 'id', 'uid', 'region'],
        {
          name: '',
          product_groups: '',
          region: user.regions[0].id,
        },
      )}
      onSubmit={values => {
        onSubmit(values, onActionSuccess);
      }}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={t('pages:tiers.name')}>
          <Form.Input type="text" name="name" placeholder={t('app:name')} />
        </FormGroup>
        <FormGroup label={t('pages:retailers.region')}>
          <Form.MultiSelect
            disabled={adminRole === Roles.DEFAULT_ROLE || type !== 'create'}
            options={user.regions.map(r => ({ value: r.id, label: r.name }))}
            name="region"
            isMulti={false}
          />
        </FormGroup>
        <FormGroup label={t('pages:tiers.selectProductGroup')}>
          {!!dependencies.product_group.length && (
            <Form.DependentField
              Component={Form.MultiSelect}
              name="product_groups"
              dependencyName="region"
              calculateOptions={region_id =>
                dependencies.product_group
                  .filter(pg => pg.region === region_id)
                  .map(item => ({
                    value: item.id,
                    label: item.name,
                  }))
              }
            />
          )}
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

TierForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  onActionSuccess: PropTypes.func,
};

export default TierForm;
