import CoreServiceRambla from './CoreServiceRambla';

class RetailersService extends CoreServiceRambla {
  constructor(endpoint) {
    super(endpoint);
  }

  fetchRetailers() {
    return this.api.get('/users/retailer-details');
  }

  fetchRetailer(uid) {
    return this.api.get(`/users/retailer-details/${uid}`);
  }

  updateRetailer(uid, email, status, region_uid) {
    return this.api.patch(`/users/retailer-details/${uid}`, { email, status, region_uid });
  }

  deleteRetailer(uid) {
    return this.api.delete(`/users/retailer-details/${uid}`);
  }

  inviteRetailer(region_uid, email) {
    return this.api.post(`/users/retailer-details`, { region_uid, email });
  }
}

const instance = new RetailersService('/v1');

export default instance;
