import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { CardContainer } from 'components';
import { ThemeCustomizationForm } from 'containers/forms';
import { useSelector } from 'react-redux';

import { themeSelector } from 'redux/brand/selectors';

const OnboardingThemeCustomizationPage = () => {
  const { t } = useTranslation(['pages']);
  const theme = useSelector(themeSelector);

  return (
    <CardContainer lg="8">
      <Helmet>
        <title>Theme Customization</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.themeCustomTitle')}</h1>
      <h2 className="display-5 text-center">{t('onboarding.themeCustomSubtitle')}</h2>
      <ThemeCustomizationForm initialValues={theme.data} />
    </CardContainer>
  );
};

export default OnboardingThemeCustomizationPage;
