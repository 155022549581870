import { push } from 'connected-react-router';
import { upperFirst } from 'lodash';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import i18n from 'i18n';

import { toastr } from 'utils';
import { removeRamblaToken, setRamblaToken } from 'utils/tokenService';
import { removeBrand, setBrand } from 'utils/brandService';
import { clearData } from 'redux/entities/actions';
import AuthService from '../../api/services/AuthService';
import AuthServiceRambla from '../../api/services/AuthServiceRambla';
import { userSelector } from './selectors';
import { userLoginError, logoutUserSuccess, ramblaUserLoginSuccess, storeToken } from './actions';
import { AUTO_LOGIN, LOGIN_USER, LOGOUT_USER, REGISTER_USER, ACCEPT_TERMS } from './constants';
import { ADMIN_TOOL_LOGIN, LANDING_PAGE, MY_LOCATIONS } from '../../constants/routes';

function* loginUser(action) {
  const { email, password, redirectAddress } = action.payload;

  try {
    const { token } = yield call([AuthServiceRambla, 'login'], email, password);

    yield put(storeToken(token));
    setRamblaToken(token);

    const ramblaUser = yield call([AuthServiceRambla, 'getUserDetails']);

    if (ramblaUser.retailer_accounts.length) {
      toastr.error(i18n.t('toastr:error'), i18n.t('toastr:unableToLogIn'));
      removeRamblaToken(token);
    } else {
      yield put(ramblaUserLoginSuccess(ramblaUser));
      yield put(push(redirectAddress));
    }
  } catch (err) {
    Object.values(err.response.data).forEach(message =>
      toastr.error(i18n.t('toastr:error'), upperFirst(message)),
    );

    yield put(userLoginError());
  }
}

function* registerUser(action) {
  const { email, password, name, last_name } = action.payload;
  try {
    yield call([AuthServiceRambla, 'register'], email, password, name, last_name);
    yield call(loginUser, { payload: { email, password } });
  } catch (err) {
    Object.values(err.response.data).forEach(message =>
      toastr.error(i18n.t('toastr:error'), upperFirst(message)),
    );
    removeRamblaToken();

    yield put(userLoginError());
  }
}

function* autoLogin() {
  try {
    const ramblaUser = yield call([AuthServiceRambla, 'getUserDetails']);
    yield put(ramblaUserLoginSuccess(ramblaUser));
  } catch (err) {
    removeRamblaToken();
    removeBrand();
    yield put(userLoginError());
  }
}

function* logoutUser() {
  yield call(removeRamblaToken);
  yield call(removeBrand);
  yield call(() => localStorage.removeItem('sheetId'));
  yield put(logoutUserSuccess());
  yield put(clearData());
  yield put(push(ADMIN_TOOL_LOGIN));
  toastr.success(i18n.t('toastr:logoutSuccessful'));
}

function* acceptTerms(action) {
  const { version, firstLogin } = action.payload;
  const user = yield select(userSelector);

  try {
    yield call([AuthService, 'acceptTerms'], version);

    setBrand(user.brand.preview);

    yield put(push(firstLogin ? LANDING_PAGE : MY_LOCATIONS));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:acceptTermsError'));
  }
}

const authSagas = [
  takeLatest(LOGIN_USER, loginUser),
  takeLatest(REGISTER_USER, registerUser),
  takeLatest(AUTO_LOGIN, autoLogin),
  takeLatest(LOGOUT_USER, logoutUser),
  takeLatest(ACCEPT_TERMS, acceptTerms),
];

export { authSagas };
