import React, { useState, createContext, useContext } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Container, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import withDefaultProps from 'helpers/withDefaultProps';

import { breakpoints } from 'utils/rwd';
import RamblaLogoWhite from 'assets/img/rambla-logo.png';
import {
  NavigationWrapper,
  NavigationLinkLabel,
  ToggleMenuButton,
  BurgerMenuButton,
  NavigationLogo,
} from './styles';

export const NavigationContext = createContext();

const Navigation = ({ compact, toggleNavigation, children, ...restProps }) => {
  const [isVisible, setVisible] = useState(false);
  const handleBurgerMenuClick = () => {
    if (window.matchMedia(`(max-width: ${breakpoints.sm})`)) {
      toggleNavigation(false);
      setVisible(!isVisible);
    }
  };

  return (
    <NavigationContext.Provider value={{ compact }}>
      <NavigationWrapper compact={compact} {...restProps}>
        <Container fluid>
          <BurgerMenuButton onClick={handleBurgerMenuClick}>
            <span className="navbar-toggler-icon" />
          </BurgerMenuButton>

          <NavigationLogo compact={compact} src={RamblaLogoWhite} />

          <div className={`collapse navbar-collapse ${isVisible && 'show'}`}>
            <ul className="navbar-nav" style={{ flex: 1 }}>
              {children}
            </ul>
          </div>

          <ToggleMenuButton onClick={() => toggleNavigation(!compact)}>
            <i className={`fe fe-chevron-${compact ? 'right' : 'left'}`} />
          </ToggleMenuButton>
        </Container>
      </NavigationWrapper>
    </NavigationContext.Provider>
  );
};

Navigation.Link = function Link({
  to = '',
  name,
  icon,
  renderIcon,
  children,
  subItem,
  collapsed = true,
  active = true,
  guard = () => true,
  ...restProps
}) {
  const { compact } = useContext(NavigationContext);
  const [isCollapsed, setCollapsed] = useState(collapsed);
  const dispatch = useDispatch();
  const passedGuardCheck = useSelector(guard);

  if (!passedGuardCheck) {
    return null;
  }

  if (children) {
    return (
      <Nav.Item className={cx({ dropright: compact })} {...restProps}>
        <Nav.Link
          className={`nav-link ${isCollapsed ? 'collapsed' : ''}`}
          data-toggle={!compact ? 'collapse' : ''}
          role="button"
          aria-expanded={isCollapsed ? 'false' : 'true'}
          aria-controls="sidebarAuth"
          onClick={() => {
            setCollapsed(() => !isCollapsed);
            if (isCollapsed) dispatch(push(to));
          }}
        >
          {renderIcon ? renderIcon() : <i className={`fe ${icon}`} />}
          {!compact && (
            <NavigationLinkLabel compact={compact} subItem={subItem}>
              {name}
            </NavigationLinkLabel>
          )}
        </Nav.Link>
        {!isCollapsed && !compact && <ul className="nav nav-sm flex-column">{children}</ul>}
        <ul className="dropdown-menu">
          <li className="dropdown-header d-none d-md-block">
            <h6 className="text-uppercase mb-0">{name}</h6>
          </li>
          {children}
        </ul>
      </Nav.Item>
    );
  }

  return (
    <Nav.Item className={cx({ dropright: compact || subItem })} {...restProps}>
      <NavLink className="nav-link" to={to} exact activeClassName={active ? 'active' : ''}>
        {renderIcon ? renderIcon() : <i className={`fe ${icon}`} />}
        {(!compact || subItem) && (
          <NavigationLinkLabel compact={compact} subItem={subItem}>
            {name}
          </NavigationLinkLabel>
        )}
      </NavLink>
      {!subItem && (
        <ul className="dropdown-menu">
          <li className="dropdown-header d-none d-md-block">
            <h6 className="text-uppercase mb-0">{name}</h6>
          </li>
        </ul>
      )}
    </Nav.Item>
  );
};

Navigation.propTypes = {
  toggleNavigation: PropTypes.func,
  compact: PropTypes.bool,
  ...withDefaultProps(),
};

Navigation.Link.propTypes = {
  to: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  renderIcon: PropTypes.func,
  collapsed: PropTypes.bool,
  guard: PropTypes.func,
  ...withDefaultProps(),
};

export default Navigation;
