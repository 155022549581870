import styled from 'styled-components/macro';
import { Button } from 'react-bootstrap';

export const ButtonWithArrows = styled(Button)`
  transition: color 0.05s ease-int-out;
  cursor: pointer;

  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.25s;
  }

  span.fwdArrow {
    &:after {
      content: '»';
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.05s;
    }
    &:hover {
      padding-right: 25px;
      &:after {
        opacity: 1;
        right: 0;
      }
    }
  }

  &:hover {
    span.fwdArrow {
      padding-right: 25px;
      &:after {
        opacity: 1;
        right: 0;
      }
    }
  }

  span.backArrow {
    &:before {
      content: '«';
      position: absolute;
      opacity: 0;
      top: 0;
      left: 20px;
      transition: 0s;
    }
    &:hover {
      padding-left: 25px;
      &:before {
        opacity: 1;
        left: 0;
      }
    }
  }
  &:hover {
    span.backArrow {
      padding-left: 25px;
      &:before {
        opacity: 1;
        left: 0;
      }
    }
  }
`;
