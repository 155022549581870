import React from 'react';
import { useSelector } from 'react-redux';

import { CardContainer } from 'components';
import { ThemeCustomizationForm } from 'containers/forms';
import { themeSelector } from 'redux/brand/selectors';

const ThemeCustomizationPage = () => {
  const theme = useSelector(themeSelector);

  return (
    <CardContainer>
      <ThemeCustomizationForm initialValues={theme.data} />
    </CardContainer>
  );
};

export default ThemeCustomizationPage;
