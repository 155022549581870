import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router-dom';

import { ONBOARDING_SELLER_ACCESS } from 'constants/routes';
import { CardContainer, ImageWrapper, Button, Spinner } from 'components';
import {
  generateColors,
  generateSizes,
  generateCategories,
  generateInventories,
} from 'redux/products/actions';

import {
  ramblaIsProductColorsGeneratedSelector,
  ramblaIsProductSizesGeneratedSelector,
  ramblaIsProductCategoriesGeneratedSelector,
  ramblaIsProductInventoriesGeneratedSelector,
  brandSelector,
} from 'redux/brand/selectors';

import { SELF_MANAGED_CATALOG } from 'constants/brandConfiguration';
import { ReactComponent as Icon } from '../../assets/img/illustrations/attributes-generation.svg';

const OnboardingProductAttributesGenerationPage = () => {
  const { t } = useTranslation(['pages']);
  const dispatch = useDispatch();
  const history = useHistory();
  const HANDLE_SUBMIT_TIMEOUT = 600;

  const [isGeneratingColors, setIsGeneratingColors] = useState(false);
  const [isGeneratingSizes, setIsGeneratingSizes] = useState(false);
  const [isGeneratingCategories, setIsGeneratingCategories] = useState(false);
  const [isGeneratingInventories, setIsGeneratingInventories] = useState(false);

  const isProductColorsGenerated = useSelector(ramblaIsProductColorsGeneratedSelector);
  const isProductSizesGenerated = useSelector(ramblaIsProductSizesGeneratedSelector);
  const isProductCategoriesGenerated = useSelector(ramblaIsProductCategoriesGeneratedSelector);
  const isProductInventoriesGenerated = useSelector(ramblaIsProductInventoriesGeneratedSelector);

  const handleOnClick = (action, setIsGenerating) => {
    setIsGenerating(true);
    dispatch(action);
    setTimeout(() => {
      setIsGenerating(false);
    }, HANDLE_SUBMIT_TIMEOUT);
  };

  const brand = useSelector(brandSelector);

  useEffect(() => {
    if (brand.data.config.product_catalog !== SELF_MANAGED_CATALOG) {
      history.push(ONBOARDING_SELLER_ACCESS);
    }
  }, []);

  return (
    <CardContainer lg="8">
      <Helmet>
        <title>Generating attributes</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.attributesGenerationTitle')}</h1>
      <h2 className="display-5 text-center">{t('onboarding.attributesGenerationSubtitle')}</h2>
      <ImageWrapper>
        <Icon />
      </ImageWrapper>
      <Button
        className="mt-2"
        variant="light"
        type="submit"
        onClick={() => handleOnClick(generateColors(), setIsGeneratingColors)}
        disabled={isProductColorsGenerated}
      >
        {isGeneratingColors ? (
          <Spinner style={{ width: '24px', height: '24px' }} />
        ) : isProductColorsGenerated ? (
          t('onboarding.colorsGenerated')
        ) : (
          t('onboarding.generateColors')
        )}
      </Button>
      <Button
        className="mt-4"
        variant="light"
        type="submit"
        onClick={() => handleOnClick(generateSizes(), setIsGeneratingSizes)}
        disabled={isProductSizesGenerated}
      >
        {isGeneratingSizes ? (
          <Spinner style={{ width: '24px', height: '24px' }} />
        ) : isProductSizesGenerated ? (
          t('onboarding.sizesGenerated')
        ) : (
          t('onboarding.generateSizes')
        )}
      </Button>
      <Button
        className="mt-4"
        variant="light"
        type="submit"
        onClick={() => handleOnClick(generateCategories(), setIsGeneratingCategories)}
        disabled={isProductCategoriesGenerated}
      >
        {isGeneratingCategories ? (
          <Spinner style={{ width: '24px', height: '24px' }} />
        ) : isProductCategoriesGenerated ? (
          t('onboarding.categoriesGenerated')
        ) : (
          t('onboarding.generateCategories')
        )}
      </Button>
      <Button
        className="mt-4"
        variant="light"
        type="submit"
        onClick={() => handleOnClick(generateInventories(), setIsGeneratingInventories)}
        disabled={isProductInventoriesGenerated}
      >
        {isGeneratingInventories ? (
          <Spinner style={{ width: '24px', height: '24px' }} />
        ) : isProductInventoriesGenerated ? (
          t('onboarding.inventoriesGenerated')
        ) : (
          t('onboarding.generateInventories')
        )}
      </Button>
      <Button
        className="mt-4"
        variant="primary"
        onClick={() => dispatch(push(ONBOARDING_SELLER_ACCESS))}
      >
        {t('onboarding.continueOnboarding')}
      </Button>
    </CardContainer>
  );
};

export default OnboardingProductAttributesGenerationPage;
