import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { BiXCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { singleLookCoverPhotosSelector } from 'redux/looks/selectors';
import { getCoverPhotos, removeCoverPhoto, updateLookCoverPhoto } from 'redux/looks/actions';
import { ImageUpload } from 'components/Form/inputs/ImageUpload/ImageUpload';
import * as Ui from './CoverPhoto.styles';
import { CoverPhotoRatio } from '../CoverPhotoRatio/CoverPhotoRatio';

const CoverPhoto = ({ isEditable, aspect_ratio }) => {
  const [coverPhotoRatio, setCoverPhotoRatio] = useState(null);
  const dispatch = useDispatch();
  const photos = useSelector(singleLookCoverPhotosSelector);

  useEffect(() => {
    dispatch(getCoverPhotos());
  }, []);

  useEffect(() => {
    !!photos?.length && setCoverPhotoRatio(aspect_ratio);
  }, [photos]);

  useEffect(() => {
    setCoverPhotoRatio(aspect_ratio);
  }, []);

  const handleRatioSelection = ratio => {
    setCoverPhotoRatio(ratio);
  };

  if (!coverPhotoRatio) {
    return <CoverPhotoRatio onClick={handleRatioSelection} />;
  }

  return (
    <Ui.CoverPhotoWrapper>
      <Ui.PhotosInnerWrapper>
        {!!photos &&
          photos.map(({ cover_photo, uid }) => (
            <Ui.ImageWrapper>
              <Image
                style-={{ aspectRatio: coverPhotoRatio.replace(':', '/') }}
                id="cover-photo"
                src={cover_photo}
                className="rounded-0"
              />
              <span
                aria-hidden="true"
                onClick={() => {
                  dispatch(removeCoverPhoto(uid));
                  if (photos.length === 1) {
                    setCoverPhotoRatio(undefined);
                  }
                }}
                className="remove-photo"
              >
                <BiXCircle size={50} />
              </span>
            </Ui.ImageWrapper>
          ))}
        {photos?.length < 5 && (
          <Ui.ImageWrapper>
            {/* Image to ensure that aspect ratio and size is preserved */}
            <Image
              id="cover-photo"
              src={photos?.[0]?.cover_photo}
              className="rounded-0 position-relative"
              style={{
                visibility: 'hidden',
                minHeight: '200px',
                aspectRatio: coverPhotoRatio.replace(':', '/'),
              }}
            />
            <ImageUpload
              withThumbnails={false}
              disabled={!isEditable}
              accept="image/*"
              name="images"
              maxFiles={1}
              withCrop
              // eslint-disable-next-line
              aspectRatio={coverPhotoRatio ? eval(coverPhotoRatio.replace(':', '/')) : null}
              onCrop={image =>
                dispatch(updateLookCoverPhoto({ image, aspectRatio: coverPhotoRatio }))
              }
            />
          </Ui.ImageWrapper>
        )}
      </Ui.PhotosInnerWrapper>
    </Ui.CoverPhotoWrapper>
  );
};

CoverPhoto.propTypes = {
  isEditable: PropTypes.bool,
  aspect_ratio: PropTypes.string,
};
export { CoverPhoto };
