import {
  RAMBLA_FETCH_REGIONS,
  RAMBLA_FETCH_REGIONS_SUCCESS,
  RAMBLA_CREATE_REGION,
  RAMBLA_CREATE_REGION_SUCCESS,
  RAMBLA_UPDATE_REGION,
  RAMBLA_UPDATE_REGION_SUCCESS,
  RAMBLA_DELETE_REGION,
  RAMBLA_DELETE_REGION_SUCCESS,
} from './constants';

export const fetchRegions = () => ({
  type: RAMBLA_FETCH_REGIONS,
});

export const fetchRegionsSuccess = regions => ({
  type: RAMBLA_FETCH_REGIONS_SUCCESS,
  payload: regions,
});

export const createRegion = ({
  name,
  base_currency,
  base_language,
  country_code,
  brand_uid,
  is_onboarding,
}) => ({
  type: RAMBLA_CREATE_REGION,
  payload: {
    name,
    base_currency,
    base_language,
    country_code,
    brand_uid,
    is_onboarding,
  },
});

export const createRegionSuccess = region => ({
  type: RAMBLA_CREATE_REGION_SUCCESS,
  payload: region,
});

export const updateRegion = (uid, name, base_currency, base_language, country_code) => ({
  type: RAMBLA_UPDATE_REGION,
  payload: { uid, name, base_currency, base_language, country_code },
});

export const updateRegionSuccess = region => ({
  type: RAMBLA_UPDATE_REGION_SUCCESS,
  payload: region,
});

export const deleteRegion = uid => ({
  type: RAMBLA_DELETE_REGION,
  payload: { uid },
});

export const deleteRegionSuccess = uid => ({
  type: RAMBLA_DELETE_REGION_SUCCESS,
  payload: { uid },
});
