import produce from 'immer';
import { RAMBLA_FETCH_STORES_SUCCESS, RAMBLA_UPDATE_STORE_SUCCESS } from './constants';

export const initialState = { fetched: false, data: [] };

const storeReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case RAMBLA_FETCH_STORES_SUCCESS:
        draft.data = payload;
        draft.fetched = true;
        break;
      case RAMBLA_UPDATE_STORE_SUCCESS:
        draft.data = state.data.map(store => (store.id === payload.id ? payload : store));
        break;
      default:
        return state;
    }
  });

export default storeReducer;
