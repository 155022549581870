import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import stylistStatus from 'constants/stylistStatus';
import { locationSelector } from 'redux/locations/selectors';
import { useDispatch, useSelector } from 'react-redux';
import entitySelectors from 'redux/entities/selectors';
import { entityActions } from 'redux/entities/actions';
import { breakpoints } from 'utils/rwd';
import ScrollContainer from 'react-indiana-drag-scroll';
import * as Ui from './OurStylists.styles';
import { Stylist } from './components/Stylist';

const OurStylistsBanner = ({ component }) => {
  const dispatch = useDispatch();
  const { participants, vanity_url } = useSelector(locationSelector);
  const selectedSellersWithAccount = participants.filter(seller => {
    const isIncluded = component.properties?.stylists?.includes(seller?.connected_user?.uid);
    const isApproved = seller.profile_page_status === stylistStatus.APPROVED;
    return isIncluded && isApproved;
  });

  const customArtists = useSelector(entitySelectors.custom_artistsSelector);

  useEffect(() => {
    if (vanity_url) {
      dispatch(entityActions.fetchEntity('custom_artists', { uid: vanity_url }));
    }
  }, [vanity_url]);

  const selectedCustomArtists = customArtists.filter(ca =>
    component.properties.custom?.includes(ca.uid),
  );

  const selectedSellers = selectedSellersWithAccount.concat(selectedCustomArtists);

  if (selectedSellers.length === 0) {
    return (
      <Row>
        <i className="fe fe-edit d-flex justify-content-center align-items-center" />
      </Row>
    );
  }

  return <StylistPureComponent selectedSellers={selectedSellers} title="Stylists" />;
};

const StylistPureComponent = ({ selectedSellers, title, onClick, widget }) => {
  const [activeSlide, setActiveSlide] = useState(1);

  const isSmallerThenLg = useMediaQuery({
    query: `(max-width: ${breakpoints.lg}px)`,
  });

  const showCarouselNavigation = isSmallerThenLg
    ? selectedSellers.length > 2
    : selectedSellers.length > 12;

  const onChange = useCallback(value => {
    setActiveSlide(value);
  }, []);

  const carouselPlugins = useMemo(() => {
    const plugins = [
      {
        resolve: slidesToShowPlugin,
        options: {
          numberOfSlides: isSmallerThenLg ? 2.3 : 6,
        },
      },
    ];

    return plugins;
  }, [isSmallerThenLg]);

  const renderStylists = useCallback(() => {
    if (showCarouselNavigation && !widget) {
      return (
        <Ui.CarouselWrapper widget={widget}>
          <Carousel value={activeSlide} onChange={onChange} plugins={carouselPlugins}>
            {selectedSellers.map(stylist => (
              <Stylist key={stylist.id} onClick={onClick} stylist={stylist} />
            ))}
          </Carousel>
        </Ui.CarouselWrapper>
      );
    }
    return (
      <Ui.StylistRow widget={widget}>
        <ScrollContainer className="d-flex w-100 position-relative" vertical>
          {selectedSellers.map(stylist => (
            <Stylist widget={widget} noSlide key={stylist.id} stylist={stylist} />
          ))}
        </ScrollContainer>
      </Ui.StylistRow>
    );
  }, [isSmallerThenLg, carouselPlugins, activeSlide, selectedSellers]);

  return (
    <Ui.Wrapper widget={widget}>
      <Ui.Container>
        <Ui.SectionTitle>{title}</Ui.SectionTitle>
        {renderStylists()}
        {!!showCarouselNavigation && !widget && (
          <Ui.DotsWrapper>
            <Dots value={activeSlide} onChange={setActiveSlide} number={selectedSellers.length} />
          </Ui.DotsWrapper>
        )}
      </Ui.Container>
    </Ui.Wrapper>
  );
};

OurStylistsBanner.propTypes = {
  component: PropTypes.object.isRequired,
};

StylistPureComponent.propTypes = {
  selectedSellers: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  widget: PropTypes.bool,
};

export default OurStylistsBanner;
export { StylistPureComponent };
