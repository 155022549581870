import styled from 'styled-components/macro';
import { media } from 'utils/rwd';

const NavigationWrapper = styled.nav.attrs({
  className: 'navbar navbar-vertical navbar-expand-md fixed-left navbar-dark',
})`
  background: rgba(0, 0, 0, 0.9);
  overflow: visible !important;
  flex: 1;
  max-width: ${props => (props.compact ? '66px !important' : 'auto')};
  ${media.sm.down`
    max-width: 100% !important;
  `}

  .dropdown-menu {
    .nav-link.active span {
      color: ${props => (props.compact ? '#000000' : '#ffffff')};
      &:hover {
        color: ${props => (props.compact ? '#000000' : '#ffffff')};
      }
    }
  }

  .dropdown-menu {
    .nav-link {
      &:hover {
        color: ${props => (props.compact ? '#000000' : '#ffffff')};
      }
    }
  }
`;

const NavigationLinkLabel = styled.span`
  font-size: 14px;
  display: ${({ compact, subItem }) => {
    const subItemClass = subItem ? 'inline-block' : 'none';
    compact ? subItemClass : 'inline-block';
  }};
  @media (max - width: 767px) {
    display: inline - block;
  }
`;

const ToggleMenuButton = styled.button.attrs({
  type: 'button',
  className: 'btn btn-white rounded-circle d-none d-md-block',
})`
  position: absolute;
  bottom: 75px;
  right: -20px;
  z-index: 10;
`;

const BurgerMenuButton = styled.button.attrs({
  className: 'navbar-toggler',
  type: 'button',
})``;

const NavigationLogo = styled.img`
  padding: 10px 0 30px 0;
  margin: 0 auto;
  height: 50%;
  max-width: 80%;
  display: ${({ compact }) => (compact ? 'none' : 'block')};

  ${media.sm.down`
    display: block;
    position: absolute;
    left: calc(50% - 80px);
    top: 12px;
    opacity: 1;
    padding: 0;
    height: 40px;
`}
`;

export {
  BurgerMenuButton,
  NavigationWrapper,
  NavigationLinkLabel,
  ToggleMenuButton,
  NavigationLogo,
};
