import CoreServiceRambla from './CoreServiceRambla';

class AuthServiceRambla extends CoreServiceRambla {
  constructor(endpoint) {
    super(endpoint);
  }

  login(email, password) {
    return this.api.post('/users/login', { email, password }, { requireAuth: false });
  }

  register(email, password, name, last_name) {
    return this.api.post(
      '/users/admin-sign-up',
      {
        email,
        password,
        name,
        last_name,
      },
      { requireAuth: false },
    );
  }

  getUserDetails() {
    return this.api.get(`/users/profile-info`);
  }

  registerApproval(uid, verification_code) {
    return this.api.patch(
      `users/admin-sign-up/approval/${uid}`,
      {
        verification_code,
      },
      { requireAuth: false },
    );
  }

  sendChangePasswordCode(email) {
    return this.api.post('users/reset-password-code', { email }, { requireAuth: false });
  }

  changePassword(reset_password_code, password) {
    return this.api.post(
      'users/reset-password',
      { reset_password_code, password },
      { requireAuth: false },
    );
  }
}
const instance = new AuthServiceRambla('/v1');

export default instance;
