import config from '../config';

type Features = {
  quicksight: boolean;
  ownerGroup: boolean;
  offerCodes: boolean;
  templateLooks: boolean;
  landingPage: boolean;
  embeddableWidgets: boolean;
};

const featureFlags: () => Features = () => config.featureFlags;

const isQuicksightEnabled = () => featureFlags().quicksight;

const isOwnerGroup = () => featureFlags().ownerGroup;

const isOfferCodes = () => featureFlags().offerCodes;

const isTemplateLooks = () => featureFlags().templateLooks;

const isLandingPage = () => featureFlags().landingPage;

const isEmbeddableWidgets = () => featureFlags().embeddableWidgets;

export {
  isQuicksightEnabled,
  isOwnerGroup,
  isOfferCodes,
  isTemplateLooks,
  isLandingPage,
  isEmbeddableWidgets,
};
