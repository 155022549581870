import React from 'react';
import * as route from 'constants/routes';
import StorefrontIcon from '@material-ui/icons/Storefront';
import i18n from 'i18n';

import { DEFAULT_ROLE } from 'constants/roles';

export const menuItems = [
  {
    id: '1',
    label: i18n.t('ownerPortal:menu.dashboard'),
    adminToolLabel: i18n.t('menu:dashboard'),
    icon: 'fe-home',
    link: route.DASHBOARD,
    roles: [DEFAULT_ROLE],
    items: [
      {
        id: '1.1',
        label: i18n.t('menu:brandInformation'),
        link: route.BRAND_INFORMATION,
      },
      {
        id: '1.2',
        label: i18n.t('menu:themeCustomization'),
        link: route.THEME_CUSTOMIZATION,
      },
    ],
  },
  {
    id: '2',
    label: i18n.t('menu:products'),
    icon: 'fe-package',
    link: route.PRODUCTS,
    roles: [DEFAULT_ROLE],
    items: [
      {
        id: '2.1',
        label: i18n.t('menu:parentProducts'),
        link: route.PARENT_PRODUCTS,
      },
      {
        id: '2.2',
        label: i18n.t('menu:childProducts'),
        link: route.CHILD_PRODUCTS,
      },
      {
        id: '2.3',
        label: i18n.t('menu:colors'),
        link: route.COLORS,
      },
      {
        id: '2.4',
        label: i18n.t('menu:sizes'),
        link: route.SIZES,
      },
      {
        id: '2.5',
        label: i18n.t('menu:categories'),
        link: route.CATEGORIES,
      },
      {
        id: '2.6',
        label: i18n.t('menu:inventories'),
        link: route.INVENTORIES,
      },
    ],
  },
  {
    id: '3',
    label: i18n.t('menu:sellers'),
    icon: 'fe-users',
    link: route.RETAILERS,
    roles: [DEFAULT_ROLE],
  },
  {
    id: '4',
    label: i18n.t('menu:stores'),
    icon: 'fa-shopping-cart',
    renderIcon: () => (
      <div className="fe">
        <StorefrontIcon />
      </div>
    ),
    link: route.STORES,
    roles: [DEFAULT_ROLE],
  },
  {
    id: '5',
    label: i18n.t('menu:regions'),
    icon: 'fe-map',
    link: route.REGIONS,
    roles: [DEFAULT_ROLE],
  },
  // TODO: uncomment when locations are ready
  // {
  //   id: '6',
  //   label: i18n.t('menu:locations'),
  //   icon: 'fe-map-pin',
  //   roles: [DEFAULT_ROLE],
  //   link: route.MY_LOCATIONS,
  // },
];
