import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import history from 'utils/history';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash-es';
import { LOOKS } from 'constants/routes';
import { createLookFromDraft } from 'redux/looks/actions';
import { Button } from 'components';

import * as Ui from './EditLookFooter.styled';
import { DraftModal } from '../DraftModal/DraftModal';
import { PreviewButton } from '../PreviewButton/PreviewButton';

const EditLookFooter = ({ onDelete, look, onSave }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ownerPortal');

  const renderCreateButton = button => {
    if (look.feature_hints.is_saved_hint_dismissed) {
      return null;
    }

    const isLookToSave = Object.keys(omit(look.feature_hints, 'is_saved_hint_dismissed')).reduce(
      (acc, curr) => acc && look.feature_hints[curr],
      true,
    );

    if (isLookToSave) {
      return React.cloneElement(button, {
        onClick: () => dispatch(createLookFromDraft()),
      });
    }

    return (
      <DraftModal trigger={button} features={omit(look.feature_hints, 'is_saved_hint_dismissed')} />
    );
  };

  return (
    <Ui.Wrapper>
      <Button
        size="lg"
        variant="link"
        onClick={onDelete}
        className="text-danger text-decoration-underline"
      >
        {t('Looks.deleteLook')}
      </Button>
      <div>
        <PreviewButton />

        {(look?.is_editable || look?.is_template) && (
          <>
            <Button
              size="lg"
              variant={look.is_draft ? 'outline-dark' : 'dark'}
              onClick={() => (onSave ? onSave() : history.push(LOOKS))}
            >
              {look.is_draft ? t('Looks.saveDraft') : 'Save'}
            </Button>

            {renderCreateButton(
              <Button size="lg" variant="primary">
                {t('Looks.publish')}
              </Button>,
            )}
          </>
        )}
      </div>
    </Ui.Wrapper>
  );
};

EditLookFooter.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,

  look: PropTypes.shape({
    is_editable: PropTypes.bool,
    is_template: PropTypes.bool,
    from_store_template: PropTypes.bool,
    is_draft: PropTypes.bool,
    feature_hints: PropTypes.shape({
      is_name_hint_dismissed: PropTypes.bool,
      is_cover_photo_hint_dismissed: PropTypes.bool,
      is_message_hint_dismissed: PropTypes.bool,
      is_add_product_hint_dismissed: PropTypes.bool,
      is_saved_hint_dismissed: PropTypes.bool,
    }),
  }),
};
export default EditLookFooter;
