import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import * as Yup from 'yup';

import useEntityForm from 'hooks/useEntityForm';
import { FormWrapper } from 'components/Modal/styles';
import {
  ramblaParentProductsSelector,
  ramblaProductColorsSelector,
  ramblaProductSizesSelector,
  ramblaProductInventoriesSelector,
} from 'redux/products/selectors';
import { Wrapper, Modal, Form, Button } from 'components';
import { ImageUpload } from 'components/Form/inputs/ImageUpload/ImageUpload';
import { createChildProduct, updateChildProduct } from 'redux/products/actions';
import { uploadMedia } from 'utils/mediaResourceUtils';
import { ramblaRegionsSelector } from 'redux/regions/selectors';
import { mapInitialValues } from '../../utils/childProductFormUtils';

const ChildProductForm = ({ type = 'create', initialValues }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'app']);
  const { validationSchema } = useEntityForm(type, 'child_product');

  const parentProducts = useSelector(ramblaParentProductsSelector);
  const colors = useSelector(ramblaProductColorsSelector);
  const sizes = useSelector(ramblaProductSizesSelector);
  const inventories = useSelector(ramblaProductInventoriesSelector);
  const regions = useSelector(ramblaRegionsSelector);

  const mappedInitialValues = useMemo(() => mapInitialValues(initialValues), [initialValues]);

  useEffect(() => {
    regions.data.forEach(region => {
      if (!validationSchema.fields[region.uid]) {
        validationSchema.fields[region.uid] = Yup.number().required(i18n.t('validation:required'));
        validationSchema._nodes.push(region.uid);
      }
    });
  }, [regions.data]);

  return (
    <Form
      initialValues={
        mappedInitialValues || {
          product_code: '',
          thumbnail: [],
          images: [],
          parent_product_uid: '',
          inventory_uid: '',
          color_uid: '',
          size_uid: '',
          prices: [],
        }
      }
      onSubmit={async ({
        uid,
        product_code,
        thumbnail,
        parent_product_uid,
        inventory_uid,
        color_uid,
        size_uid,
        images,
        ...regionPrices
      }) => {
        const prices = Object.entries(regionPrices).map(([key, value]) => ({
          region: key,
          price: value,
        }));

        const { public_id } = await uploadMedia(thumbnail);
        type === 'update'
          ? dispatch(
              updateChildProduct(
                uid,
                product_code,
                public_id,
                parent_product_uid,
                inventory_uid,
                color_uid,
                size_uid,
                prices,
              ),
            )
          : dispatch(
              createChildProduct(
                product_code,
                public_id,
                parent_product_uid,
                inventory_uid,
                color_uid,
                size_uid,
                prices,
              ),
            );
      }}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <Form.GroupVertical label={t('createProduct.productCode')}>
          <Form.Input name="product_code" type="text" />
        </Form.GroupVertical>
        {regions.data.length > 0 &&
          regions.data.map(region => (
            <Form.GroupVertical label={t('createProduct.price')}>
              <Form.Input
                name={region.uid}
                type="number"
                min="0"
                append={region.base_currency || null}
              />
            </Form.GroupVertical>
          ))}
        <Form.GroupVertical label={t('createProduct.images')}>
          <ImageUpload accept="image/*" name="thumbnail" maxFiles={1} />
        </Form.GroupVertical>
        <Form.GroupVertical label={t('createProduct.parentProduct')}>
          <Form.MultiSelect
            options={parentProducts.data.map(parentProduct => ({
              value: parentProduct.uid,
              label: parentProduct.name,
            }))}
            name="parent_product_uid"
            isMulti={false}
          />
        </Form.GroupVertical>
        <Form.GroupVertical label={t('createProduct.color')}>
          <Form.MultiSelect
            options={colors.data.map(color => ({
              value: color.uid,
              label: color.name,
            }))}
            name="color_uid"
            isMulti={false}
          />
        </Form.GroupVertical>
        <Form.GroupVertical label={t('createProduct.size')}>
          <Form.MultiSelect
            options={sizes.data.map(size => ({
              value: size.uid,
              label: size.name,
            }))}
            name="size_uid"
            isMulti={false}
          />
        </Form.GroupVertical>
        <Form.GroupVertical label={t('createProduct.inventory')}>
          <Form.MultiSelect
            options={inventories.data.map(inventory => ({
              value: inventory.uid,
              label: inventory.name,
            }))}
            name="inventory_uid"
            isMulti={false}
          />
        </Form.GroupVertical>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

ChildProductForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
};

export default ChildProductForm;
