import React from 'react';

import { CardContainer } from 'components';
import { BrandInformationForm } from 'containers/forms';
import { useSelector } from 'react-redux';
import { brandSelector } from 'redux/brand/selectors';

const BrandInformationPage = () => {
  const brand = useSelector(brandSelector);

  return (
    <CardContainer>
      <BrandInformationForm type="update" initialValues={brand.data} />
    </CardContainer>
  );
};

export default BrandInformationPage;
