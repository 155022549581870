import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button } from 'react-bootstrap';
import { entityHOC } from 'components/hoc/entityHOC';
import { useTranslation } from 'react-i18next';
import entities from 'constants/entities';
import { Modal, Table, Form, Wrapper } from 'components';
import columns from 'constants/columns';
import translationLanguages from 'constants/translationLanguages';
import useEntityForm from 'hooks/useEntityForm';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/auth/selectors';

const AddModal = () => {
  const { t } = useTranslation(['pages']);
  const { onSubmit } = useEntityForm('create', 'welcome_screen');
  const { regions, is_brand_admin } = useSelector(userSelector);

  return (
    <Modal
      header={{
        title: 'Welcome Screens',
        subTitle: 'Create',
      }}
      trigger={
        <Button className="rounded-0" variant="primary">
          {t('app:create')}
        </Button>
      }
    >
      <Modal.ClosableBody>
        {({ closePopup }) => (
          <Form
            initialValues={{ language: '', region: regions[0].id }}
            onSubmit={async values => {
              await onSubmit(values);
              closePopup();
            }}
            style={{ minWidth: '400px' }}
          >
            <Form.GroupVertical label="Language">
              <Form.MultiSelect
                options={translationLanguages.map(el => ({ label: el.name, value: el.code }))}
                name="language"
                isMulti={false}
              />
            </Form.GroupVertical>
            <Form.GroupVertical label={t('pages:retailers.region')}>
              <Form.MultiSelect
                disabled={!is_brand_admin}
                options={regions.map(r => ({ value: r.id, label: r.name }))}
                name="region"
                isMulti={false}
              />
            </Form.GroupVertical>
            <Wrapper d-flex justify-content-end>
              <Form.Submit>{t('app:create')}</Form.Submit>
            </Wrapper>
          </Form>
        )}
      </Modal.ClosableBody>
    </Modal>
  );
};

const ManageWelcomeScreen = ({ welcome_screens, fetched }) => {
  const { t } = useTranslation(['pages']);

  return (
    <Container className="mt-5">
      <Table loading={!fetched} data={welcome_screens} columns={columns.welcome_screen}>
        <Table.Header
          subtitle={t('manageWelcomeScreen.tableSubtitle')}
          title={t('manageWelcomeScreen.tableTitle')}
        >
          <AddModal />
        </Table.Header>
        <Table.Content entity="welcome_screen" deletable />
      </Table>
    </Container>
  );
};

ManageWelcomeScreen.propTypes = {
  welcome_screens: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
};

export default entityHOC(ManageWelcomeScreen, entities.welcome_screen);
