import CoreServiceRambla from './CoreServiceRambla';

class StoreService extends CoreServiceRambla {
  constructor(endpoint) {
    super(endpoint);
  }

  fetchStores() {
    return this.api.get('/stores');
  }

  updateStore(uid, is_private) {
    return this.api.patch(`/stores/${uid}`, {
      is_private,
    });
  }
}

const instance = new StoreService('/v1');

export default instance;
