/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { Card, Page } from 'components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toastr } from 'utils';
import { brandSelector, isAvedaSelector, regionsSelector } from 'redux/auth/selectors';
import { useTranslation } from 'react-i18next';
import FeaturedArtists, { iFrameCode as FeaturedArtistsIFrame } from './FeaturedArtists';
import NewLooks, { iFrameCode as NewLooksIFrame } from './NewLooks';
import TrendingLooks, { iFrameCode as TrendingLooksIFrame } from './TrendingLooks';

export default function AdminPreview() {
  const [region] = useSelector(regionsSelector);
  const brand = useSelector(brandSelector);
  const isAveda = useSelector(isAvedaSelector);
  const [{ featureArtistsCount, trendingLooksCount, newLooksCount }, setWidgetsCount] = useState({
    featureArtistsCount: null,
    trendingLooksCount: null,
    newLooksCount: null,
  });

  const handleWidgetsCountChange = (property, selectedOption) => {
    setWidgetsCount(prevCount => ({
      ...prevCount,
      [property]: selectedOption?.value,
    }));
  };

  const featuredArtistsOnChange = selectedOption => {
    handleWidgetsCountChange('featureArtistsCount', selectedOption);
  };

  const trendingLooksOnChange = selectedOption => {
    handleWidgetsCountChange('trendingLooksCount', selectedOption);
  };

  const newLooksOnChange = selectedOption => {
    handleWidgetsCountChange('newLooksCount', selectedOption);
  };

  return (
    <Page
      title="Embeddable widgets"
      description="Bring your website to life with embeddable widgets"
      avedaPage={isAveda}
      withBackButton={false}
    >
      <Card
        title="Featured Artists"
        subtitle="Showcase featured artists in carousel style widget. The artists shown are the same as selected on the Landing page"
        toggle
        className="w-100 row"
      >
        <Col md={6}>
          <FeaturedArtists
            preview
            brand={brand.uid}
            region={region.uid}
            widgetCount={featureArtistsCount}
          />
        </Col>
        <Col md={6}>
          <CodeSnippet
            code={FeaturedArtistsIFrame(region.uid, brand.uid, featureArtistsCount)}
            onChange={featuredArtistsOnChange}
          />
        </Col>
      </Card>
      <Card
        title="Trending Looks"
        subtitle="Showcase a few of the most popular looks from a two month period"
        toggle
        className="w-100 row"
      >
        <Col md={6}>
          <TrendingLooks
            preview
            brand={brand.uid}
            region={region.uid}
            widgetCount={trendingLooksCount}
          />
        </Col>
        <Col md={6}>
          <CodeSnippet
            code={TrendingLooksIFrame(region.uid, brand.uid, trendingLooksCount)}
            onChange={trendingLooksOnChange}
          />
        </Col>
      </Card>
      <Card
        title="New Looks"
        subtitle="Showcase some of the new looks created by artists in the ELC Social Shoppe program. Looks have to be at least 24hrs old, have at least three products and contain a description value to qualify"
        toggle
        className="w-100 row"
      >
        <Col md={6}>
          <NewLooks preview brand={brand.uid} region={region.uid} widgetCount={newLooksCount} />
        </Col>
        <Col md={6}>
          <CodeSnippet
            code={NewLooksIFrame(region.uid, brand.uid, newLooksCount)}
            onChange={newLooksOnChange}
          />
        </Col>
      </Card>
    </Page>
  );
}

// code snippet with copy button
const CodeSnippet = ({ code, onChange }) => {
  const { t } = useTranslation(['app', 'toastr']);
  const options = Array.from(Array(10).keys()).map(num => ({
    value: num + 1,
    label: (num + 1).toString(),
  }));

  return (
    <Card title="Code Snippet" subtitle="Click code to copy to clipboard">
      <Select closeMenuOnSelect options={options} onChange={onChange} type="string" isClearable />
      <pre
        role="button"
        tabIndex={0}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            navigator.clipboard.writeText(code);
            toastr.success('', t('toastr:linkCopy'));
          }
        }}
        onClick={() => {
          navigator.clipboard.writeText(code);
          toastr.success('', t('toastr:linkCopy'));
        }}
        style={{ backgroundColor: 'lightgray', color: 'black', fontSize: '0.6rem' }}
        className="p-2"
      >
        <code>{code}</code>
      </pre>
    </Card>
  );
};

CodeSnippet.propTypes = {
  code: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
