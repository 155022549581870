import produce from 'immer';
import {
  RAMBLA_GENERATE_CATEGORIES_SUCCESS,
  RAMBLA_GENERATE_COLORS_SUCCESS,
  RAMBLA_GENERATE_INVENTORIES_SUCCESS,
  RAMBLA_GENERATE_SIZES_SUCCESS,
} from 'redux/products/constants';
import { RAMBLA_LOGIN_USER_SUCCESS } from '../auth/constants';
import {
  RAMBLA_FETCH_BRAND_SUCCESS,
  RAMBLA_CREATE_BRAND_SUCCESS,
  RAMBLA_UPDATE_BRAND_SUCCESS,
  RAMBLA_UPDATE_THEME_SUCCESS,
} from './constants';

export const initialState = {
  brand: { fetched: false, data: null },
  theme: { fetched: false, data: null },
};

const brandReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case RAMBLA_LOGIN_USER_SUCCESS:
        draft.brand.fetched = true;
        draft.brand.data = payload?.rambla_user?.admin_details?.brand;
        draft.theme.fetched = true;
        draft.theme.data = payload?.rambla_user?.admin_details?.brand?.theme;
        break;
      case RAMBLA_FETCH_BRAND_SUCCESS:
        draft.brand.fetched = true;
        draft.brand.data = payload;
        break;
      case RAMBLA_CREATE_BRAND_SUCCESS:
        draft.brand.data = payload;
        draft.theme.data = payload?.theme;
        break;
      case RAMBLA_UPDATE_BRAND_SUCCESS:
        draft.brand.data = payload;
        break;
      case RAMBLA_UPDATE_THEME_SUCCESS:
        draft.theme.data = payload;
        break;
      case RAMBLA_GENERATE_COLORS_SUCCESS:
        draft.brand.data = { ...draft.brand.data, is_product_colors_generated: true };
        break;
      case RAMBLA_GENERATE_SIZES_SUCCESS:
        draft.brand.data = { ...draft.brand.data, is_product_sizes_generated: true };
        break;
      case RAMBLA_GENERATE_CATEGORIES_SUCCESS:
        draft.brand.data = { ...draft.brand.data, is_product_categories_generated: true };
        break;
      case RAMBLA_GENERATE_INVENTORIES_SUCCESS:
        draft.brand.data = { ...draft.brand.data, is_product_inventories_generated: true };
        break;
      default:
        return state;
    }
  });

export default brandReducer;
