import React from 'react';
import PropTypes from 'prop-types';

const ImageWrapper = ({ children }) => (
  <div className="d-flex justify-content-center">
    <div className="w-50 py-4">{children}</div>
  </div>
);

ImageWrapper.propTypes = {
  children: PropTypes.node,
};

export default ImageWrapper;
