import { Form } from 'react-bootstrap';
import styled from 'styled-components/macro';

export const ErrorFeedback = styled(Form.Control.Feedback)`
  display: block;
  margin-top: 0.25rem;
  color: var(--bs-red);
`;

export const InputDescription = styled(Form.Control.Feedback).attrs({
  className: 'text-muted',
})`
  display: block;
  margin-top: 0.25rem;
  text-align: left;
`;

export const Checkbox = styled(Form.Check)`
  input:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const RadioButton = styled(Form.Check)`
  input:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
  input.form-check-input {
    margin-left: 0;
    margin-right: 8px;
  }
  input.form-check-input: checked {
    color: #ffaaaa;
    border-color: #ffaaaa;
  }
  accent-color: #232323;
  border-radius: 8px;
  padding: 0 0;
  border: 6px solid rgba(255, 240, 240, 0);
  &:hover {
    border: 6px solid rgba(255, 240, 240, 1);
    cursor: pointer;
    background-color: rgba(255, 240, 240, 1);
  }
  transition: background-color 0.4s ease-in-out border 0.4s ease-in-out;
`;

export const Dropzone = styled.div`
  border: 1px dotted #d2ddec;
  outline: none;
  height: 100px;
  position: relative;
  border-radius: 0.375rem;
  p {
    font-size: 13px;
    position: absolute;
    top: calc(50% - 7px);
    width: 100%;
  }
`;

export const PreviewThumbnailWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`;

export const FilePreviewThumbnail = styled.div`
  max-width: 200px;
  padding-right: 40px;
  min-height: 50px;
  overflow-wrap: break-word;
  position: relative;

  button {
    position: absolute;
    top: -10px;
    right: -10px;
    border: 1px solid #cccccc;
  }
`;

export const Input = styled.div`
  display: flex;
  width: 100%;
  input {
    ${({ mask }) =>
      mask &&
      `
        border: none;
        font-weight: bold;
        :hover {
          cursor: pointer;
          background-color: #e9ecef;
        }

        :focus {
          border: 1px solid #ced4da;
          :hover {
            cursor: pointer;
            background-color: transparent;
          }
        }
      `}
  }
`;

export const Caption = styled.div`
  min-height: 150px;
  border: 1.5px dashed;
`;
