import React from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CardContainer, ImageWrapper } from 'components';
import { ONBOARDING_BRAND_INFORMATION } from 'constants/routes';
import { ReactComponent as Icon } from '../../assets/img/illustrations/onboarding.svg';

const OnboardingPage = () => {
  const { t } = useTranslation(['pages']);

  return (
    <CardContainer lg="10">
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.title')}</h1>
      <h2 className="display-5 text-center">{t('onboarding.subtitle')}</h2>
      <ImageWrapper>
        <Icon />
      </ImageWrapper>
      <Link to={ONBOARDING_BRAND_INFORMATION}>
        <Button className="mt-5" block variant="primary" type="submit">
          {t('onboarding.buttonText')}
        </Button>
      </Link>
    </CardContainer>
  );
};

export default OnboardingPage;
