import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import useEntityForm from 'hooks/useEntityForm';
import retailerStatus from 'constants/retailerStatus';
import { groupBy, pick } from 'lodash';
import * as Roles from 'constants/roles';

const AdminForm = ({ type = 'create', initialValues, FormGroup, wrapperType }) => {
  const { t } = useTranslation(['pages', 'app']);
  const { onSubmit, dependencies, validationSchema } = useEntityForm(type, 'admin', {
    dependencies: ['region', 'brand'],
  });

  const regionsByBrandId = groupBy(dependencies.region, 'brand');
  const getRole = () => Roles.DEFAULT_ROLE;

  return (
    <Form
      initialValues={
        {
          role: getRole(),
          ...pick(initialValues, ['email', 'name', 'brand', 'regions', 'id', 'uid', 'status']),
        } || {
          email: '',
          name: '',
          regions: [],
          brand: null,
          role: getRole(),
        }
      }
      onSubmit={values => {
        if (type === 'create') {
          values = {
            ...values,
            status: retailerStatus.INVITATION_PENDING,
            is_brand_admin: values.role === Roles.DEFAULT_ROLE,
          };
        }
        onSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      <FormWrapper type={wrapperType === 'modal' ? 'create' : type}>
        <FormGroup label={t('app:email')}>
          <Form.Input type="text" name="email" disabled={type === 'update'} />
        </FormGroup>
      </FormWrapper>

      <FormWrapper type={wrapperType === 'modal' ? 'create' : type}>
        <FormGroup label={t('app:name')}>
          <Form.Input type="text" name="name" />
        </FormGroup>
        <FormGroup label="Role">
          <Form.MultiSelect
            options={[{ value: Roles.DEFAULT_ROLE, label: 'Brand Admin' }]}
            name="role"
            isMulti={false}
            disabled={type !== 'create'}
          />
        </FormGroup>
        <FormGroup label={t('app:entities.brand')}>
          <Form.Select
            options={dependencies.brand.map(item => ({
              value: item.id,
              label: item.name,
            }))}
            name="brand"
          />
          {/* <CreateEntityModal
            title={t('pages:brands.createBrand')}
            subTitle={t('pages:brands.brands')}
            entity="brand"
            trigger="link"
          /> */}
        </FormGroup>
        <FormGroup label={t('app:entities.region')}>
          {!!dependencies.region.length && (
            <Form.DependentField
              Component={Form.MultiSelect}
              name="regions"
              dependencyName="brand"
              calculateOptions={brand_id =>
                regionsByBrandId[brand_id]?.map(item => ({
                  value: item.id,
                  label: item.name,
                })) || []
              }
            />
          )}
          {/* <CreateEntityModal
            title={t('pages:regions.createRegion')}
            subTitle={t('pages:regions.regions')}
            entity="region"
            trigger="link"
          /> */}
        </FormGroup>
      </FormWrapper>

      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit>{t('app:send')}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

AdminForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  wrapperType: PropTypes.string,
};

export default AdminForm;
