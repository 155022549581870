import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React from 'react';
import { PropTypes } from 'prop-types';
import EntityService from 'api/services/EntityService';
import entities from 'constants/entities';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import { useTranslation } from 'react-i18next';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { toastr } from 'utils';
import { entityActions } from 'redux/entities/actions';
import * as Yup from 'yup';
import { uploadMediaWithInitialState } from 'utils/mediaResourceUtils';
import { ImageUpload } from 'components/Form/inputs/ImageUpload/ImageUpload';
import { brandSelector } from 'redux/auth/selectors';

const wrapperStyleObject = {
  border: '1px solid black',
  marginBottom: '2rem',
};

const editorStyleObject = {
  minHeight: 300,
};

const prepareAttachments = async (attachments, initialValues) => {
  const jsonArray = attachments.map(async attachment => {
    const uploaded = await uploadMediaWithInitialState([attachment], initialValues);
    return {
      type: uploaded?.format === 'pdf' ? 'PDF' : uploaded.resource_type,
      url: uploaded.secure_url || uploaded.url,
    };
  });
  return Promise.all(jsonArray);
};

const LearningCenterPostForm = ({ type = 'update', initialValues }) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(stateFromHTML(initialValues.body)),
  );
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'app']);
  const { preview } = useSelector(brandSelector);
  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    cover_image: Yup.string().required('Required'),
  });

  const onSubmitHandler = async values => {
    const uploadedAttachments = await prepareAttachments(
      values.attachments,
      initialValues.attachments,
    );
    const { secure_url } = await uploadMediaWithInitialState(values.cover_image); // NOTE: diffrent than in other places -> sending url to backend, not public_id
    const mappedValues = {
      ...values,
      cover_image: secure_url,
      attachments: [
        ...uploadedAttachments,
        ...(values.youtube ? [{ url: values.youtube, type: 'youtube' }] : []),
      ],
      body: stateToHTML(editorState.getCurrentContent()),
    };
    await EntityService.update(entities.learning_center_post, values.uid, mappedValues);
    toastr.success(t('learning_center:postUploadSuccess'));
    dispatch(entityActions.fetchEntity(entities.learning_center));
  };

  const mappedInitialValues = {
    ...initialValues,
    images: [
      initialValues.cover_image,
      ...initialValues.attachments
        .map(attachment => attachment?.url)
        .filter(url => url !== undefined),
    ],
    cover_image: [initialValues.cover_image],
    attachments: [
      ...initialValues.attachments
        .filter(el => el?.type !== 'youtube')
        .map(attachment => attachment?.url)
        .filter(url => url),
    ],
    youtube: initialValues.attachments
      .filter(el => el?.type === 'youtube')
      .map(attachment => attachment?.url)
      .filter(el => el)[0],
  };
  return (
    <Form
      initialValues={mappedInitialValues}
      onSubmit={values => onSubmitHandler(values)}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <Editor
          placeholder={t(`learning_center:${preview}.postPlaceholder`)}
          editorState={editorState}
          wrapperStyle={wrapperStyleObject}
          editorStyle={editorStyleObject}
          toolbar={{
            image: {
              className: 'd-none',
              urlEnabled: false,
              uploadEnabled: false,
              uploadCallback: false,
            },
          }}
          onEditorStateChange={state => setEditorState(state)}
        />
        <Form.GroupHorizontal label={t(`learning_center:${preview}.postTitle`)}>
          <Form.Input name="title" type="text" />
        </Form.GroupHorizontal>
        <Form.GroupHorizontal label={t(`learning_center:${preview}.postCoverPhoto`)}>
          <ImageUpload accept="image/*" name="cover_image" maxFiles={1} />
        </Form.GroupHorizontal>
        <Form.GroupHorizontal label={t('learning_center:postAttachments')}>
          <ImageUpload accept="" name="attachments" maxFiles={8} />
        </Form.GroupHorizontal>
        <Form.GroupHorizontal label={t('learning_center:youtubeLink')}>
          <Form.Input name="youtube" type="text" />
        </Form.GroupHorizontal>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

LearningCenterPostForm.propTypes = {
  type: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default LearningCenterPostForm;
