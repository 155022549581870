import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Form } from 'components';
import {
  LOCATION_ENABLED,
  LOCATION_DISABLED,
  LOCATION_SUPPORT,
} from 'constants/brandConfiguration';
import { ONBOARDING_BRAND_TRACKING } from 'constants/routes';

import useEntityForm from 'hooks/useEntityForm';
import { brandSelector } from 'redux/brand/selectors';
import { updateBrandConfiguration } from 'redux/brand/actions';
import FormHelpBox from 'components/Form/formHelpBox';

const OnboardingLocationManagementForm = ({ type = 'update', initialValues }) => {
  const { t } = useTranslation(['pages', 'app']);
  const dispatch = useDispatch();
  const [helpContentKey, setHelpContentKey] = useState(null);
  const { validationSchema } = useEntityForm(type, 'locationManagement');

  const nextStep = ONBOARDING_BRAND_TRACKING;
  const flagName = 'location_management';

  const content = [
    { key: LOCATION_ENABLED, helpText: t('pages:locationManagement.locationEnabledHelp') },
    { key: LOCATION_DISABLED, helpText: t('pages:locationManagement.locationDisabledHelp') },
    { key: LOCATION_SUPPORT, helpText: t('pages:locationManagement.integrationHelp') },
    { key: 'default', helpText: t('pages:locationManagement.defaultHelp') },
  ];

  const brand = useSelector(brandSelector);

  const resetHelpContentKey = () => {
    setHelpContentKey(null);
  };

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, ['uid', flagName]),
        } || {
          flagName: null,
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={async ({ location_management }) => {
        const { uid } = brand.data.config;
        dispatch(
          updateBrandConfiguration({
            uid,
            configuration: { location_management },
            nextStep,
          }),
        );
      }}
      validationSchema={validationSchema}
    >
      <Form.Group id={flagName} label={t('pages:locationManagement.name')}>
        <Form.RadioButton
          id={`option-${LOCATION_ENABLED}`}
          name={flagName}
          value={LOCATION_ENABLED}
          label={t('pages:locationManagement.locationEnabledRadioLabel')}
          handleMouseOver={() => setHelpContentKey(LOCATION_ENABLED)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${LOCATION_DISABLED}`}
          name={flagName}
          value={LOCATION_DISABLED}
          label={t('pages:locationManagement.locationDisabledRadioLabel')}
          handleMouseOver={() => setHelpContentKey(LOCATION_DISABLED)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${LOCATION_SUPPORT}`}
          name={flagName}
          value={LOCATION_SUPPORT}
          label={t('pages:locationManagement.integrationRadioLabel')}
          handleMouseOver={() => setHelpContentKey(LOCATION_SUPPORT)}
          handleMouseOut={() => resetHelpContentKey()}
        />
      </Form.Group>
      <FormHelpBox helpContentKey={helpContentKey} content={content} valueName={flagName} />
      <Form.Submit className="mt-5" size="lg" variant="primary" withArrows block>
        {t('onboarding.nextStep')}
      </Form.Submit>
    </Form>
  );
};

OnboardingLocationManagementForm.propTypes = {
  type: PropTypes.oneOf(['update']),
  initialValues: PropTypes.object,
};

export default OnboardingLocationManagementForm;
