import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { brandConfigSelector } from 'redux/brand/selectors';

import { CardContainer, ImageWrapper } from 'components';
import { OnboardingCommissionTrackingForm } from 'containers/forms';
import { ONBOARDING_SELLER_ACCESS } from 'constants/routes';

import { ReactComponent as Icon } from '../../assets/img/illustrations/commission-tracking.svg';
const previousStep = ONBOARDING_SELLER_ACCESS;

const OnboardingCommissionTrackingPage = () => {
  const { t } = useTranslation(['pages']);

  const brandConfig = useSelector(brandConfigSelector);

  return (
    <CardContainer lg="8" backButtonTarget={previousStep}>
      <Helmet>
        <title>{t('onboarding.commissionTrackingTitle')}</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.commissionTrackingTitle')}</h1>
      <h3 className="display-5 text-left">{t('onboarding.commissionTrackingSubtitle')}</h3>
      <ImageWrapper>
        <Icon />
      </ImageWrapper>
      <OnboardingCommissionTrackingForm initialValues={brandConfig} />
    </CardContainer>
  );
};

export default OnboardingCommissionTrackingPage;
