import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Image, Button } from 'react-bootstrap';
import { push } from 'connected-react-router';
import * as moment from 'moment';
import { entityActions } from 'redux/entities/actions';
import { redirectCell, redirectCellV4 } from 'functions/redirectCell';
import { CreateEntityModal, QrCode } from 'components';
import EditVariantComponent from 'containers/ProductVariant/EditVariant';
import PrimaryVariantCheckbox from 'containers/ProductVariant/PrimaryVariantCheckbox';
import history from 'utils/history';

import EntityService from 'api/services/EntityService';
import { toastr } from 'utils';
import { MdCheckCircle } from 'react-icons/md';
import { FaTimesCircle } from 'react-icons/fa';
import config from 'config';
import { selectLocation } from 'redux/locations/actions';
import { updateRetailerRambla } from 'redux/retailers/actions';
import { updateStoreRambla } from 'redux/stores/actions';
import i18n from '../i18n';
import { CREATE_PRODUCT, PRODUCTS, PRODUCTS_GROUPS, RETAILERS, TIERS, REGIONS } from './routes';
import retailerStatus from './retailerStatus';
import stylistStatus from './stylistStatus';
import store from '../configureStore';
import entities from './entities';
import Placeholder from '../assets/img/covers/placeholder.jpg';
import ramblaPlaceholder from '../assets/img/rambla-placeholder.jpg';

const formatDate = (cellValue, withTime = false) => {
  const momentDate = moment(cellValue);
  const timeAppend = withTime ? ' HH:mm' : '';

  if (!cellValue) {
    return null;
  }
  return momentDate.format(`DD/MM/YYYY${timeAppend}`);
};

const getBadge = status => {
  switch (status) {
    case retailerStatus.ACTIVE:
      return 'success';
    case retailerStatus.INACTIVE:
      return 'secondary';
    case retailerStatus.REJECTED:
      return 'warning';
    case retailerStatus.BANNED:
      return 'danger';
    case retailerStatus.INVITATION_PENDING:
      return 'info';
    default:
      break;
  }
};

const getStylistBadge = status => {
  switch (status) {
    case stylistStatus.APPROVED:
      return 'success';
    case stylistStatus.NEW_FEEDBACK:
    // fallthrough
    case stylistStatus.WAIT_FOR_FEEDBACK:
      return 'warning';
    case stylistStatus.SUBMIT_FOR_APPROVAL:
    // fallthrough
    case stylistStatus.REJECTED:
    // fallthrough
    default:
      return 'danger';
  }
};

const getPageStatusString = status => {
  switch (status) {
    case stylistStatus.APPROVED:
      return i18n.t('ownerPortal:allStylists.approved');
    case stylistStatus.WAIT_FOR_FEEDBACK:
      return i18n.t('ownerPortal:allStylists.review');
    case stylistStatus.REJECTED:
      return i18n.t('ownerPortal:allStylists.rejected');
    case stylistStatus.NEW_FEEDBACK:
      return i18n.t('ownerPortal:allStylists.newFeedback');
    case stylistStatus.SUBMIT_FOR_APPROVAL:
      return i18n.t('ownerPortal:allStylists.submitForApproval');
    default:
      return i18n.t('ownerPortal:allStylists.defaultStatus');
  }
};

const columns = {
  benefit: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  brand: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:entities.brand'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  bundle: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  claim: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  parent_product: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:category'),
      accessor: 'category.name',
    },
    {
      Header: i18n.t('app:details'),
      accessor: 'details',
    },
  ],
  child_product: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:parentProduct'),
      accessor: 'parent_product.name',
      // TODO: uncomment if there is a need to edit parent product from child product table
      // renderCell: cell => (
      //   <Sidebar
      //     trigger={
      //       <Button variant="link" className="text-center w-100">
      //         {cell.render('Cell')}
      //       </Button>
      //     }
      //     header={{
      //       title: i18n.t('pages:parentProducts.updateParentProduct'),
      //       subTitle: i18n.t('pages:parentProducts.parentProduct'),
      //     }}
      //     sidebar
      //   >
      //     <EntityForm
      //       initialValues={cell.row.original.parent_product}
      //       entity="parent_product"
      //       type="update"
      //     />
      //   </Sidebar>
      // ),
    },
    {
      Header: i18n.t('app:productCode'),
      accessor: 'product_code',
    },
    {
      Header: i18n.t('app:price'),
      accessor: 'price',
      renderCell: cell => (
        <p className="m-0">
          {`${cell.row.original.prices?.[0]?.price || cell.row.original.price} ${cell.row.original
            .prices?.[0]?.currency || 'USD'} `}
        </p>
      ),
    },
    {
      Header: i18n.t('app:picture'),
      accessor: 'picture',
      renderCell: cell => (
        <Image
          className="rounded shadow-sm"
          style={{ width: 40, height: 40 }}
          src={cell.row.original.picture || Placeholder}
        />
      ),
    },
  ],
  color: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: 'hex',
      accessor: 'hex_value',
    },
    {
      Header: i18n.t('app:entities.color'),
      accessor: '',
      renderCell: data => (
        <div
          className="rounded shadow-sm"
          style={{ width: 32, height: 32, backgroundColor: data.row.original.hex_value }}
        />
      ),
    },
  ],
  size: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
  ],
  color_category: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
  ],
  category: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
  ],
  inventory: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
  ],
  gtin: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  ingredient: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:entities.ingredient'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  multipack: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  pattern: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  product: [
    {
      sortable: false,
      accessor: 'category_name',
      renderCell: () => null,
    },
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
    // {
    //   Header: i18n.t('app:entities.product_group'),
    //   accessor: 'product_groups',
    //   renderCell: cell => redirectCell(cell, PRODUCTS_GROUPS),
    // },
    {
      sortable: false,
      Header: i18n.t('app:image'),
      accessor: 'image',
      renderCell: cell => (
        <img
          src={cell?.value[0] || Placeholder}
          style={{ width: 50, height: 50 }}
          alt="product preview"
        />
      ),
    },

    {
      Header: i18n.t('app:title'),
      accessor: 'name',
      renderCell: cell => (
        // eslint-disable-next-line
        <p className="m-0" dangerouslySetInnerHTML={{ __html: cell.row.original.name }} />
      ),
    },
    {
      sortable: false,
      Header: i18n.t('app:description'),
      accessor: 'description',
      renderCell: cell => (
        // eslint-disable-next-line
        <p className="m-0" dangerouslySetInnerHTML={{ __html: cell.row.original.description }} />
      ),
    },
    {
      Header: i18n.t('app:entities.category'),
      accessor: 'category',
      reference: { entity: entities.category, property: 'name' },
      renderCell: cell => {
        const { entities: libraries } = store.getState();
        const { data } = libraries[cell.column.reference.entity];

        const reference = data.find(
          item => item.id === cell.row.original[cell.column.reference.entity],
        );

        if (!reference) return null;
        return (
          <p
            className="m-0"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: reference[cell.column.reference.property] }}
          />
        );
      },
    },
    // {
    //   Header: i18n.t('app:entities.type'),
    //   accessor: 'type',
    // },
  ],
  product_group: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCellV4(cell, PRODUCTS, 'product_group_id'),
    },
    {
      Header: i18n.t('app:entities:tier'),
      sortable: false,
      accessor: 'tier_id',
      renderCell: () => null,
    },
    {
      Header: i18n.t('pages:tiers.tiers'),
      accessor: 'tiers',
      renderCell: cell => redirectCell(cell, TIERS),
    },
    {
      Header: i18n.t('pages:retailers.retailers'),
      accessor: 'retailers',
      renderCell: cell => redirectCellV4(cell, RETAILERS, 'product_group_id'),
    },
  ],
  region: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:entities.region'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:currency'),
      accessor: 'base_currency',
    },
    {
      Header: i18n.t('pages:regions.country'),
      accessor: 'country_code',
    },
  ],
  store: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('entities:short_id'),
      accessor: 'short_id',
    },
    {
      Header: i18n.t('entities:is_private'),
      accessor: 'is_private',
      renderCell: cell => (
        <p style={{ margin: '0' }}>{cell.value ? i18n.t('app:yes') : i18n.t('app:no')}</p>
      ),
    },
    {
      sortable: false,
      Header: i18n.t('entities:status'),
      accessor: 'statusChange',
      renderCell: cell => {
        const updateStoreStatus = (uid, is_private) => {
          store.dispatch(
            updateStoreRambla({
              uid,
              is_private,
            }),
          );
        };

        if (cell.row.original.is_private) {
          return (
            <Button
              variant="success"
              size="sm"
              style={{ minWidth: '80%' }}
              onClick={() =>
                updateStoreStatus(cell.row.original.uid, !cell.row.original.is_private)
              }
            >
              {i18n.t('pages:stores.publish')}
            </Button>
          );
        }
        return (
          <Button
            variant="danger"
            size="sm"
            style={{ minWidth: '80%' }}
            onClick={() => updateStoreStatus(cell.row.original.uid, !cell.row.original.is_private)}
          >
            {i18n.t('pages:stores.unpublish')}
          </Button>
        );
      },
    },
  ],
  retailer: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'user.name',
    },

    {
      Header: i18n.t('app:email'),
      accessor: 'email',
    },
    {
      Header: i18n.t('app:entities.region'),
      accessor: 'region.name',
    },
    {
      sortable: true,
      Header: i18n.t('columns:status'),
      accessor: 'status',
      renderCell: cell => (
        <span className={`badge bg-${getBadge(cell.row.original.status)}`}>
          {cell.row.original.status}
        </span>
      ),
    },
    {
      sortable: false,
      Header: 'Access',
      accessor: 'statusChange',
      renderCell: cell => {
        const updateRetailerStatus = (uid, email, status) => {
          store.dispatch(
            updateRetailerRambla({
              uid,
              email,
              status,
            }),
          );
        };

        switch (cell.row.original.status) {
          case retailerStatus.ACTIVE:
            return (
              <Button
                variant="danger"
                size="sm"
                style={{ minWidth: '80%' }}
                onClick={() =>
                  updateRetailerStatus(
                    cell.row.original.uid,
                    cell.row.original.email,
                    retailerStatus.INVITATION_PENDING,
                  )
                }
              >
                {i18n.t('pages:retailers.reject')}
              </Button>
            );
          case retailerStatus.INVITATION_PENDING:
            return (
              <Button
                variant="success"
                size="sm"
                style={{ minWidth: '80%' }}
                onClick={() =>
                  updateRetailerStatus(
                    cell.row.original.uid,
                    cell.row.original.email,
                    retailerStatus.ACTIVE,
                  )
                }
              >
                {i18n.t('pages:retailers.activate')}
              </Button>
            );
          default:
            break;
        }
      },
    },
  ],
  sku: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:entities.sku'),
      accessor: 'sku',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
  ],

  variant: [
    {
      Header: 'id',
      accessor: 'id',
      renderCell: cell => <EditVariantComponent cell={cell} />,
    },
    {
      Header: i18n.t('app:entities.upcCode'),
      accessor: 'upc_code',
    },
    {
      Header: i18n.t('app:entities.color'),
      accessor: 'color',
      renderCell: cell => <p className="m-0">{cell?.value?.name}</p>,
    },
    {
      Header: i18n.t('app:entities.size'),
      accessor: 'size',
      renderCell: cell => <p className="m-0">{cell?.value?.name}</p>,
    },
    {
      Header: i18n.t('pages:createProduct.price'),
      accessor: 'retail_price',
    },
    {
      Header: i18n.t('pages:createProduct.isPrimarySku'),
      accessor: 'is_primary',
      renderCell: cell => (
        <PrimaryVariantCheckbox
          variant={cell.row.original}
          editable={cell.row.original.is_editable}
        />
      ),
    },
  ],

  tier: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('app:users'),
      accessor: 'retailers',
      renderCell: cell => redirectCellV4(cell, RETAILERS, 'tier_id'),
    },

    {
      Header: i18n.t('app:entities.product_group'),
      accessor: 'product_groups',
      renderCell: cell => redirectCell(cell, PRODUCTS_GROUPS),
    },
  ],
  theme: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('pages:theme.coverPhoto'),
      accessor: 'cover_photo',
      renderCell: cell => (
        <div
          style={{
            backgroundImage: `url(${cell.value?.url})`,
            height: '60px',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      ),
    },
    {
      Header: i18n.t('pages:theme.themeName'),
      accessor: 'name',
    },
  ],
  admins: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('app:email'),
      accessor: 'email',
    },
    {
      Header: i18n.t('app:entities.region'),
      accessor: 'regions',
      renderCell: cell => redirectCell(cell, REGIONS),
    },
    {
      Header: i18n.t('app:entities.brand'),
      accessor: 'brand',
      reference: { entity: entities.brand, property: 'name' },
    },
    {
      Header: i18n.t('columns:status'),
      accessor: 'status',
      renderCell: cell => (
        <span className={`badge bg-${getBadge(cell.row.original.status)}`}>
          {cell.row.original.status}
        </span>
      ),
    },
  ],

  drafts: [
    {
      Header: i18n.t('columns:draftNr'),
      accessor: 'draftNr',
      renderCell: cell => (
        <Button
          variant="link"
          onClick={() => history.push(CREATE_PRODUCT, { initialValues: cell.row.original })}
        >
          {cell.row.original.draftNr}
        </Button>
      ),
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('columns:lastUpdate'),
      accessor: 'lastUpdate',
      renderCell: cell => formatDate(cell.value, true),
    },
  ],
  mediaResource: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      sortable: false,
      Header: i18n.t('app:image'),
      accessor: 'url',
      renderCell: cell => {
        const type = cell.row.original.resource_type;
        const isVideo = type === 'video' || type === 'youtube';
        return (
          <img
            className="rounded"
            src={isVideo ? cell.row.original.thumbnail_url : cell.value}
            style={{ maxHeight: '90px', maxWidth: '120px' }}
            alt="resource preview"
          />
        );
      },
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
    {
      Header: 'Expiration Date',
      accessor: 'is_deleted',
      sortType: (a, b) => {
        const aExpDate = new Date(a.original.expiration_date);
        const bExpDate = new Date(b.original.expiration_date);
        return aExpDate - bExpDate;
      },
      renderCell: cell => formatDate(cell.row.original.expiration_date, false),
    },
    {
      Header: i18n.t('columns:resourceType'),
      accessor: 'resource_type',
      renderCell: cell => (
        <Col>
          <p className="pb-0 mb-0">{cell.value}</p>
        </Col>
      ),
    },
  ],
  stylists: [
    {
      accessor: 'profile_img',
      sortable: false,
      renderCell: cell => (
        <Image
          className="rounded-circle bg-secondary"
          style={{ width: '80px', height: '80px' }}
          src={cell.row.original.connected_user?.picture || Placeholder}
        />
      ),
    },
    {
      Header: i18n.t('ownerPortal:allStylists.name'),
      accessor: 'name',
      renderCell: cell => {
        const name = cell.row.original.connected_user?.name || cell.row.original.name;
        const surname = cell.row.original.connected_user?.last_name || cell.row.original.surname;
        return <p className="m-0">{`${name || ''} ${surname || ''}`}</p>;
      },
    },
    {
      Header: i18n.t('ownerPortal:allStylists.salon'),
      accessor: 'salonName',
    },
    {
      Header: i18n.t('ownerPortal:allStylists.looks'),
      accessor: 'stores',
      renderCell: cell => <p className="text-center m-0 pr-4">{cell.value}</p>,
    },
    {
      Header: i18n.t('ownerPortal:allStylists.views'),
      accessor: 'views',
    },
    {
      Header: i18n.t('ownerPortal:allStylists.status'),
      accessor: 'profile_page_status',
      sortType: (a, b) => {
        const aString = getPageStatusString(a.values.profile_page_status);
        const bString = getPageStatusString(b.values.profile_page_status);
        return aString.localeCompare(bString);
      },
      renderCell: cell => (
        <>
          <span
            className={`badge bg-${getStylistBadge(
              cell.row.original.profile_page_status,
            )} p-2 mx-1`}
          >
            {' '}
          </span>
          <span>{getPageStatusString(cell.row.original.profile_page_status)}</span>
        </>
      ),
    },
    {
      Header: i18n.t('ownerPortal:allStylists.webShoppe'),
      sortable: false,
      accessor: 'url',
      renderCell: cell => {
        const { profile_page_status } = cell.row.original;
        switch (profile_page_status) {
          case stylistStatus.NEW_FEEDBACK:
          // fallthrough
          case stylistStatus.WAIT_FOR_FEEDBACK:
            return (
              <CreateEntityModal
                title="Review"
                subTitle="Approvals"
                type="approval"
                entity="stylist"
                actionType="review"
                trigger={
                  <Button style={{ minWidth: '80%' }} className="rounded-0" variant="dark">
                    {i18n.t('ownerPortal:allStylists.review')}
                  </Button>
                }
                initialValues={cell.row.original}
              />
            );
          case stylistStatus.APPROVED:
            return (
              <CreateEntityModal
                title="Edit Profile"
                subTitle="Artists"
                entity="stylist"
                wrapperType="modal"
                onSubmit={() => store.dispatch(entityActions.fetchEntity(entities.location))}
                trigger={
                  <Button style={{ minWidth: '80%' }} className="rounded-0" variant="dark">
                    {i18n.t('ownerPortal:allStylists.manage')}
                  </Button>
                }
                initialValues={cell.row.original}
              />
            );
          case stylistStatus.REJECTED:
          // fallthrough
          case stylistStatus.SUBMIT_FOR_APPROVAL:
          // fallthrough
          default:
            return null;
        }
      },
    },
  ],
  location: [
    {
      sortable: false,
      accessor: 'cover_photo',
      renderCell: cell => (
        <Image
          className="rounded"
          style={{ width: '120px', height: '80px', objectFit: 'cover' }}
          src={cell?.value || Placeholder}
        />
      ),
    },
    {
      Header: 'Salon Name',
      accessor: 'name',
      renderCell: cell => (
        <p className="m-0">
          {cell.row.original.name}
          <span className="m-0 ml-1 text-black-50">{cell.row.original.sap_id}</span>
        </p>
      ),
    },
    {
      Header: i18n.t('app:artists'),
      accessor: 'participants.length',
      // renderCell: cell => <p className="m-0 ml-2">{cell.row.original.participants.length}</p>,
    },
    {
      sortable: false,
      Header: i18n.t('ownerPortal:locations.location'),
      accessor: 'address',
      renderCell: cell => {
        const town = cell.row.original.address?.post_town;
        const streetName = cell.row.original.address?.street_name;
        const number = cell.row.original.address?.number_name;
        const state = cell.row.original.address?.state;
        return <p className="m-0 ml-2">{`${number} ${streetName} ${town}, ${state}`}</p>;
      },
    },
    {
      Header: i18n.t('app:views'),
      accessor: 'views',
    },
    {
      sortable: false,
      accessor: 'uid',
      renderCell: cell => (
        <Button
          variant="dark"
          className="rounded-0 text-center w-100"
          onClick={async () => {
            const selected = cell.row.original;
            store.dispatch(selectLocation(selected));
            store.dispatch(push(`/location/${cell.value}/page-edit`));
          }}
        >
          {cell.row.original?.page?.length
            ? i18n.t('ownerPortal:locations.cta')
            : i18n.t('ownerPortal:locations.ctaFirstTime')}
        </Button>
      ),
    },
  ],
  locationAdmin: [
    {
      sortable: false,
      accessor: 'cover_photo',
      renderCell: cell => (
        <Image
          className="rounded"
          style={{ width: '120px', height: '80px', objectFit: 'cover' }}
          src={cell?.value || Placeholder}
        />
      ),
    },
    {
      Header: 'Salon Name',
      accessor: 'name',
      renderCell: cell => (
        <p className="m-0">
          {cell.row.original.name}
          <span className="m-0 ml-1 text-black-50">{cell.row.original.sap_id}</span>
        </p>
      ),
    },
    {
      Header: i18n.t('app:artists'),
      accessor: 'participants.length',
      // renderCell: cell => <p className="m-0 ml-2">{cell.row.original.participants.length}</p>,
    },
    {
      sortable: false,
      Header: i18n.t('app:entities.location'),
      accessor: 'address',
      renderCell: cell => {
        const { post_town, street_name, post_code, state } = cell.row.original.address;
        return <p className="m-0 ml-2">{`${street_name} ${post_town}, ${state} ${post_code}`}</p>;
      },
    },
    {
      Header: 'QR Code',
      accessor: 'qr_code',
      sortable: false,
      renderCell: cell => {
        const vanity = cell.row.original.vanity_url;
        if (!vanity) return null;
        return <QrCode type="location" shortId={vanity} />;
      },
    },
    {
      Header: i18n.t('app:views'),
      accessor: 'views',
    },
    {
      sortable: false,
      accessor: 'outerSite',
      renderCell: cell => {
        const vanity = cell.row.original.vanity_url;
        if (!vanity) return null;
        return (
          <Button
            variant="dark"
            className="rounded-0 text-center w-100"
            href={`${config.avedaWebApp}/salon/${cell.row.original.vanity_url}?preview=true`}
            target="_blank"
          >
            {i18n.t('ownerPortal:locations.ctaAdmin')}
          </Button>
        );
      },
    },
    {
      sortable: false,
      accessor: 'uid',
      renderCell: cell => (
        <Button
          variant="dark"
          className="rounded-0 text-center w-100"
          onClick={() => store.dispatch(push(`/location/${cell.value}/page-edit`))}
        >
          {i18n.t('ownerPortal:locations.cta')}
        </Button>
      ),
    },
  ],
  manager: [
    {
      Header: i18n.t('ownerPortal:salonInfo.managersOwners.img'),
      accessor: 'connected_user',
      renderCell: cell => (
        <Image
          className="rounded-circle bg-secondary"
          style={{ width: '40px', height: '40px' }}
          src={cell.row.original.connected_user?.picture || ramblaPlaceholder}
        />
      ),
    },
    {
      Header: i18n.t('ownerPortal:salonInfo.managersOwners.user'),
      accessor: 'name',
      renderCell: cell => {
        const name = cell.row.original.connected_user?.name || cell.row.original.name;
        const surname = cell.row.original.connected_user?.last_name || cell.row.original.surname;
        const { email } = cell.row.original;
        return (
          <Col>
            <p className="m-0">{`${name} ${surname}`}</p>
            <p className="m-0 text-balck-50">{`${email}`}</p>
          </Col>
        );
      },
    },
    {
      Header: i18n.t('ownerPortal:salonInfo.managersOwners.role'),
      accessor: 'owner',
      renderCell: cell => {
        const color = cell.row.original.owner ? 'bg-warning' : 'bg-danger';
        const string = cell.row.original.owner ? 'owner' : 'manager';
        return <span className={`badge ${color}`}>{string}</span>;
      },
    },
  ],
  post: [
    {
      Header: 'Id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:title'),
      accessor: 'title',
    },
    {
      Header: i18n.t('app:content'),
      accessor: 'body',
      renderCell: cell => (
        // eslint-disable-next-line
        <p className="m-0" dangerouslySetInnerHTML={{ __html: cell.value }} />
      ),
    },
    {
      Header: i18n.t('pages:theme.coverPhoto'),
      accessor: 'cover_image',
      renderCell: cell => (
        <Image
          src={cell.value}
          style={{ width: 160, height: 90, objectFit: 'cover' }}
          className="rounded"
        />
      ),
    },
    {
      accessor: 'uid',
      renderCell: cell => {
        // eslint-disable-next-line
        const dispatch = useDispatch();
        return (
          <Button
            className="rounded-0"
            size="sm"
            variant="dark"
            onClick={async () => {
              await EntityService.delete('learning_center_post', cell.value);
              dispatch(entityActions.fetchEntity(entities.learning_center));
              toastr.success('post successfully deleted!');
            }}
          >
            {i18n.t('app:delete')}
          </Button>
        );
      },
    },
  ],
  bundleDashboard: [
    {
      sortable: false,
      Header: i18n.t('app:name'),
      accessor: 'info.name',
      renderCell: cell => {
        const { auth } = store.getState();
        return (
          <a
            style={{ color: 'black' }}
            target="_blank"
            href={`${auth.user.brand.configuration.short_url}/${
              cell.row.original.info.short_id
            }?preview=true`}
          >
            {cell.value}
          </a>
        );
      },
    },
    {
      sortable: false,
      Header: i18n.t('app:owner'),
      accessor: 'info',
      renderCell: cell => {
        const name = cell.value?.owner_name || '';
        const surname = cell.value?.owner_surname || '';
        return <p className="m-0">{`${name} ${surname}`}</p>;
      },
    },
    {
      sortable: false,
      Header: i18n.t('app:views'),
      accessor: 'dcount',
    },
  ],
  retailerBundle: [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'productsCount',
    },

    {
      Header: i18n.t('app:views'),
      accessor: 'views',
    },
    {
      Header: i18n.t('columns:publishedAt'),
      accessor: 'created_at',
      renderCell: formatDate,
    },
    {
      Header: 'QR code',
      accessor: 'qr',
      renderCell: cell => {
        const { short_id } = cell.row.original;
        return <QrCode shortId={short_id} type="bundle" />;
      },
    },
    {
      Header: 'Preview',
      accessor: 'url',
      renderCell: cell => (
        <a
          target="_blank"
          className="border-0 btn-transition btn btn-outline-primary"
          href={`${cell.row.original.url}?preview=true`}
        >
          {cell.row.original.url}
        </a>
      ),
      sortable: false,
    },
  ],
  learning_center: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'Language',
      accessor: 'language',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
  ],
  welcome_screen: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'Language',
      accessor: 'language',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
  ],
  look: [
    {
      Header: i18n.t('app:image'),
      sortable: false,
      accessor: 'pictures',
      renderCell: cell => (
        <Image
          className="rounded"
          style={{ width: '160px', height: '90px', objectFit: 'cover' }}
          src={cell.value[0] || Placeholder}
        />
      ),
    },
    {
      Header: 'id',
      accessor: 'created_at',
      hidden: true,
      renderCell: cell => new Date(cell.value).getTime(),
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('ownerPortal:Looks.enabled'),
      accessor: 'enabled',
      sortable: false,
      centered: true,
      renderCell: cell =>
        cell.value ? (
          <MdCheckCircle style={{ margin: '0 auto', display: 'block' }} size="20px" color="green" />
        ) : (
          <FaTimesCircle style={{ margin: '0 auto', display: 'block' }} size="18px" color="red" />
        ),
    },
    {
      Header: i18n.t('ownerPortal:Looks.visible'),
      accessor: 'is_visible',
      sortable: false,
      centered: true,
      renderCell: cell =>
        cell.value ? (
          <MdCheckCircle style={{ margin: '0 auto', display: 'block' }} size="20px" color="green" />
        ) : (
          <FaTimesCircle style={{ margin: '0 auto', display: 'block' }} size="18px" color="red" />
        ),
    },
    {
      Header: i18n.t('ownerPortal:Looks.draft'),
      accessor: 'is_draft',
      sortable: false,
      centered: true,
      renderCell: cell =>
        cell.value ? (
          <MdCheckCircle style={{ margin: '0 auto', display: 'block' }} size="20px" color="green" />
        ) : (
          <FaTimesCircle style={{ margin: '0 auto', display: 'block' }} size="18px" color="red" />
        ),
    },
    {
      Header: i18n.t('ownerPortal:Looks.productCount'),
      accessor: 'products_count',
      centered: true,
    },
    {
      Header: i18n.t('ownerPortal:Looks.viewsCount'),
      accessor: 'views_count',
      centered: true,
    },
  ],

  ourArtists: [
    {
      Header: 'Feature',
      accessor: 'checkbox',
      sortable: false,
    },
    {
      Header: 'Artist',
      accessor: 'name',
      renderCell: cell => (
        <div className="d-flex align-items-center">
          <img
            src={cell.row.original.image}
            style={{ width: 50, height: 50, marginRight: '10px' }}
            alt="product preview"
          />
          <p>{cell.row.original.name}</p>
        </div>
      ),
    },
    {
      Header: 'Salon',
      accessor: 'salonName',
      sortable: false,
    },
    {
      Header: 'Looks',
      accessor: 'looksCount',
      sortable: false,
    },
    {
      Header: 'Total Views',
      accessor: 'viewsCount',
      sortable: false,
    },
    {
      Header: i18n.t('columns:pageStatus'),
      accessor: 'profile_page_status',
      sortable: false,
      renderCell: cell => (
        <span className={`badge text-uppercase bg-${getStylistBadge(cell.value)}`}>
          {getPageStatusString(cell.value)}
        </span>
      ),
    },
    {
      Header: 'Preview',
      accessor: 'url',
      sortable: false,
      renderCell: cell =>
        cell.value ? (
          <a
            href={cell.value}
            target="_blank"
            rel="noopener"
            className="text-reset text-decoration-underline"
          >
            View page
          </a>
        ) : (
          '-'
        ),
    },
  ],
  customArtists: [
    {
      accessor: 'checkbox',
      sortable: false,
    },
    {
      Header: i18n.t(
        'ownerPortal:locations.addComponentModal.components.ourStylists.customStylistName',
      ),
      accessor: 'name',
      renderCell: cell => (
        <div className="d-flex align-items-center">
          <img
            src={cell.row.original.picture}
            style={{ width: 50, height: 50, marginRight: '10px' }}
            alt="user preview"
          />
          <p>{cell.row.original.name}</p>
        </div>
      ),
    },
    {
      Header: i18n.t(
        'ownerPortal:locations.addComponentModal.components.ourStylists.customStylistAbout',
      ),
      accessor: 'about',
      sortable: false,
    },
    {
      Header: i18n.t(
        'ownerPortal:locations.addComponentModal.components.ourStylists.customStylistLink',
      ),
      accessor: 'linkBtn',
      sortable: false,
    },
    {
      Header: i18n.t(
        'ownerPortal:locations.addComponentModal.components.ourStylists.customStylistRemove',
      ),
      accessor: 'remove',
      sortable: false,
    },
  ],
  batchRetailer: [
    {
      Header: i18n.t('pages:retailers.name'),
      accessor: 'first_name',
      sortable: false,
    },
    {
      Header: i18n.t('pages:retailers.lastName'),
      accessor: 'last_name',
      sortable: false,
    },
    {
      Header: i18n.t('pages:retailers.email'),
      accessor: 'email',
      sortable: false,
    },
  ],
};

export default columns;
