import { call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'utils';
import { upperFirst } from 'lodash';
import i18n from 'i18n';
import {
  RAMBLA_FETCH_RETAILERS,
  RAMBLA_INVITE_RETAILER,
  RAMBLA_UPDATE_RETAILER,
  RAMBLA_DELETE_RETAILER,
} from './constants';
import RetailersService from '../../api/services/RetailersService';
import {
  fetchRetailersRamblaSuccess,
  updateRetailerRamblaSuccess,
  deleteRetailerRamblaSuccess,
  inviteRetailerRamblaSuccess,
} from './actions';

// TODO: to be extracted to generic saga
function createError(error) {
  Object.values(error.response.data).forEach(message =>
    toastr.error(i18n.t('toastr:error'), upperFirst(message)),
  );
}

function* fetchRetailers() {
  try {
    const response = yield call([RetailersService, 'fetchRetailers']);

    yield put(fetchRetailersRamblaSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* updateRetailer(action) {
  const { uid, email, status, region_uid } = action.payload;

  try {
    const response = yield call(
      [RetailersService, 'updateRetailer'],
      uid,
      email,
      status,
      region_uid,
    );
    yield put(updateRetailerRamblaSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* deleteRetailer(action) {
  const { uid } = action.payload;

  try {
    yield call([RetailersService, 'deleteRetailer'], uid);
    yield put(deleteRetailerRamblaSuccess(uid));
  } catch (err) {
    createError(err);
  }
}

function* inviteRetailer(action) {
  const { region_uid, email } = action.payload;

  try {
    const response = yield call([RetailersService, 'inviteRetailer'], region_uid, email);
    yield put(inviteRetailerRamblaSuccess(response));
  } catch (err) {
    createError(err);
  }
}

const retailerSagas = [
  takeLatest(RAMBLA_FETCH_RETAILERS, fetchRetailers),
  takeLatest(RAMBLA_UPDATE_RETAILER, updateRetailer),
  takeLatest(RAMBLA_DELETE_RETAILER, deleteRetailer),
  takeLatest(RAMBLA_INVITE_RETAILER, inviteRetailer),
];

export { retailerSagas };
