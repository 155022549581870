import { Form } from 'react-bootstrap';
import styled from 'styled-components/macro';

export const ErrorFeedback = styled(Form.Control.Feedback)`
  display: block;
  margin-top: 0.25rem;
  color: var(--bs-red);
`;

export const Dropzone = styled.div`
  border: 1px dotted #d2ddec;
  outline: none;
  height: 100px;
  position: relative;
  border-radius: 0.375rem;
  p {
    font-size: 13px;
    position: absolute;
    top: calc(50% - 7px);
    width: 100%;
  }
  cursor: pointer;
  &:hover {
    background-color: var(--bs-primary-5);
  }
`;

export const PreviewThumbnailWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
`;

export const PreviewThumbnail = styled.div`
  width: 50%;
  height: auto;
  position: relative;
  border: 1px dashed var(--bs-primary-10);
  padding 8px;
  border-radius: 4px;
  background-color: var(--bs-primary-5);
  background-opacity: 0.1;


  img {
    max-width: 100%;
    max-height: 100%;
  }

  button {
    position: absolute;
    top: -10px;
    right: -10px;
    border: 1px solid #cccccc;
  }
`;
