import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Modal, Form } from 'components';
import { Button, Image } from 'react-bootstrap';
import stylistStatus from 'constants/stylistStatus';
import { useDispatch } from 'react-redux';
import { toastr } from 'utils';
import { entityActions } from 'redux/entities/actions';
import { useTranslation } from 'react-i18next';
import ApproveService from '../../api/services/ApproveService';
import EntityService from '../../api/services/EntityService';

const StylistForm = ({ initialValues, actionType = 'manage', onSubmit }) => {
  const [link, setLink] = React.useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation(['ownerPortal', 'app', 'toastr']);
  const isInReview = actionType === 'review';
  const name = {
    first_name: initialValues.connected_user.name || initialValues.name,
    last_name: initialValues.connected_user.last_name || initialValues.surname,
  };

  React.useEffect(() => {
    const fetch = async () => {
      const { url } = await EntityService.generateLink(initialValues.vanity_url);
      setLink(url);
    };
    fetch();
  }, []);

  const onRejectHandler = async () => {
    if (isInReview) {
      await ApproveService.disapprove(initialValues.connected_user.uid);
      toastr.warning(t('toastr:rejected'), t('artistPageRejected'));
    }
  };

  const onSubmitHandler = async isApproved => {
    if (isApproved || isInReview) {
      await ApproveService.approve(initialValues.connected_user.uid);
    } else {
      await ApproveService.review(initialValues.connected_user.uid, isApproved);
    }
    dispatch(
      entityActions.updateEntitySuccess('retailer', {
        ...initialValues,
        profile_page_status:
          isApproved || isInReview ? stylistStatus.APPROVED : stylistStatus.REJECTED,
      }),
    );
    if (onSubmit) onSubmit();
    toastr.success(
      '',
      isApproved || isInReview
        ? t('toastr:artistPageApproved')
        : t('toastr:artistPageStatusUpdated'),
    );
    if (isInReview) window.location.reload();
  };
  return (
    <Modal.ClosableBody>
      {({ closePopup }) => (
        <>
          <Wrapper d-flex justify-content-between align-items-center mb-4>
            <Image
              className="rounded bg-secondary mr-4"
              style={{
                maxWidth: '200px',
                maxHeight: '120px',
                minWidth: '120px',
                minHeight: '120px',
              }}
              src={initialValues.connected_user?.picture}
            />
            <Wrapper d-flex flex-column mr-5 align-items-start>
              <h1 className="mb-0">{`${name.first_name} ${name.last_name}`}</h1>
              <p className="m-0 mb-2">{initialValues.email}</p>
              <a
                className="p-2 bg-dark rounded text-light text-center"
                target="_blank"
                href={isInReview ? `${link}&feedback=true&preview=true` : `${link}?preview=true`}
              >
                {isInReview ? t('app:previewPage') : t('app:viewPage')}
              </a>
            </Wrapper>
          </Wrapper>
          {initialValues?.salonName && (
            <Wrapper d-flex flex-column ml-3>
              <b className="mr-3 w-50">{t('allStylists.associatedSalon')}</b>
              <p className="text-black-50">{initialValues?.salonName}</p>
            </Wrapper>
          )}
          <Form
            initialValues={{
              isApproved: initialValues.profile_page_status === stylistStatus.APPROVED,
            }}
            onSubmit={async ({ isApproved }) => {
              toastr.confirm(t('toastr:areYouSure'), {
                onOk: async () => {
                  await onSubmitHandler(isApproved);
                  closePopup();
                },
                dispatch,
              });
            }}
            className="justify-content-start"
          >
            {!isInReview && (
              <Wrapper d-flex justify-content-start>
                <Wrapper d-flex justify-content-center>
                  <Wrapper mx-3 d-flex flex-column>
                    <b>{t('app:approval')}</b>
                    <p className="font-weight-light text-black-50">{t('app:approvalEnabled')}</p>
                  </Wrapper>
                  <Form.Checkbox name="isApproved" />
                </Wrapper>
              </Wrapper>
            )}
            <Wrapper d-flex justify-content-end>
              {isInReview && (
                <Button
                  className="mr-3 bg-secondary border-0"
                  onClick={async () => {
                    toastr.confirm(t('toastr:areYouSureToRejectPage'), {
                      onOk: async () => {
                        onRejectHandler();
                        closePopup();
                      },
                      dispatch,
                    });
                  }}
                >
                  {t('app:reject')}
                </Button>
              )}
              <Form.Submit className="bg-dark border-0" onClick={closePopup}>
                {isInReview ? t('app:approve') : t('app:save')}
              </Form.Submit>
            </Wrapper>
          </Form>
        </>
      )}
    </Modal.ClosableBody>
  );
};
StylistForm.propTypes = {
  initialValues: PropTypes.object,
  actionType: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default StylistForm;
