import i18n from 'i18n';
import { upperFirst } from 'lodash';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { toastr } from 'utils';
import { DASHBOARD, ONBOARDING_REGION, ONBOARDING_THEME_CUSTOMIZATION } from 'constants/routes';
import BrandService from '../../api/services/BrandService';
import {
  createBrandSuccess,
  fetchBrandSuccess,
  updateBrandConfigurationSuccess,
  updateBrandSuccess,
  updateThemeSuccess,
} from './actions';
import {
  RAMBLA_FETCH_BRAND,
  RAMBLA_CREATE_BRAND,
  RAMBLA_UPDATE_BRAND,
  RAMBLA_UPDATE_THEME,
  RAMBLA_UPDATE_BRAND_CONFIGURATION,
} from './constants';

// TODO: to be extracted to generic saga
function createError(error) {
  Object.values(error.response?.data)?.forEach(message =>
    toastr.error(i18n.t('toastr:error'), upperFirst(message)),
  );
}

function* fetchBrand(action) {
  const { uid } = action.payload;

  try {
    const response = yield call([BrandService, 'fetchBrand'], uid);

    yield put(fetchBrandSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createBrand(action) {
  const { name, domain } = action.payload;

  try {
    const response = yield call([BrandService, 'createBrand'], name, domain);

    yield put(createBrandSuccess(response));
    yield put(push(ONBOARDING_THEME_CUSTOMIZATION));
  } catch (err) {
    createError(err);
  }
}

function* updateBrand(action) {
  const { uid, name, domain, is_onboarding_process_finished, no_redirect } = action.payload;

  try {
    const response = yield call(
      [BrandService, 'updateBrand'],
      uid,
      name,
      domain,
      is_onboarding_process_finished,
    );
    yield put(updateBrandSuccess(response));
    toastr.success(i18n.t('toastr:successUpdate'));
    !no_redirect ? yield put(push(DASHBOARD)) : null;
  } catch (err) {
    createError(err);
  }
}

function* updateTheme(action) {
  const { uid, color, picture_id, is_onboarding } = action.payload;

  try {
    const response = yield call([BrandService, 'updateTheme'], uid, color, picture_id);
    yield put(updateThemeSuccess(response));
    toastr.success(i18n.t('toastr:successUpdate'));
    is_onboarding ? yield put(push(ONBOARDING_REGION)) : yield put(push(DASHBOARD));
  } catch (err) {
    createError(err);
  }
}

function* updateBrandConfiguration(action) {
  const { uid, configuration, next_step } = action.payload;
  try {
    const response = yield call([BrandService, 'updateBrandConfiguration'], uid, configuration);
    yield put(updateBrandConfigurationSuccess(response));
    toastr.success(i18n.t('toastr:successUpdate'));
    yield put(push(next_step));
  } catch (err) {
    createError(err);
  }
}

const brandSagas = [
  takeLatest(RAMBLA_FETCH_BRAND, fetchBrand),
  takeLatest(RAMBLA_CREATE_BRAND, createBrand),
  takeLatest(RAMBLA_UPDATE_BRAND, updateBrand),
  takeLatest(RAMBLA_UPDATE_THEME, updateTheme),
  takeLatest(RAMBLA_UPDATE_BRAND_CONFIGURATION, updateBrandConfiguration),
];

export { brandSagas };
