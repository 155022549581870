// TODO: remove prefix after resolving entity saga
export const RAMBLA_FETCH_BRAND = 'RAMBLA_FETCH_BRAND';
export const RAMBLA_FETCH_BRAND_SUCCESS = 'RAMBLA_FETCH_BRAND_SUCCESS';
export const RAMBLA_CREATE_BRAND = 'RAMBLA_CREATE_BRAND';
export const RAMBLA_CREATE_BRAND_SUCCESS = 'RAMBLA_CREATE_BRAND_SUCCESS';
export const RAMBLA_UPDATE_BRAND = 'RAMBLA_UPDATE_BRAND';
export const RAMBLA_UPDATE_BRAND_SUCCESS = 'RAMBLA_UPDATE_BRAND_SUCCESS';
export const RAMBLA_UPDATE_THEME = 'RAMBLA_UPDATE_THEME';
export const RAMBLA_UPDATE_THEME_SUCCESS = 'RAMBLA_UPDATE_THEME_SUCCESS';
export const RAMBLA_UPDATE_BRAND_CONFIGURATION = 'RAMBLA_UPDATE_BRAND_CONFIGURATION';
export const RAMBLA_UPDATE_BRAND_CONFIGURATION_SUCCESS =
  'RAMBLA_UPDATE_BRAND_CONFIGURATION_SUCCESS';
