import { toastr } from 'utils';
import { upperFirst } from 'lodash';
import i18n from 'i18n';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ONBOARDING_CHECKOUT_TYPE } from 'constants/routes';
import {
  RAMBLA_FETCH_REGIONS,
  RAMBLA_CREATE_REGION,
  RAMBLA_UPDATE_REGION,
  RAMBLA_DELETE_REGION,
} from './constants';
import RegionService from '../../api/services/RegionService';
import {
  fetchRegionsSuccess,
  createRegionSuccess,
  updateRegionSuccess,
  deleteRegionSuccess,
} from './actions';

// TODO: to be extracted to generic saga
function createError(error) {
  Object.values(error.response?.data)?.forEach(message =>
    toastr.error(i18n.t('toastr:error'), upperFirst(message)),
  );
}

function* fetchRegions() {
  try {
    const response = yield call([RegionService, 'fetchRegions']);
    yield put(fetchRegionsSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* createRegion(action) {
  const {
    name,
    base_currency,
    base_language,
    country_code,
    brand_uid,
    is_onboarding,
  } = action.payload;

  try {
    const response = yield call(
      [RegionService, 'createRegion'],
      name,
      base_currency,
      base_language,
      country_code,
      brand_uid,
    );

    yield put(createRegionSuccess(response));
    if (is_onboarding) {
      yield put(push(ONBOARDING_CHECKOUT_TYPE));
    }
  } catch (err) {
    createError(err);
  }
}

function* updateRegion(action) {
  const { uid, name, base_currency, base_language, country_code, brand_uid } = action.payload;

  try {
    const response = yield call(
      [RegionService, 'updateRegion'],
      uid,
      name,
      base_currency,
      base_language,
      country_code,
      brand_uid,
    );

    yield put(updateRegionSuccess(response));
  } catch (err) {
    createError(err);
  }
}

function* deleteRegion(action) {
  const { uid } = action.payload;

  try {
    yield call([RegionService, 'deleteRegion'], uid);
    yield put(deleteRegionSuccess(uid));
  } catch (err) {
    createError(err);
  }
}

const regionSagas = [
  takeLatest(RAMBLA_FETCH_REGIONS, fetchRegions),
  takeLatest(RAMBLA_CREATE_REGION, createRegion),
  takeLatest(RAMBLA_UPDATE_REGION, updateRegion),
  takeLatest(RAMBLA_DELETE_REGION, deleteRegion),
];

export { regionSagas };
