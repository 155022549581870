import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Form } from 'components';
import FormHelpBox from 'components/Form/formHelpBox';

import {
  SELF_MANAGED_CATALOG,
  API_CATALOG_INTEGRATION,
  SHOPIFY_CATALOG_INTEGRATION,
  CATALOG_SUPPORT,
} from 'constants/brandConfiguration';
import { ONBOARDING_ATTRIBUTES_GENERATION, ONBOARDING_SELLER_ACCESS } from 'constants/routes';

import useEntityForm from 'hooks/useEntityForm';
import { brandSelector } from 'redux/brand/selectors';
import { updateBrandConfiguration } from 'redux/brand/actions';

const OnboardingProductCatalogForm = ({ type = 'update', initialValues }) => {
  const { t } = useTranslation(['pages', 'app']);
  const dispatch = useDispatch();
  const [helpContentKey, setHelpContentKey] = useState(null);
  const { validationSchema } = useEntityForm(type, 'productCatalog');

  const nextStepSelfManaged = ONBOARDING_ATTRIBUTES_GENERATION;
  const nextStep = ONBOARDING_SELLER_ACCESS;
  const flagName = 'product_catalog';

  const content = [
    { key: SELF_MANAGED_CATALOG, helpText: t('pages:productCatalog.selfManagedHelp') },
    { key: API_CATALOG_INTEGRATION, helpText: t('pages:productCatalog.apiHelp') },
    { key: SHOPIFY_CATALOG_INTEGRATION, helpText: t('pages:productCatalog.shopifyHelp') },
    { key: CATALOG_SUPPORT, helpText: t('pages:productCatalog.supportHelp') },
    { key: 'default', helpText: t('pages:productCatalog.defaultHelp') },
  ];

  const brand = useSelector(brandSelector);

  const resetHelpContentKey = () => {
    setHelpContentKey(null);
  };

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, ['uid', flagName]),
        } || {
          flagName: null,
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={async ({ product_catalog }) => {
        const { uid } = brand.data.config;
        switch (product_catalog) {
          case 'SELF_MANAGED':
            dispatch(
              updateBrandConfiguration({
                uid,
                configuration: { product_catalog },
                nextStep: nextStepSelfManaged,
              }),
            );
            return;
          default:
            dispatch(
              updateBrandConfiguration({ uid, configuration: { product_catalog }, nextStep }),
            );
        }
      }}
      validationSchema={validationSchema}
    >
      <Form.Group id={flagName} label={t('pages:productCatalog.name')}>
        <Form.RadioButton
          id={`option-${SELF_MANAGED_CATALOG}`}
          name={flagName}
          value={SELF_MANAGED_CATALOG}
          label={t('pages:productCatalog.selfManagedRadioLabel')}
          handleMouseOver={() => setHelpContentKey(SELF_MANAGED_CATALOG)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${API_CATALOG_INTEGRATION}`}
          name={flagName}
          value={API_CATALOG_INTEGRATION}
          label={t('pages:productCatalog.apiRadioLabel')}
          handleMouseOver={() => setHelpContentKey(API_CATALOG_INTEGRATION)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${SHOPIFY_CATALOG_INTEGRATION}`}
          name={flagName}
          value={SHOPIFY_CATALOG_INTEGRATION}
          label={t('pages:productCatalog.shopifyRadioLabel')}
          handleMouseOver={() => setHelpContentKey(SHOPIFY_CATALOG_INTEGRATION)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <Form.RadioButton
          id={`option-${CATALOG_SUPPORT}`}
          name={flagName}
          value={CATALOG_SUPPORT}
          label={t('pages:productCatalog.supportRadioLabel')}
          handleMouseOver={() => setHelpContentKey(CATALOG_SUPPORT)}
          handleMouseOut={() => resetHelpContentKey()}
        />
        <FormHelpBox helpContentKey={helpContentKey} content={content} valueName={flagName} />
      </Form.Group>
      <Form.Submit className="mt-5" size="lg" variant="primary" withArrows block>
        {t('onboarding.nextStep')}
      </Form.Submit>
    </Form>
  );
};

OnboardingProductCatalogForm.propTypes = {
  type: PropTypes.oneOf(['update']),
  initialValues: PropTypes.object,
};

export default OnboardingProductCatalogForm;
