import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import entities from 'constants/entities';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { Form, Modal, Button } from 'components';
import { uploadMedia } from 'utils/mediaResourceUtils';
import { useTranslation } from 'react-i18next';
import useEntityForm from 'hooks/useEntityForm';
import { locationsGroupSelector } from 'redux/locations/selectors';
import { ownerSelector } from 'redux/auth/selectors';
import { SocialShare } from 'pages/Locations/LocationsModals';
import { ImageUpload } from 'components/Form/inputs/ImageUpload/ImageUpload';
import { useOwnerGroupPageQrCode } from 'pages/Locations/hooks/useOwnerGroupPageQrCode';
import { useLocationGroupRetailers } from '../../hooks/useLocationGroupRetailers';

export const OwnerGroupForm = React.memo(({ locations }) => {
  const { t } = useTranslation(['ownerPortal']);
  const locationGroup = useSelector(locationsGroupSelector);
  const isOwnerUser = useSelector(ownerSelector);
  const qrCode = useOwnerGroupPageQrCode(locationGroup?.vanity_url);

  // eslint-disable-next-line
  const [image, setImage] = useState('');

  const { onSubmit, validationSchema } = useEntityForm(
    locationGroup?.vanity_url ? 'update' : 'create',
    entities.owner_group_page,
  );

  const retailersInputOptions = useLocationGroupRetailers();

  useEffect(() => {
    if (locationGroup) {
      setImage(locationGroup.logo_id);
    }
  }, [locationGroup]);

  const getHost = url => {
    const [host] = url.split('/g');
    return host;
  };

  const initialValues = useMemo(() => {
    if (locationGroup?.vanity_url) {
      return {
        name: locationGroup.name,
        vanity_url: locationGroup.vanity_url,
        logo_id: [],
        invite_retailers: locationGroup.assigned_retailers.map(({ uid }) => uid),
        uid: locationGroup.uid,
        assign_locations: locationGroup.locations.map(({ uid }) => uid),
      };
    }

    return {
      name: '',
      vanity_url: '',
      logo_id: [],
      invite_retailers: [],
      assign_locations: [],
    };
  }, [locationGroup]);

  const locationOptions = useMemo(() => {
    const userLocation = locations.map(item => ({
      value: item.uid,
      label: item.name,
    }));

    const pageGroupLocations = locationGroup?.locations
      ? locationGroup.locations.map(item => ({
          value: item.uid,
          label: item.name,
        }))
      : [];

    return [...userLocation, ...pageGroupLocations].reduce((unique, o) => {
      if (!unique.some(obj => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
  }, [locations, locationGroup]);

  return (
    <Form
      initialValues={initialValues}
      // enableReinitialize
      onSubmit={async values => {
        let payload = values;
        if (values.vanity_url === locationGroup?.vanity_url) {
          payload = omit(payload, ['vanity_url']);
        }

        if (values.logo_id.length) {
          const response = await uploadMedia(values.logo_id);
          payload = {
            ...payload,
            logo_id: response.public_id,
          };
        } else {
          payload = omit(payload, ['logo_id']);
        }

        onSubmit(payload);
      }}
      validationSchema={validationSchema}
    >
      <Form.GroupHorizontal label={t('locations.locationGroupPageName')}>
        <Form.Input type="text" name="name" />
      </Form.GroupHorizontal>
      <Form.GroupHorizontal label={t('locations.locationGroupPageVanityUrl')}>
        <Form.Input
          description={t('locations.locationGroupPageVanityUrlDescription', {
            path: locationGroup?.preview_url
              ? `${getHost(locationGroup.preview_url)}/g/[ YOUR_VANITY_URL ]`
              : '/g/[ YOUR_VANITY_URL ]',
          })}
          type="text"
          name="vanity_url"
        />
      </Form.GroupHorizontal>
      <Form.GroupHorizontal label={t('locations.logo')}>
        <ImageUpload name="logo_id" maxFiles={1} />
        {locationGroup?.logo && (
          <Image src={locationGroup.logo} className="rounded" style={{ maxWidth: '80px' }} />
        )}
      </Form.GroupHorizontal>

      <Form.GroupHorizontal label={t('locations.locationGroupPageLocations')}>
        <Form.MultiSelect options={locationOptions} name="assign_locations" isMulti withSelectAll />
      </Form.GroupHorizontal>
      {isOwnerUser && locationGroup?.vanity_url && (
        <Form.GroupHorizontal label={t('locations.locationGroupPageAssign')}>
          <Form.MultiSelect
            options={retailersInputOptions}
            name="invite_retailers"
            isMulti
            withSelectAll
          />
        </Form.GroupHorizontal>
      )}
      <Form.Submit className="mt-3" disabled={false} withOutSubmitAction>
        {locationGroup?.vanity_url
          ? t('locations.locationGroupPageUpdate')
          : t('locations.locationGroupPageCreate')}
      </Form.Submit>
      {locationGroup?.vanity_url && (
        <>
          <Button
            className="mt-3 ml-3"
            $type="avedaPrimaryOutline"
            onClick={() => window.open(locationGroup.preview_url, '_blank').focus()}
          >
            {t('locations.locationGroupPagePreview')}
          </Button>
          <Modal
            header={{
              title: t('locations.locationGroupPageShare'),
            }}
            trigger={() => (
              <Button className="mt-3 ml-3" variant="info">
                {' '}
                {t('locations.locationGroupPageShare')}
              </Button>
            )}
          >
            <SocialShare url={locationGroup.preview_url} qr={qrCode} />
          </Modal>
        </>
      )}
    </Form>
  );
});

OwnerGroupForm.propTypes = {
  locations: PropTypes.array.isRequired,
};
