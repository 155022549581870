import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';

import useEntityForm from 'hooks/useEntityForm';
import { FormWrapper } from 'components/Modal/styles';
import { brandSelector } from 'redux/brand/selectors';
import { Wrapper, Modal, Form, Button } from 'components';
import { createColor, updateColor } from 'redux/products/actions';

const ColorForm = ({ type = 'create', initialValues, FormGroup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'app']);
  const { validationSchema } = useEntityForm(type, 'color');
  const brand = useSelector(brandSelector);

  return (
    <Form
      initialValues={
        pick(initialValues, ['uid', 'name', 'hex_value']) || {
          name: '',
          hex_value: '',
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={({ uid, name, hex_value }) =>
        type === 'update'
          ? dispatch(updateColor(uid, name, hex_value))
          : dispatch(createColor(name, hex_value, brand.data?.uid))
      }
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={t('app:name')}>
          <Form.Input type="text" name="name" />
        </FormGroup>
        <FormGroup label={t('pages:colors.hex')}>
          <Form.ColorPicker name="hex_value" />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

ColorForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
};

export default ColorForm;
