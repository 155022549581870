import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from './RamblaSpinnerStyledComponents';

export const RamblaSpinner = ({ style }) => (
  <Spinner style={style}>
    <div className="wrapper">
      <div className="shape1" />
      <div className="shape2" />
      <div className="shape3" />
      <div className="shape4" />
    </div>
  </Spinner>
);

RamblaSpinner.propTypes = {
  style: PropTypes.object,
};

export default RamblaSpinner;
