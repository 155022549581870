import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Form } from 'components';
import countries from 'constants/countries';
import languages from 'constants/languages';
import { ONBOARDING } from 'constants/routes';
import * as Roles from 'constants/roles';
import useEntityForm from 'hooks/useEntityForm';
import { useUserRole } from 'hooks/useUserRole';
import { currencies } from 'constants/currenciesList';
import { createRegion, updateRegion } from 'redux/regions/actions';
import { brandSelector } from 'redux/brand/selectors';

const OnboardingRegionForm = ({ type = 'update', initialValues }) => {
  const { t } = useTranslation(['pages', 'app']);
  const dispatch = useDispatch();
  const location = useLocation();
  const { validationSchema } = useEntityForm(type, 'region');

  const userRole = useUserRole();
  const isSuperAdmin = userRole === Roles.DEFAULT_ROLE;
  const brand = useSelector(brandSelector);

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, ['uid', 'name', 'base_currency', 'country_code', 'base_language']),
        } || {
          name: null,
          base_currency: null,
          country_code: '',
          base_language: '',
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={({ uid, name, base_currency, base_language, country_code }) =>
        type === 'update'
          ? dispatch(
              updateRegion(uid, name, base_currency, base_language, country_code, brand.data?.uid),
            )
          : dispatch(
              createRegion({
                name,
                base_currency,
                base_language,
                country_code,
                brand_uid: brand.data?.uid,
                is_onboarding: location.pathname.includes(ONBOARDING),
              }),
            )
      }
      validationSchema={validationSchema}
    >
      <Form.Group label={t('pages:regions.name')}>
        <Form.Label>{t('regionRegistration.name')}</Form.Label>
        <Form.Input type="text" name="name" disabled={!isSuperAdmin} />
      </Form.Group>
      <Form.Group label={t('pages:regions.currency')}>
        <Form.Label>{t('regionRegistration.currency')}</Form.Label>
        <Form.MultiSelect
          options={currencies.map(currency => ({
            value: currency.code,
            label: currency.description,
          }))}
          name="base_currency"
          isMulti={false}
          disabled={!isSuperAdmin}
        />
      </Form.Group>
      <Form.Group label={t('pages:regions.country')}>
        <Form.Label>{t('regionRegistration.country')}</Form.Label>
        <Form.MultiSelect
          options={countries.map(country => ({
            value: country.code,
            label: country.name,
          }))}
          name="country_code"
          isMulti={false}
          disabled={!isSuperAdmin}
        />
      </Form.Group>
      <Form.Group label={t('pages:regions.language')}>
        <Form.Label>{t('regionRegistration.language')}</Form.Label>
        <Form.MultiSelect
          options={languages.map(language => ({
            value: language.value,
            label: language.name,
          }))}
          name="base_language"
          isMulti={false}
          disabled={!isSuperAdmin}
        />
      </Form.Group>
      <Form.Submit className="mt-5" size="lg" variant="primary" withArrows block>
        {t('onboarding.nextStep')}
      </Form.Submit>
    </Form>
  );
};

OnboardingRegionForm.propTypes = {
  type: PropTypes.oneOf(['update']),
  initialValues: PropTypes.object,
};

export default OnboardingRegionForm;
