import React from 'react';

import { Button } from 'components';
import store from 'configureStore';
import Sidebar from 'components/Modal/modal';
import EntityForm from 'containers/EntityForm/entityForm';

import capitalize from 'helpers/capitailze';
import { push } from 'connected-react-router';
import { API_V4_ENTITIES } from 'constants/entities';
import i18n from '../i18n';

export default (cell, entity, editable) => {
  if (cell.column.renderCell) {
    return cell.column.renderCell(cell);
  }
  if (cell.column.reference) {
    const { entities: libraries } = store.getState();
    const { data } = libraries[cell.column.reference.entity];

    const reference = data.find(
      item => item.id === cell.row.original[cell.column.reference.entity],
    );

    if (!reference) return null;
    return reference[cell.column.reference.property];
  }
  if (cell.column.id === 'id' && editable) {
    if (
      [
        'product',
        'theme',
        'brand_content',
        'location',
        'learning_center_post',
        'learning_center',
        'welcome_screen',
        'retailer',
      ].includes(entity)
    ) {
      let path = `${entity}/${cell.value}`;

      if (API_V4_ENTITIES.includes(entity)) {
        path = `${entity}/${cell.row.original.uid}`;
      }
      return (
        <Button
          variant="link"
          className="text-center w-100"
          onClick={() => store.dispatch(push(path))}
        >
          {cell.render('Cell')}
        </Button>
      );
    }
    return (
      <Sidebar
        trigger={
          <Button variant="link" className="text-center w-100">
            {cell.render('Cell')}
          </Button>
        }
        header={{
          title:
            entity === 'category'
              ? i18n.t(`pages:categories.update${capitalize(entity)}`)
              : entity === 'parent_product'
              ? i18n.t(`pages:parentProducts.updateParentProduct`)
              : entity === 'child_product'
              ? i18n.t(`pages:childProducts.updateChildProduct`)
              : i18n.t(`pages:${entity}s.update${capitalize(entity)}`),
          subTitle:
            entity === 'category'
              ? i18n.t(`pages:categories.category`)
              : entity === 'parent_product'
              ? i18n.t(`pages:parentProducts.parentProduct`)
              : entity === 'child_product'
              ? i18n.t(`pages:childProducts.childProduct`)
              : i18n.t(`pages:${entity}s.${entity}`),
        }}
        sidebar
      >
        <EntityForm initialValues={cell.row.original} entity={entity} type="update" />
      </Sidebar>
    );
  }

  return (
    <p
      style={{ margin: 0 }}
      className={
        (['id', 'dcount', 'views', 'sum', 'productsCount'].includes(cell.column.id) ||
          cell.column.centered) &&
        'text-center'
      }
    >
      {cell.render('Cell')}
    </p>
  );
};
