import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PageNotFound = ({ location }) => {
  const { t } = useTranslation(['pages']);

  return (
    <Container className="d-flex align-items-center vh-100">
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <div style={{ padding: '16px 36px' }}>
            <h1 className="display-1 text-center text-uppercase my-5">404</h1>
            <h1 className="display-4 text-center text-uppercase mb-3">{t('pageNotFound.title')}</h1>
            {location?.state ? (
              <p className="text-muted text-center mb-5">
                {t('pageNotFound.page')}
                <span>
                  <b className="text-secondary">{` ${location.state.from.pathname} `}</b>
                </span>
                {t('pageNotFound.subtitle')}
                <a href="/"> {t('pageNotFound.goBack')}</a>
              </p>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
PageNotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PageNotFound;
