import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Card } from 'components';
import { ramblaUserSelector } from 'redux/auth/selectors';

const UserProfile = () => {
  const { t } = useTranslation(['app']);
  const user = useSelector(ramblaUserSelector);

  const getData = () => {
    const data = [
      {
        label: t('app:name'),
        value: user.name,
      },
      {
        label: t('app:email'),
        value: user.email,
      },
      {
        label: t('app:entities.region'),
        value: user.admin_details?.brand?.regions.map(r => r.name).join(', '),
      },
      {
        label: t('app:entities.brand'),
        value: user.admin_details?.brand.name,
      },
    ];

    return data;
  };

  return (
    <Container className="px-5 mt-5 mx-auto">
      <Card title={t('app:profileTitle')} subtitle={t('app:profileSubtitle')}>
        <ul className="list-group">
          {getData().map(item => (
            <li className="list-group-item">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0 w-50">{item.label}</h4>
                <p className="m-0 w-50 text-right">{item.value}</p>
              </div>
            </li>
          ))}
        </ul>
      </Card>
    </Container>
  );
};

export default UserProfile;
