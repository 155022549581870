import CoreApiRambla from '../CoreApiRambla';

class CoreServiceRambla extends CoreApiRambla {
  constructor(endpoint) {
    super();

    if (endpoint) {
      this.endpoint = endpoint;
      this.setEndpointUrl(this.endpoint);
    }
  }

  setEndpointUrl(url) {
    this.api.defaults.baseURL = this.baseUrl + url;
    this.api.defaults.withCredentials = true;
  }
}

export default CoreServiceRambla;
