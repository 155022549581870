import {
  DELETE_PAGE_COMPONENT,
  INSERT_PAGE_COMPONENT,
  LOCATION_SELECT,
  UPDATE_SELECTED_LOCATION,
  MOVE_PAGE_COMPONENT,
  CHANGE_PAGE_COMPONENT,
  UPDATE_HEADER,
  SET_COMPONENT_EDITIONG,
  SET_SAVING,
  GET_LOCATION_GROUP,
  GET_LOCATION_GROUP_SUCCESS,
  UPDATE_LOCATION_GROUP_SUCCESS,
} from './constants';

export function selectLocation(location) {
  return {
    type: LOCATION_SELECT,
    payload: { location },
  };
}

export function insertComponent(component) {
  return {
    type: INSERT_PAGE_COMPONENT,
    payload: { name: component.name, nameTranslationPath: component.nameTranslationPath },
  };
}

export function deleteComponent(position) {
  return {
    type: DELETE_PAGE_COMPONENT,
    payload: { position },
  };
}

export function moveComponent(cuurentPosition, previousPosition) {
  return {
    type: MOVE_PAGE_COMPONENT,
    payload: { cuurentPosition, previousPosition },
  };
}

export function updateLocation() {
  return {
    type: UPDATE_SELECTED_LOCATION,
    payload: {},
  };
}

export function updateComponent(component) {
  return {
    type: CHANGE_PAGE_COMPONENT,
    payload: { component },
  };
}

export function updateHeader(location) {
  return {
    type: UPDATE_HEADER,
    payload: { location },
  };
}

export function raiseComponentEditingFlag(value) {
  return {
    type: SET_COMPONENT_EDITIONG,
    payload: { value },
  };
}

export function setSavingFlag(value) {
  return {
    type: SET_SAVING,
    payload: { value },
  };
}

export function getLocationGroup() {
  return {
    type: GET_LOCATION_GROUP,
  };
}

export function getLocationGroupSuccess(value) {
  return {
    type: GET_LOCATION_GROUP_SUCCESS,
    payload: value,
  };
}

export function updateLocationGroupSuccess(payload) {
  return {
    type: UPDATE_LOCATION_GROUP_SUCCESS,
    payload,
  };
}
