export const mapInitialValues = initialValues => {
  const mappedInitialValues = {
    thumbnail: [initialValues?.picture_id],
    images: [initialValues?.picture],
    parent_product_uid: initialValues?.parent_product?.uid,
    color_uid: initialValues?.color?.uid,
    size_uid: initialValues?.size?.uid,
    inventory_uid: initialValues?.inventory?.uid,
    ...initialValues?.prices?.reduce((acc, item) => {
      acc[item.region] = item.price;
      return acc;
    }, {}),
    ...initialValues,
  };

  return mappedInitialValues;
};
