export const mapInitialValues = initialValues => {
  const mappedInitialValues = {
    ...initialValues,
    thumbnail: initialValues?.logo_id ? [initialValues.logo_id] : [],
    images: initialValues?.logo ? [initialValues.logo] : [],
    color: initialValues?.colour || undefined,
  };

  return mappedInitialValues;
};
