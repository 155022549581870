export const LOCATION_SELECT = 'LOCATION_SELECT';
export const CHANGE_PAGE_COMPONENT = 'CHANGE_PAGE_COMPONENT';
export const UPDATE_SELECTED_LOCATION = 'UPDATE_SELECTED_LOCATION';
export const INSERT_PAGE_COMPONENT = 'INSERT_PAGE_COMPONENT';
export const DELETE_PAGE_COMPONENT = 'DELETE_PAGE_COMPONENT';
export const MOVE_PAGE_COMPONENT = 'MOVE_PAGE_COMPONENT';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const SET_COMPONENT_EDITIONG = 'SET_COMPONENT_EDITIONG';
export const SET_SAVING = 'SET_SAVING';
export const GET_LOCATION_GROUP = 'GET_LOCATION_GROUP';
export const GET_LOCATION_GROUP_SUCCESS = 'GET_LOCATION_GROUP_SUCCESS';
export const UPDATE_LOCATION_GROUP_SUCCESS = 'UPDATE_LOCATION_GROUP_SUCCESS';
