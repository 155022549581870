export const QUERY_PARAMS = 'QUERY_PARAMS';
export const PAYPAL = 'PAY_PAL';
export const SHOPIFY = 'SHOPIFY';
export const SUPPORT = 'SUPPORT';
export const SELF_MANAGED_CATALOG = 'SELF_MANAGED';
export const API_CATALOG_INTEGRATION = 'API_INTEGRATION';
export const SHOPIFY_CATALOG_INTEGRATION = 'SHOPIFY';
export const CATALOG_SUPPORT = 'SUPPORT';
export const EMAIL_INVITE = 'EMAIL';
export const EMAIL_INVITE_APPROVAL = 'EMAIL_APPROVAL';
export const SSO_INTEGRATION = 'SSO';
export const PUBLISHER_ID_REQUIRED = 'PUBLISHER_ID_REQUIRED';
export const PUBLISHER_ID_NOT_REQUIRED = 'PUBLISHER_ID_NOT_REQUIRED';
export const COMMISSION_TRACKING_SUPPORT = 'SUPPORT';
export const LOCATION_ENABLED = 'LOCATION_ENABLED';
export const LOCATION_DISABLED = 'LOCATION_DISABLED';
export const LOCATION_SUPPORT = 'SUPPORT';
export const BRAND_TRACKING_GTM_ID = 'GTM_ID';
export const BRAND_TRACKING_SUPPORT = 'SUPPORT';
