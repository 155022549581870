import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';

import useQuery from 'hooks/useQuery';
import { loginUser } from 'redux/auth/actions';
import { CardContainer, Form, Wrapper } from 'components';
import { REGISTER, FORGOT_PASSWORD, ROOT } from 'constants/routes';

const LoginPage = () => {
  const { t } = useTranslation(['pages']);
  const dispatch = useDispatch();
  const query = useQuery();
  const redirectAddress = query.get('redirectUrl') || ROOT;

  return (
    <CardContainer xs="12" md="8" lg="6">
      <h1 className="display-4 text-center text-uppercase mb-3">{t('login.title')}</h1>
      <p className="text-muted text-center mb-5">{t('login.subtitle')}</p>
      <Form
        initialValues={{
          email: '',
          password: '',
          countryCode: '',
          phoneNumber: '',
        }}
        onSubmit={async ({ email, password }) => {
          dispatch(loginUser(email, password, redirectAddress));
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required(t('validation:required')),
          password: Yup.string()
            .min(6, t('validation:atLeastChars', { number: 6 }))
            .required(t('validation:required')),
        })}
      >
        <Form.Group>
          <Form.Label>{t('login.email')}</Form.Label>
          <Form.Input name="email" type="email" placeholder="johndoe@rambla.com" />
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>{t('login.password')}</Form.Label>
            </Col>
          </Row>
          <Form.Password name="password" placeholder={t('login.passwordPlaceholder')} />
        </Form.Group>
        <Wrapper mb-3>
          <Form.Submit size="lg" variant="primary" block>
            {t('login.login')}
          </Form.Submit>
        </Wrapper>
        <Wrapper d-flex flex-column justify-content-center align-items-center>
          <Wrapper d-flex justify-content-center align-items-center mt-5 mb-3>
            <Link to={REGISTER} className="h3 text-muted">
              {`${t('login.dontHaveAcc')} ${t('login.signUp')}`}!
            </Link>
          </Wrapper>
          <Link to={`${FORGOT_PASSWORD}?admin=true`} className="h3 text-muted">
            {t('login.forgetPassword')}
          </Link>
        </Wrapper>
      </Form>
    </CardContainer>
  );
};

export default LoginPage;
