import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Card, Container, Col, Row, Nav, NavItem } from 'react-bootstrap';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { generatePath, Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Page } from 'components';
import { BRAND_INFORMATION, DASHBOARD, ONBOARDING, THEME_CUSTOMIZATION } from 'constants/routes';
import { ramblaIsOnboardingProcessFinishedSelector } from 'redux/brand/selectors';
import BrandInformationPage from './brandInformationPage';
import ThemeCustomizationPage from './themeCustomizationPage';
import { ReactComponent as BrandInfoIcon } from '../../assets/img/illustrations/brand-information.svg';
import { ReactComponent as ThemeIcon } from '../../assets/img/illustrations/theme-customization.svg';

const Tabs = ({ t }) => (
  <Nav variant="tabs" className="d-flex justify-content-between align-items-center mb-4">
    <div className="d-flex">
      <NavItem>
        <NavLink
          className="nav-link"
          activeClassName="active font-weight-bold"
          to={generatePath(BRAND_INFORMATION)}
          style={{ fontSize: 16 }}
        >
          {t('dashboard.brandInformation')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link"
          activeClassName="active font-weight-bold"
          exact
          to={generatePath(THEME_CUSTOMIZATION)}
          style={{ fontSize: 16 }}
        >
          {t('dashboard.themeCustomization')}
        </NavLink>
      </NavItem>
    </div>
  </Nav>
);

const DashboardPage = () => {
  const { t } = useTranslation(['pages']);
  const history = useHistory();
  const location = useLocation();
  const isOnboardingProcessFinished = useSelector(ramblaIsOnboardingProcessFinishedSelector);

  useEffect(() => {
    if (!isOnboardingProcessFinished) {
      history.push(ONBOARDING);
    }
  }, [isOnboardingProcessFinished]);

  return (
    <Page
      title={t('dashboard.dashboard')}
      description={t('dashboard.customizeYourBrand')}
      withBackButton={false}
    >
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      {location.pathname === DASHBOARD ? (
        <Container className="container-fluid m-0">
          <Row className="d-flex flex-row">
            <Col xs="12" md="12" lg="6" xl="5">
              <Card
                className="p-4 m-2 d-flex justify-content-center"
                onClick={() => history.push(BRAND_INFORMATION)}
                role="button"
              >
                <h2 className="text-center mb-4">{t('dashboard.brandInformation')}</h2>
                <div style={{ minHeight: '400px', display: 'flex', justifyContent: 'center' }}>
                  <BrandInfoIcon />
                </div>
              </Card>
            </Col>
            <Col xs="12" md="12" lg="6" xl="5">
              <Card
                className="p-4 m-2 d-flex justify-content-center"
                onClick={() => history.push(THEME_CUSTOMIZATION)}
                role="button"
              >
                <h2 className="text-center mb-4 w-100">{t('dashboard.themeCustomization')}</h2>
                <div style={{ minHeight: '400px', display: 'flex', justifyContent: 'center' }}>
                  <ThemeIcon />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Tabs t={t} />

          <Switch>
            <Route exact path={BRAND_INFORMATION} component={BrandInformationPage} />
            <Route exact path={THEME_CUSTOMIZATION} component={ThemeCustomizationPage} />
          </Switch>
        </>
      )}
    </Page>
  );
};

Tabs.propTypes = {
  t: PropTypes.func,
};

export default DashboardPage;
