import cx from 'classnames';
import { Card, Pagination, Spinner, Wrapper } from 'components';
import { PermissionContext } from 'components/common/ProtectedRoute';
import entities from 'constants/entities';
import renderCell from 'functions/renderCell';
import withDefaultProps from 'helpers/withDefaultProps';
import * as matchSorter from 'match-sorter';
import PropTypes, { arrayOf } from 'prop-types';
import * as qs from 'qs';
import React, { useState } from 'react';
import { Table as BootstrapTable, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toastr } from 'utils';
import { useLocation } from 'react-router';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import Popup from 'reactjs-popup';
import { entityActions } from 'redux/entities/actions';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrowDown.svg';
import { ReactComponent as ArrowNeutral } from '../../assets/svg/arrowNeutral.svg';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrowUp.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import RamblaLogo from '../../assets/img/rambla-logo-black.png';
import Search from '../Search/search';
import { FilterButton } from './components/FilterButton';
import Filter, { sortQuery } from './filters/filter';
import useUrlQueryParamFilter from './filters/useUrlQueryParamFilter';
import { TableHeader, TableRow, TableSubtitle, TableTitle } from './styles';

export const TableContext = React.createContext();
const PAGE_SIZE = 10;
let previousPage = 0;
let initialRun = true;

const Table = ({
  data,
  loading = false,
  columns,
  children,
  sortBy: initialSortBy = [],
  fetchData,
  dataCount,
  serverPagination = false,
  initialFilters = [],
  ...restProps
}) => {
  const memoizedColumns = React.useMemo(() => columns, [columns]);
  const memoizedData = React.useMemo(() => data, [data]);
  const isPaginable = (serverPagination ? dataCount : data?.length) > PAGE_SIZE;
  const browserParams = useLocation();

  const filteredValues = qs.parse(browserParams.search, { ignoreQueryPrefix: true });
  const initialFiltersWithQuery = [...initialFilters];

  Object.keys(filteredValues).forEach(key => {
    initialFiltersWithQuery.push({ id: key, value: filteredValues[key] });
  });

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
  }
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
      multiple: (rows, id, filterValue) =>
        rows.filter(row => {
          // we need to parse id to int because on backed side we have id as a number
          // and ids we get from url query string
          const rowValue = id[0] === 'id' ? `${row.original[id]}` : row.original[id];

          return rowValue !== undefined ? Object.values(filterValue).includes(rowValue) : true;
        }),
    }),
    [],
  );

  const useTableParams = {
    data: memoizedData,
    columns: memoizedColumns,
    initialState: { pageIndex: 0, filters: initialFiltersWithQuery, sortBy: initialSortBy },
    filterTypes,
  };

  if (serverPagination) {
    useTableParams.manualPagination = true;
    useTableParams.manualFilters = true;
    useTableParams.manualGlobalFilter = true;
    useTableParams.manualSortBy = true;
    useTableParams.autoResetPage = false;
    useTableParams.pageCount = Math.ceil(dataCount / PAGE_SIZE);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    visibleColumns,
    preFilteredRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, filters, globalFilter, sortBy },
  } = useTable(useTableParams, useFilters, useGlobalFilter, useSortBy, usePagination);

  const [queryFilters, removeQueryFilter] = useUrlQueryParamFilter(memoizedColumns, setFilter);

  // Server side filtering
  const searchOptions = filters.reduce(
    (params, { id, value }) => ({
      ...params,
      [id]: value,
    }),
    {},
  );
  if (globalFilter) {
    searchOptions.search = globalFilter;
  }
  if (sortBy.length) {
    const { id, desc } = sortBy[0];

    const { sortKey, sortValue } = sortQuery(id, desc);
    searchOptions[sortKey] = sortValue;
  }
  const searchOptionsString = Object.keys(searchOptions) // for memoization
    .map(key => [key, searchOptions[key]].join('='))
    .join('&');

  searchOptions.page = pageIndex + 1; // memoization without page to differantiate between changing filters and page

  React.useEffect(() => {
    if (!serverPagination || initialRun) {
      initialRun = false;
      return;
    }
    if (pageIndex !== previousPage) {
      // user changes page
      previousPage = pageIndex;
    } else if (pageIndex !== 0) {
      // user changes filter while on not initial page
      previousPage = 0;
      searchOptions.page = 1;
      gotoPage(0); // this would change pageIndex so we need to reset initialRun flag
      initialRun = true;
    }

    fetchData(searchOptions);
  }, [searchOptionsString, pageIndex]);
  const queryFiltersCount = Object.keys(filteredValues).length;
  React.useEffect(() => {
    if (serverPagination && queryFiltersCount > 0) {
      fetchData(searchOptions);
    }
  }, [queryFiltersCount]);

  const [value, setValue] = React.useState('');
  return (
    <TableContext.Provider
      value={{
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows: page,
        prepareRow,
        state,
        visibleColumns,
        preFilteredRows,
        preGlobalFilteredRows,
        dataCount: serverPagination ? dataCount || 0 : (preGlobalFilteredRows || []).length,
        setGlobalFilter,
        value,
        setValue,
        setFilter,
        pageOptions,
        gotoPage,
        pageIndex,
        queryFilters,
        removeQueryFilter,
        loading,
        isPaginable,
      }}
    >
      <Card noPadding {...restProps}>
        {children}
      </Card>
    </TableContext.Provider>
  );
};

Table.Header = function Header({ title, subtitle, children, ...restProps }) {
  return (
    <TableHeader {...restProps}>
      <div className="d-flex flex-column">
        <Table.Title>{title}</Table.Title>
        {subtitle && <TableSubtitle>{subtitle}</TableSubtitle>}
      </div>
      <div className="d-flex" style={{ gap: 12 }}>
        {children}
      </div>
    </TableHeader>
  );
};

Table.Title = function Title({ children, ...restProps }) {
  return <TableTitle {...restProps}>{children}</TableTitle>;
};

Table.Content = function Content({
  entity,
  deletable = true,
  editable = true,
  onDelete,
  onRowClick,
}) {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    pageOptions,
    gotoPage,
    pageIndex,
    loading,
    isPaginable,
  } = React.useContext(TableContext);
  const { permission } = React.useContext(PermissionContext);
  const NoData = () => <Image src={RamblaLogo} style={{ width: '50%', height: 'auto' }} />;

  const dispatch = useDispatch();
  const { t } = useTranslation(['toastr']);
  const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <BootstrapTable responsive hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers
                .filter(column => !column?.hidden)
                .map(column => {
                  const isCentered =
                    column.centered ||
                    ['id', 'dcount', 'views', 'sum', 'productsCount'].includes(column.id);
                  const isSortable = column.sortable === undefined ? true : column.sortable;
                  const sortedArrow = column.isSortedDesc ? <ArrowDown /> : <ArrowUp />;
                  return (
                    <th
                      {...column.getHeaderProps(
                        isSortable ? column.getSortByToggleProps() : undefined,
                      )}
                      className={cx({
                        'text-center': isCentered,
                        'position-relative': true,
                      })}
                    >
                      {column.render('Header')}
                      <span style={{ fontSize: 9, position: 'absolute', marginLeft: 2 }}>
                        {isSortable && (column.isSorted ? sortedArrow : <ArrowNeutral />)}
                      </span>
                    </th>
                  );
                })}
              {deletable && permission.delete && <th />}
            </tr>
          ))}
        </thead>
        {!!rows.length && !loading && (
          <tbody {...getTableBodyProps()}>
            {firstPageRows.map(row => {
              prepareRow(row);

              return (
                <TableRow
                  onClick={() => onRowClick && onRowClick(row.cells[0].row.original)}
                  clicable={!!onRowClick}
                  {...row.getRowProps()}
                >
                  {row.cells
                    .filter(cell => !cell.column?.hidden)
                    .map(cell => (
                      <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()}>
                        {renderCell(cell, entity, editable && permission.update)}
                      </td>
                    ))}
                  {deletable && permission.delete && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-link text-danger px-1"
                        onClick={() =>
                          toastr.confirm(t('toastr:areYouSure'), {
                            onOk: () => {
                              if (onDelete) {
                                onDelete(row.original);
                              } else {
                                dispatch(entityActions.deleteEntity(entity, row.original.uid));
                              }
                            },
                            dispatch,
                          })
                        }
                      >
                        <i className="fe fe-trash" />
                      </button>
                    </td>
                  )}
                </TableRow>
              );
            })}
          </tbody>
        )}
      </BootstrapTable>
      {loading && (
        <div className="my-5 d-flex justify-content-center">
          <Spinner />
        </div>
      )}
      {!rows.length && !loading && (
        <div className="mt-5 p-5  d-flex flex-column align-items-center">
          <NoData />
          <p className="text-muted mt-5">No data</p>
        </div>
      )}
      {!!rows.length && isPaginable && !loading && (
        <Pagination activePage={pageIndex} numberOfPages={pageOptions.length} goToPage={gotoPage} />
      )}
    </>
  );
};

Table.Filter = function FilterComponent({
  filters = [],
  withGlobalFilter = false,
  globalFilterProps,
  withHideIcon = true,
}) {
  const {
    preFilteredRows,
    setFilter,
    state: { filters: tableFilters },
    queryFilters,
    visibleColumns,
    removeQueryFilter,
  } = React.useContext(TableContext);
  const [curentlySelectedFilter, setCurentlySelectedFilter] = useState(withHideIcon ? [] : filters);

  const filterProps = { filters: tableFilters, setFilter, preFilteredRows };

  const onFilterClick = filter => {
    const filterAlreadyAdded = curentlySelectedFilter.find(f => f.column === filter.column);
    if (filterAlreadyAdded) {
      setFilter(filter.column, filter.defaultValue || '');
      setCurentlySelectedFilter(curentlySelectedFilter.filter(f => f.column !== filter.column));
    } else {
      setCurentlySelectedFilter([...curentlySelectedFilter, filter]);
    }
  };

  return (
    <div style={{ padding: '1rem 1.5rem' }}>
      <Wrapper d-flex>
        {withGlobalFilter && <Table.GlobalFilter {...globalFilterProps} />}
        {!!filters.length && withHideIcon && (
          <Popup
            closeOnDocumentClick
            trigger={FilterButton({
              number: curentlySelectedFilter.length + Object.keys(queryFilters).length,
            })}
            position="bottom right"
          >
            <>
              {/* FILTERS WHICH ARE APPLIED VIA QUERY PARAM BUT DOESNT HAVE INPUT TO CHANGE IT */}
              {Object.keys(queryFilters).map(filterName => {
                if (!filters.find(filter => filter.column === filterName)) {
                  return (
                    <div className="d-flex">
                      <label className="form-label">
                        {visibleColumns.find(column => column.id === filterName).Header}
                      </label>
                      <CloseIcon
                        style={{ color: 'var(--bs-red)', cursor: 'pointer' }}
                        onClick={() => removeQueryFilter(filterName)}
                      />
                    </div>
                  );
                }
                return null;
              })}

              {filters.map(filter => (
                // <Filter key={filter.column} {...filter} {...filterProps} />
                <button
                  type="button"
                  style={{ display: 'block' }}
                  className={`btn btn-${
                    curentlySelectedFilter.find(f => f.column === filter.column)
                      ? 'primary'
                      : 'white'
                  } px-4 my-3 w-100 text-capitalize`}
                  onClick={() => onFilterClick(filter)}
                >
                  {filter.name || filter.column}
                </button>
              ))}
            </>
          </Popup>
        )}
      </Wrapper>
      {!!curentlySelectedFilter.length && (
        <div className="d-flex flex-row align-items-end mt-3">
          <div className="d-flex flex-row flex-wrap" style={{ gap: 20 }}>
            {curentlySelectedFilter.map(filter => (
              <Filter key={filter.column} {...filter} {...filterProps} withCaption />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Table.GlobalFilter = function GlobalFilterComponent({ entity, style }) {
  const { dataCount, value, setValue, setGlobalFilter } = React.useContext(TableContext);
  const onChange = useAsyncDebounce(val => {
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <Search
      style={style}
      value={value}
      onChange={e => {
        setValue(e);
        onChange(e);
      }}
      placeholder={`Search ${dataCount} ${entity || 'record'}s...`}
    />
  );
};

// /////////* Proptypes *//////////

Table.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      Filter: PropTypes.func,
      filter: PropTypes.string,
    }),
  ),
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool.isRequired,
    }),
  ),
  ...withDefaultProps(),
};

Table.Header.propTypes = { ...withDefaultProps() };

Table.Title.propTypes = { ...withDefaultProps() };

Table.Content.propTypes = {
  entity: PropTypes.oneOf(Object.keys(entities)),
  deletable: PropTypes.bool,
  editable: PropTypes.bool,
  onDelete: PropTypes.func,
  onRowClick: PropTypes.func,
};

Table.Filter.propTypes = {
  filters: arrayOf(
    PropTypes.shape({
      column: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['slider', 'text', 'numberRange', 'select']),
    }),
  ),
  withGlobalFilter: PropTypes.bool,
  globalFilterProps: PropTypes.shape({
    entity: PropTypes.string,
    style: PropTypes.object,
  }),
  withHideIcon: PropTypes.bool,
};

Table.GlobalFilter.propTypes = {
  entity: PropTypes.string,
  style: PropTypes.object,
};

export default Table;
