import React, { useMemo, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { getCroppedImg } from 'utils/crop';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { toastr } from 'utils';
import { Button } from 'react-bootstrap';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
  Dropzone,
  ErrorFeedback,
  PreviewThumbnail,
  PreviewThumbnailWrapper,
} from './ImageUpload.styles';
import Placeholder from '../../../../assets/img/covers/placeholder.jpg';

const buttonProps = {
  style: {
    borderRadius: 0,
    fontSize: '2rem',
    padding: '0.5rem 3rem',
    marginTop: '1rem',
  },
  size: 'lg',
};

export const ImageUpload = ({
  maxFiles = 6,
  accept = 'image/*',
  aspectRatio = 1,
  withCrop = false,
  withThumbnails = true,
  onCrop,
  hintMessage = '',
  onCropStart = () => null,
  onCropEnd = () => null,
  setFile = () => null,
  ...props
}) => {
  const { t } = useTranslation('app');
  const [uploadingFile, setUploadingFile] = useState(null);
  const [crop, setCrop] = useState({
    aspect: aspectRatio,
    unit: '%',
    width: 50,
    x: 25,
    y: 25,
  });

  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [field, meta, { setValue }] = useField(props);
  // eslint-disable-next-line react/prop-types
  const [{ value: cloudinaryImages }] = useField({ name: props.imageField || 'images' });
  const isInvalid = meta.touched && meta.error;

  const onDropAccepted = async acceptedFiles => {
    if (withCrop) {
      return setUploadingFile(...acceptedFiles);
    }
    if (field.value?.length === maxFiles) {
      setValue([...field.value?.slice(acceptedFiles.length), ...acceptedFiles]);
    } else {
      setValue([...field.value, ...acceptedFiles]);
    }
  };

  const onDropRejected = async rejectedFiles =>
    rejectedFiles[0]?.errors.forEach(({ message }) => toastr.error(t('toastr:error'), message));

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    multiple: maxFiles > 1,
    onDropAccepted,
    onDropRejected,
  });

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const isCloudinaryFile = file => !file.path && !file.size;
  const handleFileDelete = file => {
    if (isCloudinaryFile(file)) {
      // TAKE DELETE Action
      setValue(field.value.filter(item => item !== file));
    } else {
      setValue(field.value.filter(item => item.name !== file.name));
    }
  };

  const cropSubmit = async () => {
    const img = imgRef.current;
    const cropped = completedCrop;
    onCropStart();
    const image = await getCroppedImg(img, cropped, uploadingFile.name, uploadingFile.type);
    onCropEnd();
    const file = new File([image], 'img', { type: uploadingFile.type });

    if (field.value?.length === maxFiles) {
      setValue([...field.value?.slice(1), file]);
    } else {
      setValue([...field.value, file]);
    }
    onCrop && onCrop(file);
    setUploadingFile(null);
  };

  const croppedFile = useMemo(() => {
    if (uploadingFile) {
      return URL.createObjectURL(uploadingFile?.url || uploadingFile);
    }
    return undefined;
  }, [uploadingFile]);

  React.useEffect(() => {
    if (uploadingFile && withCrop) {
      onCropStart();
    } else {
      onCropEnd();
    }
  }, [uploadingFile, withCrop]);

  React.useEffect(() => {
    const file = field.value.length > 0 ? field.value[0] : null;
    if (!file) {
      setFile(null);
      return;
    }
    const fileSrc = isCloudinaryFile(file)
      ? cloudinaryImages?.find(imageUrl => imageUrl.indexOf(file) > -1)?.replace('pdf', 'png') ||
        (cloudinaryImages?.length && cloudinaryImages[0]?.replace('pdf', 'png')) ||
        Placeholder
      : URL.createObjectURL(file?.url || file);
    setFile(fileSrc);
  }, [field.value, setFile]);

  return (
    <>
      {!props.disabled && (
        <>
          {uploadingFile && withCrop ? (
            <div
              // id="crop-wrapper"
              style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                zIndex: 9999,
                top: '0',
                left: '0',
                background: 'rgba(0,0,0,0.5)',
              }}
              className="d-flex flex-row p-0 m-0 justify-content-center align-items-center"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <ReactCrop
                  src={croppedFile}
                  crop={crop}
                  imageStyle={{
                    maxWidth: '100vmin',
                    maxHeight: '80vmin',
                    background: props.dark ? '#4A4A4A' : 'unset',
                  }}
                  onChange={newCrop => {
                    setCrop(newCrop);
                  }}
                  onImageLoaded={onLoad}
                  onComplete={c => {
                    setCompletedCrop(c);
                  }}
                />
                <div>
                  <Button {...buttonProps} variant="danger" onClick={() => setUploadingFile(null)}>
                    {t('app:cancel')}
                  </Button>
                  <Button {...buttonProps} variant="primary" onClick={cropSubmit}>
                    {t('app:crop')}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <Dropzone id="dropzone-wrapper" {...getRootProps({ refKey: 'innerRef' })}>
              <input id="dropzone" {...getInputProps()} disabled={props.disabled} />
              <p className="text-muted text-center">
                {maxFiles === 1 ? t('dropzonePlaceholderSingle') : t('dropzonePlaceholder')}
                {!!hintMessage && (
                  <>
                    <br /> {hintMessage}
                  </>
                )}
              </p>
            </Dropzone>
          )}
          {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
        </>
      )}
      {withThumbnails && (
        <PreviewThumbnailWrapper>
          {field.value
            .filter(x => x)
            .map(file => (
              <PreviewThumbnail key={file?.name}>
                {!props.disabled && (
                  <button
                    type="button"
                    className="btn-white rounded-circle"
                    disabled={props.disabled}
                    onClick={() => handleFileDelete(file)}
                    style={{ boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.1)' }}
                  >
                    <i className="fe fe-x" />
                  </button>
                )}
                <img
                  alt={file?.name || 'preview'}
                  style={{ background: props.dark ? '#4A4A4A' : 'unset' }}
                  src={
                    isCloudinaryFile(file)
                      ? cloudinaryImages
                          ?.find(imageUrl => imageUrl.indexOf(file) > -1)
                          ?.replace('pdf', 'png') ||
                        (cloudinaryImages?.length && cloudinaryImages[0]?.replace('pdf', 'png')) ||
                        Placeholder
                      : URL.createObjectURL(file?.url || file)
                  }
                />
              </PreviewThumbnail>
            ))}
        </PreviewThumbnailWrapper>
      )}
    </>
  );
};

ImageUpload.propTypes = {
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  dark: PropTypes.bool,
  withCrop: PropTypes.bool,
  aspectRatio: PropTypes.number,
  withThumbnails: PropTypes.bool,
  onCrop: PropTypes.func,
  hintMessage: PropTypes.string,
  onCropStart: PropTypes.func,
  onCropEnd: PropTypes.func,
  setFile: PropTypes.func,
};
