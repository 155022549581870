import CoreServiceRambla from './CoreServiceRambla';

class BrandService extends CoreServiceRambla {
  constructor(endpoint) {
    super(endpoint);
  }

  fetchBrand(uid) {
    return this.api.get(`/brands/${uid}`);
  }

  createBrand(brand_name, domain) {
    return this.api.post('/brands', {
      name: brand_name,
      domain,
    });
  }

  updateBrand(brand_uid, brand_name, domain, is_onboarding_process_finished) {
    return this.api.patch(`/brands/${brand_uid}`, {
      name: brand_name,
      domain,
      is_onboarding_process_finished,
    });
  }

  updateTheme(uid, color, public_id) {
    return this.api.patch(`/brand-themes/${uid}`, {
      colour: color,
      logo_id: public_id,
    });
  }

  updateBrandConfiguration(uid, configuration) {
    return this.api.patch(`/brand-configs/${uid}`, configuration);
  }

  sendBrandConfigurationSummary(uid) {
    return this.api.post(`/brand-configs/send-summary/${uid}`);
  }
}

const instance = new BrandService('/v1');

export default instance;
