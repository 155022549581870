import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import 'react-phone-input-2/lib/bootstrap.css';
import * as Yup from 'yup';

import useQuery from 'hooks/useQuery';
import { registerUser } from 'redux/auth/actions';
import { ADMIN_TOOL_LOGIN } from 'constants/routes';
import { CardContainer, Form, Wrapper } from 'components';

const RegisterPage = () => {
  const { t } = useTranslation(['pages']);
  const dispatch = useDispatch();
  const query = useQuery();

  return (
    <CardContainer xs="12" md="8" lg="6">
      <h1 className="display-4 text-center text-uppercase mb-3">
        {t('adminRegistration.register')}
      </h1>
      <p className="text-muted text-center mb-5">{t('adminRegistration.subtitle')}</p>
      <Form
        initialValues={{
          name: query.get('name') || '',
          last_name: query.get('last_name') || '',
          email: query.get('email') || '',
          password: '',
          password_repeat: '',
          countryCode: '',
        }}
        onSubmit={async values => {
          dispatch(registerUser(values));
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(3, t('validation:atLeastChars', { number: 3 }))
            .required(t('validation:required')),
          last_name: Yup.string()
            .min(3, t('validation:atLeastChars', { number: 3 }))
            .required(t('validation:required')),
          email: Yup.string()
            .email('Invalid email address')
            .required(t('validation:required')),
          password: Yup.string()
            .min(8, t('validation:atLeastChars', { number: 8 }))
            .required(t('validation:required')),
          password_repeat: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
        })}
      >
        <Form.Group>
          <Form.Label>{t('adminRegistration.name')}</Form.Label>
          <Form.Input name="name" type="text" placeholder="John" />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t('adminRegistration.lastName')}</Form.Label>
          <Form.Input name="last_name" type="text" placeholder="Doe" />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t('adminRegistration.email')}</Form.Label>
          <Form.Input name="email" type="email" placeholder="johndoe@rambla.com" />
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>{t('adminRegistration.password')}</Form.Label>
            </Col>
          </Row>
          <Form.Input
            type="password"
            name="password"
            placeholder={t('adminRegistration.passwordPlaceholder')}
          />
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>{t('adminRegistration.rePassword')}</Form.Label>
            </Col>
          </Row>
          <Form.Input
            type="password"
            name="password_repeat"
            placeholder={t('adminRegistration.rePasswordPlaceholder')}
          />
        </Form.Group>

        <Wrapper mb-3>
          <Form.Submit size="lg" variant="primary" block>
            {t('adminRegistration.signUp')}
          </Form.Submit>
        </Wrapper>
        <Wrapper d-flex justify-content-center align-items-center mt-5>
          <Link to={ADMIN_TOOL_LOGIN} className="h3 text-muted">
            {t('adminRegistration.backToLogin')}
          </Link>
        </Wrapper>
      </Form>
    </CardContainer>
  );
};

export default RegisterPage;
