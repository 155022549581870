import styled from 'styled-components';
import { media } from 'utils/rwd';

export const Ctr = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Wrapper = styled.div`
  background: black;
  ${w => w.widget && 'height: 100vh;'}
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  ${media.lg`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

export const Container = styled(Ctr)`
  padding-right: 0;
`;

export const SectionTitle = styled.h1`
  font-size: 40px;
  margin-bottom: 20px;
  color: #fff;
`;

export const StylistRow = styled.div`
  display: flex;
  flex-wrap: ${s => s.widget && 'no'}wrap;
  ${s => s.widget && 'overflow-x: auto;'}
  ${s =>
    s.widget &&
    `-ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
    display: none;
    }`}
  gap: 20px 10px;
`;

export const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row;
  align-content: center;
  position: relative;

  .BrainhubCarousel__customArrows:nth-child(1) {
    position: absolute;
    left: 0;
    top: calc(50% - 30px);
    z-index: 1;
    cursor: pointer;
  }

  .BrainhubCarousel__customArrows:nth-child(3) {
    position: absolute;

    ${media.lg`
      right: 20px;
    `}
    cursor: pointer;
    right: 5px;
    top: calc(50% - 30px);
    z-index: 1;
  }
`;

export const DotsWrapper = styled.div`
  margin-top: 30px;

  ${media.lg`
    margin-top: 40px;
  `}

  .BrainhubCarousel__dot:before {
    width: 6px;
    height: 6px;
    background: white;

    ${media.lg`
      width: 15px;
      height: 15px;
    `}
  }
`;
