import produce from 'immer';
import {
  RAMBLA_CREATE_REGION_SUCCESS,
  RAMBLA_DELETE_REGION_SUCCESS,
  RAMBLA_FETCH_REGIONS_SUCCESS,
  RAMBLA_UPDATE_REGION_SUCCESS,
} from './constants';

export const initialState = { fetched: false, data: [] };

const regionReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case RAMBLA_FETCH_REGIONS_SUCCESS:
        draft.data = payload;
        draft.fetched = true;
        break;
      case RAMBLA_CREATE_REGION_SUCCESS:
        draft.data.push(payload);
        break;
      case RAMBLA_UPDATE_REGION_SUCCESS:
        draft.data = draft.data.map(region => (region.uid === payload.uid ? payload : region));
        break;
      case RAMBLA_DELETE_REGION_SUCCESS:
        draft.data = draft.data.filter(region => region.uid !== payload.uid);
        break;
      default:
        return state;
    }
  });

export default regionReducer;
