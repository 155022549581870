import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import SideNavigation from 'containers/SideNavigation/sideNavigation';
import cx from 'classnames';

export const LayoutContext = createContext();

const Layout = ({ children }) => {
  const [compact, setCompact] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        sideBarCollapsed: compact,
      }}
    >
      <div>
        <SideNavigation compact={compact} toggleNavigation={setCompact} />
        <div className={cx('main-content', { collapsed: compact })}>{children}</div>
      </div>
    </LayoutContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
