export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const RAMBLA_LOGIN_USER_SUCCESS = 'RAMBLA_LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const SET_TOKENS = 'SET_TOKENS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const STORE_TOKEN = 'STORE_TOKEN';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
