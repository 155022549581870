import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Route, Redirect } from 'react-router-dom';
import { ramblaUserSelector, userLoggingSelector } from 'redux/auth/selectors';
import { ADMIN_TOOL_LOGIN } from 'constants/routes';

export const PermissionContext = React.createContext();

export function ProtectedRoute({ component: Component, condition, location, ...rest }) {
  const user = useSelector(ramblaUserSelector);
  const [userPermissions, setUserPermissions] = useState();
  const { t } = useTranslation(['app']);
  const isUserLogging = useSelector(userLoggingSelector);

  useEffect(() => {
    if (user) {
      setUserPermissions({ read: true, create: true, update: true, delete: true });
    }
  }, [user]);

  return (
    <Route
      {...rest}
      render={props => {
        const pageName = location.pathname.split('/').slice(-1)[0] || 'Dashboard';

        if (isUserLogging) {
          return null;
        }

        if (typeof condition !== 'undefined' && !condition) {
          return <h1>{t('app:noAccess')}</h1>;
        }

        if (user?.email) {
          return (
            <PermissionContext.Provider
              value={{
                permission: userPermissions,
              }}
            >
              <Helmet>
                <title>{capitalize(pageName)}</title>
              </Helmet>
              <Component {...props} />
            </PermissionContext.Provider>
          );
        }

        return (
          <Redirect
            to={{
              pathname: ADMIN_TOOL_LOGIN,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  condition: PropTypes.bool,
  rest: PropTypes.any,
};
