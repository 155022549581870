import { currencies } from 'constants/currenciesList';
import * as Yup from 'yup';
import i18n from 'i18n';

export default {
  tier: Yup.object({
    name: Yup.string().required(i18n.t('validation:required')),
  }),
  bundle: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  region: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    base_currency: Yup.mixed()
      .oneOf(currencies.map(({ code }) => code), i18n.t('validation:currencyCodeNotValid'))
      .required(i18n.t('validation:required')),
    base_language: Yup.string().required(i18n.t('validation:required')),
    country_code: Yup.string().required(i18n.t('validation:required')),
  }),
  checkoutType: Yup.object().shape({
    checkout: Yup.string().required(i18n.t('validation:required')),
  }),
  brandTracking: Yup.object().shape({
    brand_sites_track: Yup.string().required(i18n.t('validation:required')),
  }),
  commissionTracking: Yup.object().shape({
    retailers_commission_track: Yup.string().required(i18n.t('validation:required')),
  }),
  sellerAccess: Yup.object().shape({
    retailers_invite: Yup.string().required(i18n.t('validation:required')),
  }),
  productCatalog: Yup.object().shape({
    product_catalog: Yup.string().required(i18n.t('validation:required')),
  }),
  locationManagement: Yup.object().shape({
    location_management: Yup.string().required(i18n.t('validation:required')),
  }),
  product_group: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  multipack: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  parent_product: Yup.object({
    name: Yup.string().required(i18n.t('validation:required')),
    description: Yup.string().required(i18n.t('validation:required')),
    details: Yup.string().required(i18n.t('validation:required')),
  }),
  child_product: Yup.object({
    product_code: Yup.string().required(i18n.t('validation:required')),
    thumbnail: Yup.array().required(i18n.t('validation:required')),
    parent_product_uid: Yup.string().required(i18n.t('validation:required')),
    color_uid: Yup.string().required(i18n.t('validation:required')),
    size_uid: Yup.string().required(i18n.t('validation:required')),
    inventory_uid: Yup.string().required(i18n.t('validation:required')),
  }),
  color: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    hex_value: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  color_category: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  pattern: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  claim: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  gtin: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  category: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  inventory: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
  }),
  ingredient: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    description: Yup.string().min(3, i18n.t('validation:atLeastChars', { number: 3 })),
  }),
  benefit: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    description: Yup.string().min(3, i18n.t('validation:atLeastChars', { number: 3 })),
  }),
  brand: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    description: Yup.string().min(3, i18n.t('validation:atLeastChars', { number: 3 })),
    default_theme: Yup.number().when('uid', {
      is: false,
      then: Yup.number().required(i18n.t('validation:required')),
    }),
  }),
  sku: Yup.object({
    sku: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    description: Yup.string().min(3, i18n.t('validation:atLeastChars', { number: 3 })),
    color: Yup.number()
      .nullable()
      .required(i18n.t('validation:required')),
    inventory: Yup.number().nullable(),
    retail_price: Yup.number()
      .nullable()
      .required(i18n.t('validation:required')),
  }),
  retailer: Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required(i18n.t('validation:required')),
    name: Yup.string()
      .required(i18n.t('validation:required'))
      .min(3, i18n.t('validation:atLeastChars', { number: 3 })),
    surname: Yup.string().required(i18n.t('validation:required')),
    region: Yup.number().required(i18n.t('validation:required')),
    role: Yup.string().required(i18n.t('validation:required')),
    product_groups: Yup.array().required(i18n.t('validation:required')),
    location: Yup.array()
      .when('role', {
        is: role => ['Manager', 'Owner'].includes(role),
        then: Yup.array().required('required'),
      })
      .test('at least one location', 'Owner must to have at least one location', function fn(
        location,
      ) {
        const { role } = this.parent;
        const isOwner = role === 'Owner';
        if (isOwner) return location?.length >= 1;
        return true;
      })
      .test('onlyOneLocationOwner', 'Manager must to have at least one location', function fn(
        location,
      ) {
        const { role } = this.parent;
        const isManager = role === 'Manager';
        if (isManager) return location?.length >= 1;
        return true;
      })
      .test('max 1 location', 'Artist may have only one location', function fn(location) {
        const { role } = this.parent;
        const isArtist = role === 'Artist';
        if (isArtist && location) return location?.length <= 1;
        return true;
      }),
  }),
  product: Yup.object({
    name: Yup.string()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    description: Yup.string().min(6, i18n.t('validation:atLeastChars', { number: 6 })),
    product_group: Yup.array(),
    details: Yup.string().min(6, i18n.t('validation:atLeastChars', { number: 6 })),
    highlighted: Yup.boolean(),
    multipack: Yup.string().nullable(),
    bundle: Yup.string().nullable(),
    color: Yup.number().nullable(),
    material: Yup.string(),
    pattern: Yup.array(),
    ingedients: Yup.array(),
    claims: Yup.array(),
    benefits: Yup.array(),
    recommended_usage: Yup.string(),
    videoUrl: Yup.string(),
    type: Yup.string(),
    gtin: Yup.string(),
    sku: Yup.string().when('product_will_have_variants', {
      is: false,
      then: Yup.string().required(i18n.t('validation:required')),
    }),
    size: Yup.number()
      .nullable()
      .required(i18n.t('validation:required')),
    availability_date_start: Yup.string().nullable(),
    availability_date_end: Yup.string().nullable(),
    expiration: Yup.string().nullable(),
    inventory: Yup.number().nullable(),
    taxCategory: Yup.string(),
    wholesale_price: Yup.number().nullable(),
    retail_price: Yup.number()
      .nullable()
      .required(i18n.t('validation:required')),
    base_id: Yup.number()
      .integer('Given id must be integer')
      .required(i18n.t('validation:required')),
    product_code: Yup.string().max(14, 'The code must not exceed 14 characters'),
    vat_percentage: Yup.number()
      .min(0, 'Percent must be positive')
      .max(100, `Percent can't be higher than 100`)
      .required(i18n.t('validation:required')),
    commission: Yup.number()
      .nullable()
      .min(0, 'Percent must be positive')
      .max(100, `Percent can't be higher than 100`)
      .required(i18n.t('validation:required')),
  }),
  theme: Yup.object({
    coverPhoto: Yup.string().required(i18n.t('validation:required')),
  }),
  admin: Yup.object({
    name: Yup.string(),
    email: Yup.string()
      .email()
      .min(3, i18n.t('validation:atLeastChars', { number: 3 }))
      .required(i18n.t('validation:required')),
    brand: Yup.string().required('Required'),
    regions: Yup.array()
      .required(i18n.t('validation:required'))
      .test(
        'at least one region',
        'Brand admin must to have at least one region, channel manager exactly one',
        function fn(regions) {
          const { role } = this.parent;
          if (role === 'BRAND_ADMIN') return regions?.length >= 1;
          return regions?.length === 1;
        },
      ),
  }),
  assignments: Yup.object({
    product_group: Yup.string().required(i18n.t('validation:required')),
  }),
  location: Yup.object({
    toolFreeNumber: Yup.string().matches(
      /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{3})$/,
      'invalid phone',
    ),
    email: Yup.string().email(),
    website: Yup.string().url(),
    bookOnlineLink: Yup.string(),
  }),
  welcome_screen: Yup.object({
    title: Yup.string().required(i18n.t('validation:required')),
    caption: Yup.string().required(i18n.t('validation:required')),
    video_url: Yup.string(),
    'ctas[0]': Yup.object().shape({
      title: Yup.string(),
      caption: Yup.string(),
      link: Yup.string(),
      icon: Yup.string(),
    }),
    'ctas[1]': Yup.object().shape({
      title: Yup.string(),
      caption: Yup.string(),
      link: Yup.string(),
      icon: Yup.string(),
    }),
    'ctas[2]': Yup.object().shape({
      title: Yup.string(),
      caption: Yup.string(),
      link: Yup.string(),
      icon: Yup.string(),
    }),
  }),
  learning_center: Yup.object({
    primary_section: Yup.string().required(i18n.t('validation:required')),
    primary_subtitle: Yup.string().required(i18n.t('validation:required')),
    secondary_section: Yup.string().nullable(),
    secondary_subtitle: Yup.string().nullable(),
    button: Yup.string().nullable(),
    button_link: Yup.string().nullable(),
  }),
  batch_retailer: Yup.object({
    email: Yup.string()
      .nullable()
      .required(i18n.t('validation:required')),
    firstName: Yup.string()
      .nullable()
      .required(i18n.t('validation:required')),
    lastName: Yup.string()
      .nullable()
      .required(i18n.t('validation:required')),
    tier: Yup.string()
      .nullable()
      .required(i18n.t('validation:required')),
    product_group: Yup.number()
      .nullable()
      .required(i18n.t('validation:required')),
  }),
  owner_group_page: Yup.object({
    name: Yup.string().required(i18n.t('validation:required')),
    vanity_url: Yup.string().required(i18n.t('validation:required')),
    assign_locations: Yup.array().required(i18n.t('validation:required')),
  }),
};
