import {
  RAMBLA_FETCH_STORES,
  RAMBLA_FETCH_STORES_SUCCESS,
  RAMBLA_UPDATE_STORE,
  RAMBLA_UPDATE_STORE_SUCCESS,
} from './constants';

export const fetchStores = () => ({
  type: RAMBLA_FETCH_STORES,
});

export const fetchStoresSuccess = regions => ({
  type: RAMBLA_FETCH_STORES_SUCCESS,
  payload: regions,
});

export const updateStoreRambla = ({ uid, is_private }) => ({
  type: RAMBLA_UPDATE_STORE,
  payload: { uid, is_private },
});

export const updateStoreRamblaSuccess = store => ({
  type: RAMBLA_UPDATE_STORE_SUCCESS,
  payload: store,
});
