import entities from 'constants/entities';

const entityActionTypes = Object.keys(entities).reduce((o, key) => ({
  ...o,
  [`FETCH_${key.toUpperCase()}`]: `FETCH_${key.toUpperCase()}`,
  [`FETCH_${key.toUpperCase()}_SUCCESS`]: `FETCH_${key.toUpperCase()}_SUCCESS`,
  [`FETCH_${key.toUpperCase()}_FAILURE`]: `FETCH_${key.toUpperCase()}_FAILURE`,
  [`CREATE_${key.toUpperCase()}`]: `CREATE_${key.toUpperCase()}`,
  [`CREATE_${key.toUpperCase()}_SUCCESS`]: `CREATE_${key.toUpperCase()}_SUCCESS`,
  [`CREATE_${key.toUpperCase()}_FAILURE`]: `CREATE_${key.toUpperCase()}_FAILURE`,
  [`UPDATE_${key.toUpperCase()}`]: `UPDATE_${key.toUpperCase()}`,
  [`UPDATE_${key.toUpperCase()}_SUCCESS`]: `UPDATE_${key.toUpperCase()}_SUCCESS`,
  [`UPDATE_${key.toUpperCase()}_FAILURE`]: `UPDATE_${key.toUpperCase()}_FAILURE`,
  [`DELETE_${key.toUpperCase()}`]: `DELETE_${key.toUpperCase()}`,
  [`DELETE_${key.toUpperCase()}_SUCCESS`]: `DELETE_${key.toUpperCase()}_SUCCESS`,
  [`DELETE_${key.toUpperCase()}_FAILURE`]: `DELETE_${key.toUpperCase()}_FAILURE`,
}));

export { entityActionTypes };

export const ASSIGN_TO_ENTITY = 'ASSIGN_TO_ENTITY';
export const ASSIGN_TO_ENTITY_SUCCESS = 'ASSIGN_TO_ENTITY_SUCCESS';
export const ASSIGN_TO_ENTITY_FAILURE = 'ASSIGN_TO_ENTITY_FAILURE';
export const DELETE_VARIANT = 'DELETE_VARIANT';
export const DELETE_VARIANT_SUCCESS = 'DELETE_VARIANT_SUCCESS';
export const DELETE_VARIANT_FAILURE = 'DELETE_VARIANT_FAILURE';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const UPDATE_VARIANT_SUCCESS = 'UPDATE_VARIANT_SUCCESS';
export const UPDATE_VARIANT_FAILURE = 'UPDATE_VARIANT_FAILURE';
export const CLEAR = 'CLEAR';
export const FETCH_FLAG_DOWN = 'FETCH_FLAG_DOWN';
export const QR_CODE_FETCHED = 'QR_CODE_FETCHED';
export const IMPORT_SELLERS = 'IMPORT_SELLERS';
