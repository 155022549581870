import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { brandConfigSelector } from 'redux/brand/selectors';

import { CardContainer, ImageWrapper } from 'components';
import { OnboardingSellerAccessForm } from 'containers/forms';
import { ONBOARDING_PRODUCT_CATALOG } from 'constants/routes';

import { ReactComponent as Icon } from '../../assets/img/illustrations/seller-access.svg';

const previousStep = ONBOARDING_PRODUCT_CATALOG;

const OnboardingSellerAccessPage = () => {
  const { t } = useTranslation(['pages']);

  const brandConfig = useSelector(brandConfigSelector);

  return (
    <CardContainer lg="8" backButtonTarget={previousStep}>
      <Helmet>
        <title>{t('onboarding.sellerAccessTitle')}</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.sellerAccessTitle')}</h1>
      <h3 className="display-5 text-left">{t('onboarding.sellerAccessSubtitle')}</h3>
      <ImageWrapper>
        <Icon />
      </ImageWrapper>
      <OnboardingSellerAccessForm initialValues={brandConfig} />
    </CardContainer>
  );
};

export default OnboardingSellerAccessPage;
