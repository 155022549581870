import styled from 'styled-components/macro';
import { media } from 'utils/rwd';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 1031;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  animation: opacityFromNone 0.2s ease-out;
`;

export const ModalDialog = styled.div`
  position: ${({ sidebar }) => (sidebar ? 'absolute' : 'relative')};
  margin: ${({ sidebar }) => (sidebar ? 0 : 'auto')};
  min-height: ${({ sidebar }) => (sidebar ? '100%' : 'unset')};
  animation: ${({ sidebar }) =>
    sidebar ? ' slideFromRight 0.3s ease-out;' : ' slideIn 0.3s ease-out;'};
  pointer-events: auto;
  border-radius: 0.5rem;
  ${({ sidebar }) => sidebar && 'right: 0'}
  ${({ wide }) => wide && 'width: 80vw'}
`;

export const ModalContent = styled.div`
  overflow-y: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  ${({ sidebar }) => !sidebar && 'max-height: 90vh'}
`;

export const ModalHeader = styled.div`
  height: unset;
`;

export const ModalTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 20px;

  font-weight: 500;
`;

export const ModalSubTitle = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
`;

export const FormWrapper = styled.div`
  width: ${({ type }) => (type === 'create' ? 'calc(600px - 3rem)' : '100%')};
  ${media.sm.down`
     width: 100%;
  `}
`;
