import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const CoverPhotoWrapper = styled.div`
  position: relative;
  #dropzone-wrapper,
  #crop-wrapper {
    z-index: 100;
    width: 100%;
    height: 100%;
    min-width: 200px;
    position: absolute;
    top: 0;
  }
`;

export const PhotosInnerWrapper = styled.div.attrs({
  className: 'position-relative text-center d-flex flex-nowrap pr-5',
})`
  overflow-x: auto;
  overflow-y: hidden;
  gap: 15;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const ImageWrapper = styled(Col).attrs({
  className: 'position-relative col-3',
})`
  height: 100%;
  // max-width: 20%;

  #cover-photo {
    // min-height: 200px;
    width: 100%;
  }
  .remove-photo {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #ffffffAA;
    border-radius: 50%;
    cursor: pointer;
`;
