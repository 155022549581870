/* eslint-disable react/no-danger, react/prop-types */

import React, { useState, useEffect, useRef, createElement, Fragment } from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import { autocomplete } from '@algolia/autocomplete-js';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import { Button } from 'react-bootstrap';

import Select from 'react-select';
import { productsByCategorySelector, productsCategoriesSelector } from 'redux/looks/selectors';
import { sanitizeHtml } from 'utils/htmlString';
import LooksService from 'api/services/LooksService';

import { regionsSelector } from 'redux/auth/selectors';
import * as Ui from './ProductSearchStyled';

const options = ['Hair Care', 'Skin Care', 'Styling', 'Body Care', 'Makeup'];

function debouncePromise(fn, time) {
  let timerId;

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise(resolve => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}

const debounced = debouncePromise(items => Promise.resolve(items), 800);

const ProductSearch = React.memo(({ setSearchedProducts }) => {
  const categories = useSelector(productsCategoriesSelector);
  const regions = useSelector(regionsSelector);
  const productsByCategory = useSelector(productsByCategorySelector);
  const [selectedCategory, setSelectedCategory] = useState({ value: '', label: '' });
  const containerRef = useRef(null);
  const [searchInput, setSearchInput] = useState(null);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState(null);

  const handleQuickFilter = async filter => {
    const selectedFiler = selectedQuickFilter === filter ? '' : filter;
    setSelectedQuickFilter(selectedFiler);
    searchInput.setQuery(selectedFiler);
    await searchInput.refresh();
    containerRef.current.querySelector('.aa-SubmitButton').click();
  };

  const resetFields = () => {
    setSelectedCategory({ value: 'all', label: 'All Products' });
    setSearchedProducts(productsByCategory.all);
  };

  useEffect(resetFields, [categories.length]);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root);
      },
      openOnFocus: true,
      placeholder: 'Search product ...',
      getSources: ({ query }) =>
        debounced([
          {
            sourceId: 'actions',
            templates: {
              item: ({ item }) => (
                <Ui.DropdownItem>
                  <p>{item.name}</p>
                  <desc className="text-muted">
                    category &gt;{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.category,
                      }}
                    />
                  </desc>
                </Ui.DropdownItem>
              ),
            },
            getItems: () =>
              LooksService.searchProducts({
                regionUid: regions[0].uid,
                productName: query?.toLowerCase() || '',
              }).then(results =>
                results.map(el => ({
                  ...el,
                  name: el.name.replaceAll('<span class="trade">&trade;</span>', ' '),
                })),
              ),
            onSelect(params) {
              const { item } = params;
              setSearchedProducts([item]);
              setSelectedCategory({ value: item.category, label: item.category });
            },
            getItemInputValue({ item }) {
              return item.name;
            },
          },
        ]),
      onSubmit: ({ state }) => {
        setSearchedProducts(state.collections[0].items);
        setSelectedCategory({ value: 'all', label: 'Category selection...' });
      },
      onReset: () => {
        setSearchedProducts(productsByCategory[selectedCategory.value]);
      },
    });
    setSearchInput(search);

    return () => {
      search.destroy();
    };
  }, [containerRef.current]);

  return (
    <>
      <Ui.ProductSearchWrapper>
        <Ui.WrapperAutocomplete>
          <div ref={containerRef} />
        </Ui.WrapperAutocomplete>
        <Ui.MultiselectWrapper>
          <Select
            searchable={false}
            placeholder="Filter by category"
            closeMenuOnSelect={false}
            options={categories.map(cat => ({
              label: sanitizeHtml(cat),
              value: cat,
            }))}
            onChange={option => {
              setSearchedProducts(productsByCategory[option.value]);
              setSelectedCategory(option);
            }}
            value={selectedCategory}
          />
        </Ui.MultiselectWrapper>
        <Button
          type="reset"
          size="sm"
          variant="primary"
          onClick={() => {
            resetFields();
            searchInput.setQuery('');
            searchInput.refresh();
          }}
        >
          CLEAR
        </Button>
      </Ui.ProductSearchWrapper>
      <Nav
        variant="tabs"
        defaultActiveKey=""
        activeKey={selectedQuickFilter}
        onSelect={k => handleQuickFilter(k)}
      >
        {options.map(value => (
          <Nav.Item>
            <Nav.Link eventKey={value}>{value}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <h2
        className="pt-4 text-capitalize"
        dangerouslySetInnerHTML={{
          __html: selectedCategory.value,
        }}
      />
    </>
  );
});

ProductSearch.propTypes = {
  setSearchedProducts: PropTypes.func.isRequired,
};
export default ProductSearch;
