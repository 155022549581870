import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Button } from 'components';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import i18n from 'i18n';

import LooksService from 'api/services/LooksService';
import { toastr } from 'utils';
import { getSingleLook } from 'redux/looks/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VariantType } from 'utils/products';
import { PopupContext } from 'components/Modal/modal';
import { sanitizeHtml } from 'utils/htmlString';
import * as Ui from './VariantForm.styles';
import { VariantPicker } from '../VariantPicker/VariantPicker';

const VariantForm = ({
  lookId,
  productId,
  variantType,
  availableVariants,
  currentlyUsedSkus,
  primarySizeId,
  parentProduct,
}) => {
  const { closePopup } = React.useContext(PopupContext);
  const dispatch = useDispatch();
  const { t } = useTranslation(['ownerPortal']);

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'remove':
        return state.filter(id => id !== payload);
      case 'add':
        return state.concat(payload);

      default:
        throw new Error();
    }
  };

  const [selectedVariants, internalDispatch] = useReducer(reducer, [
    ...currentlyUsedSkus.map(({ id }) => id),
  ]);

  const [primarySku, setPrimarySku] = useState(primarySizeId);
  const { images } = parentProduct.sub_skus.find(s => s.id === primarySizeId);

  const isValid = () => {
    if (!selectedVariants.includes(primarySku)) {
      toastr.error(i18n('toastr:error'), 'Primary variant must be included');
      return false;
    }
    return true;
  };

  const editProduct = async () => {
    if (!isValid()) {
      return;
    }

    await LooksService.patchProduct(lookId, productId, {
      sub_skus: selectedVariants,
      primary_sku: primarySku,
    });

    dispatch(getSingleLook(lookId));
    closePopup();
  };

  const onSave = () => {
    editProduct();
  };

  const onVariantClick = variantId => {
    if (selectedVariants.includes(variantId)) {
      internalDispatch({ type: 'remove', payload: variantId });
    } else {
      internalDispatch({ type: 'add', payload: variantId });
    }
  };

  const variantOptions = availableVariants.map(item => ({
    value: item.id,
    label: item.variantName,
  }));

  return (
    <>
      <Row>
        <Col xs={4}>
          <div className="d-flex justify-content-center">
            <Ui.Image src={images[0]} />
          </div>
        </Col>
        <Col xs={8}>
          <Ui.ProductName
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(parentProduct.name),
            }}
          />
          <p>{parentProduct.description}</p>

          <VariantPicker
            variants={availableVariants}
            selectedVariants={selectedVariants}
            onClick={onVariantClick}
          />

          <Form.GroupHorizontal
            className="mt-4"
            label={
              variantType === VariantType.COLOR ? t('Looks.primaryColor') : t('Looks.primarySize')
            }
          >
            <Select
              closeMenuOnSelect
              options={variantOptions}
              onChange={field => setPrimarySku(field.value)}
              value={variantOptions.filter(option => option.value === primarySku)}
              className="text-left"
            />
          </Form.GroupHorizontal>
        </Col>
      </Row>
      <Modal.Footer>
        {x => (
          <Ui.ButtonsWrapper>
            <Button variant="outline-dark" onClick={x.closePopup}>
              {t('app:back')}
            </Button>
            <Button variant="primary" onClick={onSave}>
              {t('app:save')}
            </Button>
          </Ui.ButtonsWrapper>
        )}
      </Modal.Footer>
    </>
  );
};

VariantForm.propTypes = {
  lookId: PropTypes.string.isRequired,
  variantType: PropTypes.oneOf(Object.keys(VariantType)).isRequired,
  availableVariants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      variantName: PropTypes.string.isRequired,
      hexValue: PropTypes.string,
    }),
  ).isRequired,
  currentlyUsedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      variantName: PropTypes.string.isRequired,
      hexValue: PropTypes.string,
    }),
  ).isRequired,
  primarySizeId: PropTypes.number.isRequired,
  productId: PropTypes.string,
  parentProduct: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    sub_skus: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        is_primary: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }),
};

export default VariantForm;
