import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { brandConfigSelector } from 'redux/brand/selectors';

import { CardContainer, RamblaSpinner } from 'components';
import { DASHBOARD } from 'constants/routes';
import BrandService from 'api/services/BrandService';
import { ReactComponent as Icon } from '../../assets/img/illustrations/splash.svg';

const OnboardingFinalSplashPage = () => {
  const { t } = useTranslation(['pages']);
  const dispatch = useDispatch();
  const brandConfig = useSelector(brandConfigSelector);

  useEffect(() => {
    BrandService.sendBrandConfigurationSummary(brandConfig.uid);
    setTimeout(() => dispatch(push(DASHBOARD)), 5000);
  }, []);

  return (
    <CardContainer lg="10">
      <Helmet>
        <title>All set!</title>
      </Helmet>
      <h1 className="display-3 text-center mb-4">{t('onboarding.splashTitle')}</h1>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div
          style={{
            maxWidth: '600px',
            maxHeight: '600px',
            minHeight: '500px',
            width: '100%',
            position: 'relative',
            marginTop: '1rem',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }}
          >
            <Icon />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '33%',
              left: '41%',
              transform: 'translate(-50%,-50%)',
              maxWidth: '30px',
              maxHeight: '20px',
            }}
          >
            <RamblaSpinner />
          </div>
        </div>
      </div>
      <h2 className="display-5 text-center">{t('onboarding.splashSubtitle')}</h2>
    </CardContainer>
  );
};

export default OnboardingFinalSplashPage;
