import produce from 'immer';
import {
  RAMBLA_FETCH_RETAILERS_SUCCESS,
  RAMBLA_UPDATE_RETAILER_SUCCESS,
  RAMBLA_INVITE_RETAILER_SUCCESS,
  RAMBLA_DELETE_RETAILER_SUCCESS,
} from './constants';

export const initialState = { fetched: false, data: [] };

const retailerReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case RAMBLA_FETCH_RETAILERS_SUCCESS:
        draft.data = payload;
        draft.fetched = true;
        break;
      case RAMBLA_UPDATE_RETAILER_SUCCESS:
        draft.data = state.data.map(retailer => (retailer.id === payload.id ? payload : retailer));
        break;
      case RAMBLA_DELETE_RETAILER_SUCCESS:
        draft.data = state.data.filter(retailer => retailer.id !== payload.id);
        break;
      case RAMBLA_INVITE_RETAILER_SUCCESS:
        draft.data = [...state.data, payload];
        break;
      default:
        return state;
    }
  });

export default retailerReducer;
