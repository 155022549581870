export const brandSelector = state => state.brands.brand;
export const themeSelector = state => state.brands.theme;
export const brandConfigSelector = state => state.brands.brand.data.config;
export const ramblaIsProductColorsGeneratedSelector = state =>
  state.brands.brand.data.is_product_colors_generated;
export const ramblaIsProductSizesGeneratedSelector = state =>
  state.brands.brand.data.is_product_sizes_generated;
export const ramblaIsProductCategoriesGeneratedSelector = state =>
  state.brands.brand.data.is_product_categories_generated;
export const ramblaIsProductInventoriesGeneratedSelector = state =>
  state.brands.brand.data.is_product_inventories_generated;
export const ramblaIsOnboardingProcessFinishedSelector = state =>
  state.brands?.brand?.data?.is_onboarding_process_finished;
